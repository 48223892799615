import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import ProductBox from './Box';

// Modals
import CreateEdit from './Modal';
import Inactivate from './Modal/inactivate';

// Interfaces
export interface IResourceMeasure {
    id: number;
    measure: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export interface IResourceType {
    id: number;
    type: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export interface IWarehouseResource {
    id: number;
    name: string;
    description: string;
    measure_id: number;
    type_id: number;
    quantity: number;
    inactive: boolean;
    created_at: string;
    updated_at: string;
    resourceMeasure: IResourceMeasure;
    resourceType: IResourceType;
}

const WarehouseProductsNutrition: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [product, setProduct]           = useState<IWarehouseResource[]>([]);
    const [productsList, setProductsList] = useState<IWarehouseResource[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type==="create") {
            setModalClass('whCreateEdit')
            setModalTitle('Adicionar Produto')
            setModalBody(<CreateEdit setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "Delete") {
            setModalClass('whInactivate')
            setModalTitle('Inativar Produto')
            setModalBody(<Inactivate {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "Edit") {
            setModalClass('whCreateEdit')
            setModalTitle('Editar Produto')
            setModalBody(<CreateEdit {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Produto", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: productsList,
                    filterKey: ["name", "resourceType.description"],
                    setFilteredList: setProduct
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                product && product.length > 0 ? (
                    <div className='products'>
                        {
                            product.map((productData: any) => (
                                <ProductBox
                                    key={`product_box_${productData.id}`}
                                    {...productData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum produto de transporte</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('warehouse_resources?filter=active&category=manutencao', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setProduct(resp.data);
            setProductsList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados dos produtos de transporte')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Manutenção") && setBreadcrumbs({
            curr: 'Manutenção',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Almoxarifado', url: '/almoxarifado' },
                { name: 'Manutenção' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Produtos de Manutenção" pageTitle="Almoxarifado - Produtos de Manutenção" className="warehouseProdMaintenance">
            { ready ? getContent() : <Loading /> }
        </Template>
    );
}

export default WarehouseProductsNutrition;