import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const DriverBox: React.FC<any> = (props: any) => {

    function getButtons() {
        return <>
            <button type="button" className="edit" onClick={() => props.openModal("Edit", props)} title="Deletar Produto">
            <FaEye size={16} /> <span>Visualizar</span>
            </button>
        </>
    }

    return <div className="driversBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="linhas">
            <h3>Rotas</h3>
            {props.linhas.map((linha: any, index: number) => (
            <div key={index}>
                {linha}
            </div>))}
            </div>
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default DriverBox;
