import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import OtherUnitsBox from './Box';

interface LocationData {
    id: number;
    name: string;
    address: string;
    address_number: number;
    address_complement: string;
    address_neighborhood: string;
    address_city: string;
    address_cep: number;
    latitude: string;
    longitude: string;
    teaching_network_id: number;
}

const SecretaryEducationOtherUnits: React.FC = () => {
    const [ready, setReady]         = useState(false);
    const [units, setUnits]         = useState<LocationData[]>([]);
    const [unitsList, setUnitsList] = useState<LocationData[]>([]);
    const [error, setError]         = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ url: "novo", nameButton: " Adicionar Unidade", className: "add" }]}
                search={{
                    dataList: unitsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setUnits   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                units && units.length > 0 ? (
                    <div className='units'>
                        {
                            units.map((unitData: any) => (
                                <OtherUnitsBox
                                    key={'other_units_' + unitData.id}
                                    {...unitData}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma unidade</div>
            }
        </>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('other-units', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setUnits(resp.data);
            setUnitsList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Demais Unidades") && setBreadcrumbs({
            curr: 'Demais Unidades',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                { name: 'Demais Unidades' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Demais Unidades" pageTitle="Secretaria da Educação - Demais Unidades" className="secEducUnits">
        {
            ready ? getContent() : <Loading />
        }
    </Template>;
}

export default SecretaryEducationOtherUnits;



