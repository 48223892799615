import axios from 'axios';

const apiIP = {
    get: async function () {
        // let ip = await axios.get('https://api.ipify.org/?format=json').then(resp => resp.data.ip);
        // return ip
        return '127.0.0.1'
    }
}

export default apiIP;