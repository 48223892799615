import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import {AiEditor} from "aieditor";
import api from "~/services/api";
import "aieditor/dist/style.css"
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Tip Base
const tipBase = {
    id   : 0,
    title: '',
    text : ''
};

const ModalCreate: React.FC<any> = ({data, setMainReady, setModalOpen}: any) => {
    const [editor, setEditor]     = useState<any>(null);
    const [tip, setTip]           = useState<any>(tipBase);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [ready, setReady]       = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    //Define Ref
    const divRef = useRef(null);

    const onChange = (e: any) => {
        let name: any, value: any;

        if (e.target) {
            name  = e.target.name;
            value = e.target.value;
        } else {
            name = 'text';
            value = e.getHtml();
        }

        setTip((prevTip:any) => ({
            ...prevTip,
            [name]: value
        }));
    }

    async function saveDataForm() {
        if (
            tip.title.trim() === "" || tip.text.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = {
            id   : tip.id,
            title: tip.title,
            text : tip.text
        };

        // Send data to server
        await api.post('/nutrition/tip', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setMainReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    }

    useEffect(() => {
        if (!ready && data) {
            setTip(data);
            setReady(true);
        } else if (divRef.current && !editor) {
            const aiEditor = new AiEditor({
                element: divRef.current,
                placeholder: "ex.: A alimentação é algo muito importante para nosso desenvolvimento...",
                toolbarKeys: ["undo", "redo", "eraser",
                    "|", "bold", "italic", "underline", "strike",
                    "|", "heading", "font-size",
                    "|", "font-color", "highlight",
                    "|", "subscript", "superscript", "link", "hr", "todo",
                    "|", "align", "line-height",
                    "|", "bullet-list", "ordered-list", "indent-decrease", "indent-increase", "break",
                    "|", "image", "quote", "table",
                    "|", "fullscreen"
                ],
                content: tip.text,
                lang: "pt-BR",
                onChange: onChange
            })

            // Set Editor State
            setEditor(aiEditor);

            // Destroy Editor
            return () => aiEditor.destroy();
        }
    }, [ready]);

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="CreateTips">
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="title">Titulo</label>
                    <input
                        type="text"
                        id="title"
                        placeholder="ex.: Alimentação"
                        name="title"
                        value={tip.title}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="input-group">
                    <label htmlFor="text">Descrição</label>
                    <div ref={divRef} id="text" />
                </div>
            </div>

            <div className="btns">
                <button id="btnSave" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalCreate;
