import React, { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import TipsBox from './Box';
import View from './Modal';
import Create from './Modal/create';
import Delete from './Modal/delete';

const NutritionTips: React.FC = () => {
    const [ready, setReady]       = useState(false);
    const [tips, setTips]         = useState<any>([]);
    const [tipsList, setTipsList] = useState<any>([]);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);

    const openModal = (type: string, data: any) => {
        if (type === "Edit") {
            setModalClass('editCreateTips');
            setModalTitle('Editar Dica de Nutrição')
            setModalBody(<Create data={data} setMainReady={setReady} setModalOpen={setModalOpen} />)
            setModalOpen(true)
        } else if (type === "Create") {
            setModalClass('editCreateTips');
            setModalTitle('Criar Nova Dica de Nutrição')
            setModalBody(<Create setMainReady={setReady} setModalOpen={setModalOpen} />)
            setModalOpen(true)
        } else if (type === "Delete") {
            setModalClass('deleteTips');
            setModalTitle('Deletando Dica de Nutrição')
            setModalBody(<Delete data={data} setReady={setReady} setModalOpen={setModalOpen} />)
            setModalOpen(true)
        } else {
            setModalClass('viewTips');
            setModalTitle('Dica de Nutrição')
            setModalBody(<View data={data} />)
            setModalOpen(true)
        }
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "Criar Nova Dica", onClick:(()=>{openModal("Create", setTips)}), icons:<FaPlus size={14} />, className: "add" }]}
                search={{
                    dataList: tipsList, // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["title", "text", "author.name"], // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setTips // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            <div className="tips">
                { tips.map((tip: any) => <TipsBox key={ `card_${tip.id}Tips` } data={tip} openModal={openModal} />) }
            </div>
        </>
    }

    // Get Data
    useEffect(() => {
        !ready && api.get('/nutrition/tips', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTips(resp.data)
            setTipsList(resp.data)
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar as dicas de nutrição')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Dicas de Nutrição") && setBreadcrumbs({
            curr: 'Dicas de Nutrição',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Nutrição', url: '/nutricao' },
                { name: 'Dicas' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Dicas de Nutrição" pageTitle="Nutrição - Dicas" className="nutritionTips">
           { ready ? getContent() : <Loading /> }
        </Template>

    );
}

export default NutritionTips;
