import React, { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Map from '~/components/Default/Loading/map';
import env from '~/utils/env';
import './styles.css';

// Images
import pin from './images/pin.png';
import pin2 from './images/pin2.png';

// A tipagem correta para `libraries`
const libraries = ['places'] as Array<'places'>;

const MapComponent: React.FC<any> = ({ infos, type }) => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: env.googleMapsApiKey,
		libraries,
	});

	async function initMap() {
		await new Promise(f => setTimeout(f, 1500));

		// Request needed libraries.
		const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

		const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
			center: { lat: Number(infos.latitude), lng: Number(infos.longitude) },
			zoom: 17,
			mapId: '4504f8b37365c3d0',
		});

        // Custom Pin with PNG
        const mapPin = document.createElement('img');
        mapPin.src = type && type==='school' ?  pin2 : pin;

        let marker = new AdvancedMarkerElement({
            map,
            position: { lat: Number(infos.latitude), lng: Number(infos.longitude)},
            title: infos.name,
            content: mapPin,
            gmpClickable: true,
        });

        let infoWindow = new google.maps.InfoWindow();

        marker.addListener('click', ({ domEvent, latLng }: any) => {
				const contentString = `<h4 class="name">${infos.name}</h4>`;

				infoWindow.setContent(contentString);
				infoWindow.open(map, marker);
			});
	}

	useEffect(() => {
		if (isLoaded && !loadError) initMap();
	}, [isLoaded, loadError]);

	return <div id="map" className='mapContent'>
		<Map />
	</div>;
};

export default MapComponent;
