import React from "react";
import './styles.css';

const ModalView: React.FC<any> = ({data}: any) => {

    return <>
        <h3>{data.title}</h3>
        <hr />
        <div className="content" dangerouslySetInnerHTML={{__html: data.text}}></div>
    </>
};

export default ModalView;
