import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import ClassBox from './Box';

const SchoolSecretaryClasses: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [classes, setClasses]         = useState<any>(null);
    const [classesList, setClassesList] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Get page data
    useEffect(() => {
        !ready && api.get('school_classes?rel=students', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setClasses(resp.data);
            setClassesList(resp.data);
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Turmas") && setBreadcrumbs({
            curr: 'Turmas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Turmas' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Turmas" pageTitle="Secretaria Escolar - Turmas" className="secEscClasses">
        {
            ready ? <>
                <HeaderSearchAndForm
                    buttons={[{ url: "nova", nameButton: "Criar Nova Turma", className: "add" }]}
                    search={{
                        dataList: classesList,        // Substitua 'yourDataList' pela sua lista de dados
                        filterKey: ["id", "school.name", "teaching.name"],             // Substitua 'nome' pela chave que você deseja filtrar
                        setFilteredList: setClasses   // Substitua 'setYourList' pela função que define a lista filtrada
                    }}
                    isViewButton={true}
                    isViewSearch={true}
                />

                {
                    classes && classes.length > 0 ? (
                        <div className="classes">
                            {
                                classes.map((classData: any) =>
                                    <ClassBox key={`class_${classData.id}`} {...classData} />)
                            }
                        </div>
                    ) : <div className='noData'>Não foi possível localizar nenhuma turma</div>
                }
            </> : <Loading />
        }
    </Template>;
}

export default SchoolSecretaryClasses;