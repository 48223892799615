// general.tsx
import { createContext, ReactNode, useEffect, useState } from "react";
import api from "~/services/api";
import apiIP from "~/services/getIp";

// Login
import cryptos from '~/utils/cryptos';
import storage from '~/utils/storage';

interface Permission {
    page: string;
    subpage: string;
    ready: boolean;
    write: boolean;
}

interface UserInterface {
    id: number;
    role: string;
    name: string;
    mail: string;
    active: boolean;
    token: string;
    role_category: string;
    permissions: Permission[];
}

interface GeneralContextData {
    logged: boolean;
    breadcrumbs: any;
    setBreadcrumbs: Function;
    user: UserInterface;
    login: Function;
    logout: Function;
    checkToken: Function;
}

interface GeneralProviderProps {
    children: ReactNode;
}

// User Init
const userInit: UserInterface = {
    id: 0,
    role: 'guest',
    name: 'Guest',
    mail: '',
    active: true,
    token: '',
    role_category:'',
    permissions: []
}

export const GeneralContext = createContext({} as GeneralContextData);

export function GeneralProvider ({ children }: GeneralProviderProps) {
    const [ready, setReady]             = useState<boolean>(false);
    const [logged, setLogged]           = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<any>(null);
    const [user, setUser]               = useState<UserInterface>(userInit);

    // async function fetchPermissions(role_category: string) {
    //     console.log("🚀 ~ fetchPermissions ~ role:", role_category)
    //     console.log("🚀 ~ fetchPermissions ~ user.role:", user.role)
    //     if (user.role_category===null) return;

    //     const response = await api.get(`/permissions/user?roleID=${role_category}`, { headers: { Authorization: user.token } });
    //     console.log("🚀 ~ fetchPermissions ~ response:", response)
    //     return response.data;
    // }

    async function login(userData: any) {
        const updatedUserData = { ...userData};
        let data = cryptos.encryptWeb(updatedUserData);
        storage.set('UToken', data);
        setUser(updatedUserData);
        setLogged(true);
    }

    function logout() {
        storage.remove('UToken');
        setUser(userInit);
        setLogged(false);
    }

    async function checkToken(user: any) {
        let tokens: any = cryptos.encryptServer({ token: user.token, refresh: user.refreshToken });
        let data: any = null;

        // Validate Token
        let ip = await apiIP.get();
        if (ip === user.ip) {
            await api.post('validateToken', { data: tokens })
            .then(async resp => {
                if (resp.data.valid && resp.data.user === "") {
                    const updatedUser = { ...user };
                    data = cryptos.encryptWeb(updatedUser);

                    setUser(updatedUser);
                } else {
                    let userData = cryptos.decryptServer(resp.data.user);
                    const updatedUserData = { ...userData };
                    data = cryptos.encryptWeb(updatedUserData);
                    storage.set('UToken', data);
                    setUser(updatedUserData);
                }

                setLogged(true);
                setReady(true);
                return true;
            }).catch(error => {
                storage.remove('UToken');
                setUser(userInit);
                setLogged(false);
                return false;
            });
        } else {
            alert('Faça o login novamente');
            logout();
        }
    }

    useEffect(() => {
        if (!ready) {
            let data = storage.get('UToken');

            if (data) {
                let userData = cryptos.decryptWeb(data);
                checkToken(userData);
            } else {
                setUser(userInit);
                setLogged(false);
                setReady(true);
            }
        }
    }, [ready, logged]);

    useEffect(() => {}, [breadcrumbs]);

    return (
        <GeneralContext.Provider value={{ logged, breadcrumbs, setBreadcrumbs, user, login, logout, checkToken }}>
            { ready ? children : <></> }
        </GeneralContext.Provider>
    );
}
