import React from "react";
import './styles.css';

type BimesterDates = {
  bimester1Start: string;
  bimester1End  : string;
  bimester2Start: string;
  bimester2End  : string;
  bimester3Start: string;
  bimester3End  : string;
  bimester4Start: string;
  bimester4End  : string;
};

interface BimesterInfoComponentProps {
  bimesterDates: BimesterDates;
  onBimesterDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  letiveDays: number;
}

const BimesterInfoComponent: React.FC<BimesterInfoComponentProps> = ({ bimesterDates, onBimesterDateChange, letiveDays, }) => {
	function getBimestrer(bimester: number, name: string, values: any, onChange: any) {
		return <div className="bimester">
			<h4>Bimestre {bimester}</h4>

			<label>
				<span>Início:</span>
				<input
					type="date"
					name={`${name}Start`}
					value={values.start}
					onChange={onChange}
				/>
			</label>

			<label>
				<span>Fim:</span>
				<input
					type="date"
					name={`${name}End`}
					value={values.end}
					onChange={onChange}
				/>
			</label>
		</div>
	}

	return (
		<div className="bimester-info">
			<h3>Informações dos Bimestres</h3>

			<div className="bimester-dates">
				{ getBimestrer(1, 'bimester1', {start: bimesterDates.bimester1Start, end: bimesterDates.bimester1End}, onBimesterDateChange) }
				{ getBimestrer(2, 'bimester2', {start: bimesterDates.bimester2Start, end: bimesterDates.bimester2End}, onBimesterDateChange) }
				{ getBimestrer(3, 'bimester3', {start: bimesterDates.bimester3Start, end: bimesterDates.bimester3End}, onBimesterDateChange) }
				{ getBimestrer(4, 'bimester4', {start: bimesterDates.bimester4Start, end: bimesterDates.bimester4End}, onBimesterDateChange) }
			</div>

			<div className="letive-days">
				<b>Dias Letivos:</b> {letiveDays}
			</div>
		</div>
	);
};

export default BimesterInfoComponent;
