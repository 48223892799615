import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

const ClassTeacher: React.FC<any> = ({ id, setModalOpen, openModal }: any) => {
    const [ready, setReady]               = useState(false);
    const [teachers, setTeachers]         = useState<any>(null);
    const [classTeacher, setClassTeacher] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function getContent() {
        return <>
            {
                classTeacher && classTeacher.length > 0 ? <>
                    <div className="header">
                        <div className="teacher">Professor</div>
                        <div className="discipline">Materia</div>
                    </div>

                    {
                        classTeacher.map((el: any) => <div key={`teacher_${el.id}`} className='line'>
                            <div className="teacher">{el.teacher.name}</div>
                            <div className="discipline">{el.discipline}</div>
                        </div>)
                    }
                </> : <div className='alert'>Nenhum professor cadastrado para essa sala</div>
            }

            <div className="buttons">
                <button className='edit' onClick={() => {
                    openModal('teacherEdit', {id, teachers, classTeacher})
                }}>
                    Editar
                </button>

                <button className='cancel' onClick={() => setModalOpen(false)}>
                    Cancelar
                </button>
            </div>
        </>
    }

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const [respPosition, respTeacher] = await Promise.all([
                api.get('employees?position=1', { headers: { Authorization: user.token } }),
                api.get(`school_classes/teacher/${id}`, { headers: { Authorization: user.token } }),
            ]);

            setClassTeacher(respTeacher.data.teachers);
            setTeachers(respPosition.data);
            setReady(true);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }, [user.token]);

    useEffect(() => {
        !ready && fetchData()
    }, [ready]);

    return ready ? getContent() : <></>;
}

export default ClassTeacher;