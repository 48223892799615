import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalView: React.FC<any> = (props: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return <>
       {/* dados referente a rota do aluno  */}
    </>;
};

export default ModalView;
