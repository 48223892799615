import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '~/contexts';

const useAuthGuard = (page: string, subpage: string) => {
    const { user } = useContext(GeneralContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            // Se não houver usuário autenticado, redireciona para a página de login
            navigate('/login');
        } else if (user.role === 'admin') {
            // Se o usuário for admin, permite acesso a todas as páginas
            return;
        } else {
            // Verifica permissões específicas do usuário
            const hasPermission = user.permissions.some(
                (perm: any) =>  perm.page === page && (perm.subpage === subpage || perm.subpage === null) && perm.ready
            );
            if (!hasPermission) {
                // Se o usuário não tiver permissão, redireciona para a página de erro 404
                navigate('/404');
            }
        }
    }, [user, page, subpage, navigate]);
};

export default useAuthGuard;