import axios from 'axios';

const apiAddress = {
    getViaCep: async function (cep: string) {
        let address = await axios.get('https://viacep.com.br/ws/' + cep + '/json/').then(resp => resp.data);
        
        return address
    }
}

export default apiAddress;