import React from "react";
import { eachDayOfInterval, startOfWeek, endOfWeek, startOfMonth, endOfMonth, format, isSameMonth } from "date-fns";
import { ptBR } from "date-fns/locale";
import './styles.css';

type EventDays = Record<string, string>;

interface CalendarComponentProps {
    eventColors: Record<string, string>;
    eventDays: EventDays;
    onDayClick: (day: number, month: number) => void;
    year: number;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({ eventColors, eventDays, onDayClick, year}) => {
    const months = Array.from({ length: 12 }, (_, i) => new Date(year, i, 1));

    const generateCalendar = () => {
        return months.map((monthDate, monthIndex) => {
            const start        = startOfMonth(monthDate);
            const end          = endOfMonth(monthDate);
            const days         = eachDayOfInterval({ start, end });
            const startWeek    = startOfWeek(start);
            const endWeek      = endOfWeek(end);

            const calendarDays = eachDayOfInterval({ start: startWeek, end: endWeek });

            return {
                monthIndex,
                monthName: format(monthDate, "MMMM", { locale: ptBR }),
                calendarDays,
                monthDays: days,
            };
        });
    };

    const calendarData = generateCalendar();

    return (
        <div className="calendar-months">
            {calendarData.map(({ monthIndex, monthName, calendarDays }) => (
                <div className="calendar-month" key={monthIndex}>
                    <h2>{monthName.charAt(0).toUpperCase() + monthName.slice(1)}</h2>
                    <div className="calendar-weekdays">
                        <span>Dom</span>
                        <span>Seg</span>
                        <span>Ter</span>
                        <span>Qua</span>
                        <span>Qui</span>
                        <span>Sex</span>
                        <span>Sáb</span>
                    </div>
                    <div className="calendar-grid">
                        {calendarDays.map((date) => {
                            const day       = date.getDate();
                            const month     = date.getMonth();
                            const key       = `${day}-${month}`;
                            const eventType = eventDays[key];

                            return (
                                <div
                                    key={key}
                                    className={`calendar-day ${!isSameMonth(date, new Date(year, monthIndex)) ? 'other-month' : eventType ? eventColors[eventType] : ""}`}
                                    onClick={() => !isSameMonth(date, new Date(year, monthIndex)) ? null : onDayClick(day, month)}
                                    role="button"
                                    aria-label={`Dia ${day} de ${monthName}`}
                                >
                                    {isSameMonth(date, new Date(year, monthIndex)) ? day : ""}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CalendarComponent;
