import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiBell } from 'react-icons/fi';
import { FaUser, FaChevronLeft } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import './styles.css';

// Images
import userProfile from '~/assets/images/user.jpg';
import { Button } from '~/components/Forms';

const Header: React.FC = () => {
    const { breadcrumbs } = useContext(GeneralContext);
    const navigate  = useNavigate();
    const location  = useLocation();

    // Check if page is internal
    let checkBread = location.pathname.search(/\/admin\/([0-9])/gm)>=0 || location.pathname.search(/\/admin\/auditoria\//gm)>=0;

    function getBreads () {
        return breadcrumbs ? (<>
            <div className="links">
                {
                    breadcrumbs.links && breadcrumbs.links.length>0 && breadcrumbs.links.map((el: any) => el.url ?
                        <Link key={`bread_${el.name}`} to={el.url}>{el.name}</Link> :
                        <span key={`bread_${el.name}`}>{el.name}</span>
                    )
                }
            </div>
            <div className="curr">{breadcrumbs.curr}</div>
        </>) : ""
    }

    return <header>
        <div className="section">
            <div className="breadBox">
                {
                    checkBread && <Button
                        type="button"
                        className="back"
                        icon={<FaChevronLeft />}
                        func={() => navigate(-1)}
                    />
                }

                <div className="breadcrumbs">
                    { getBreads() }
                </div>
            </div>

            <div className="buttonsBox">
                <button type="button" className='bell'>
                    <FiBell />
                </button>

                <div className="foto" data-tip="tooltip" data-for="userBox">
                    <img src={userProfile} alt="Foto do Usuário" />

                    <ReactTooltip id="userBox" type="light" effect="solid" clickable={true} delayHide={1000}>
                        <Link to="/perfil">
                            <div className="icon">
                                <FaUser />
                            </div>

                            <div className="infos">
                                <span>Perfil</span>
                            </div>
                        </Link>
                    </ReactTooltip>
                </div>
            </div>
        </div>
    </header>;
}

export default Header;