import { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalForm: React.FC<any> = (props: any) => {

    // Contexts
    const { user } = useContext(GeneralContext);

    const deletePosition = async () => {
        await api.delete(`position/${props.id}`, {
            headers: { Authorization: user.token }
        }).then(data => {
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    };

    return <>
        <div className="infos">
            <p>Deseja realmente excluir o cargo <i>"{props.name}"</i>?</p>
            <p className="alert">Esse processo é irreversível!</p>
        </div>

        <div className="btns">
            <button id="btnDelete" type="button" onClick={deletePosition}>Deletar</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
        </div>
    </>;
};

export default ModalForm;
