import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalDelete: React.FC<any> = ({data, setReady, setModalOpen}: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    const deleteTip = async () => {
        await api.delete(`/nutrition/tip/${data.id}`, {
            headers: { Authorization: user.token }
        }).then(data => {
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    };

    return <>
        <div className="infos">
            <p>Deseja realmente excluir a dica <i>"{data.title}"</i>?</p>
            <p className="alert">Esse processo é irreversível!</p>
        </div>

        <div className="btns">
            <button id="btnDelete" type="button" onClick={deleteTip}>Deletar</button>
            <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
        </div>
    </>;
};

export default ModalDelete;
