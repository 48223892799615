import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Import Components
import Header from '~/components/Default/Header';
import Menu from '~/components/Default/Menu';
import env from '~/utils/env';

const Template: React.FC<any> = (props) => {
    // Contexts
    const { user, logged } = useContext(GeneralContext);

    // States
    const [ready, setReady] = useState(false);

    // Constants
    const navigate = useNavigate();


    // useEffect(() => {
        // if(props.page_id === user.permission.inArray)
    // }, [props.page_id]);

    useEffect(() => {
        if (!logged || !user.token) navigate('/login', {replace: true})
        setReady(true)
    }, [props.page, ready]);

    return (
        <>
            <Helmet>
                <title>{props.pageTitle}</title>
                <link rel="canonical" href={env.host} />
            </Helmet>

            <section className={props.className ? ' ' + props.className : ''}>
                <Menu />
                <Header />

                <main className={props.classMain ? props.classMain : ''}>
                    { ready ? props.children : <></> }
                </main>
            </section>
        </>
    );
}

export default Template;