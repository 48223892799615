import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import loading from './images/map.json';

const Loading: React.FC = () => {
    return <div className='loading'>
        <Lottie
            animationData={loading}
            speed={0.9}
            loop play
            className='icon'
        />
    </div>;
}

export default Loading;