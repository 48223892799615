import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import NewStudent from './NewStudent';
import NewStudentDocs from './NewStudentDocs';
import NewStudentAddress from './NewStudentAddress';
import NewStudentDeficiency from './NewStudentDeficiency';
import NewStudentResourceAssessment from './NewStudentResourceAssessment';
import apiAddress from '~/services/cep';

const initData = {
    // Dados do estudante (IStudent)
    id: 0,
    school_id: 0,
    class_id: 0,
    ra_number: 0,
    name: "",
    social_name: "",
    sex: "",
    color_race: "",
    birth: "",  // pode ser ajustado para "" se preferir lidar como string
    birth_city: "",
    birth_uf: "",
    mother_name: "",
    father_name: "",
    mail: "",
    nationality: "Brasileiro",
    country_origin: "",
    organ_donor: false,
    blood_type: "O+",
    internet: false,
    pc_smartphone_tablet: false,
    deceased: false,
    deceased_date: undefined,
    quilombola: false,
    family_bag: false,
    family_bag_cod: "",

    // Endereço do estudante (IStudentAddress)
    student_id: 0,
    address: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    uf: "",
    cep: 0,
    public_area: "URBANA",
    latitude: "",
    longitude: "",

    // Documentos do estudante (IStudentDocs)
    inep_code: "",
    birth_certificate: "",
    date_birth_certificate: "",  // pode ser ajustado para "" se preferir lidar como string
    cpf: "",
    cns_number: "",
    date_civil_doc: "",  // pode ser ajustado para "" se preferir lidar como string

    // Dados sobre Deficiência
    reduced_mobility: false,
    reduced_mobility_type: "",
    school_support: false,
    school_support_daily_activities: false,
    school_support_activities: false,
    school_support_hygiene: false,
    school_support_locomotion: false,
    school_support_food: false,
    start_term_activities_daily: "",
    end_term_activities_daily: "",
    start_term_school_activities: "",
    end_term_school_activities: "",

    // Recursos de Acessibilidade
    interpreter_guide: false,
    interpret_libras: false,
    lip_reading: false,
    none: false,
    expanded_test: false,
    expanded_test_font_size: "",
    braille_test: false,
    transcription_assistance: false,
    reader_aid: false,
    libras_video_test: false,
    audio_visual_def_code: false,
    portuguese_language_test: false,

};

const SchoolSecretaryStudentsForm: React.FC = () => {

    const [ready, setReady] = useState(true);
    const [studentDeficiency, setStudentDeficiency] = useState(false);
    const [studentResourceAssessment, setStudentResourceAssessment] = useState(false);
    const [data, setData] = useState<any>(initData);


    const navigate = useNavigate();

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { user } = useContext(GeneralContext);

    async function sendForm() {
        console.log(data)
        await api.post('', data, { headers: { Authorization: user.token } })
            .then(resp => {
                alert('Cadastro realizado com sucesso')
                console.log(resp)
                navigate('/secretaria-escolar/alunos')
            }).catch(err => {
                console.log("🚀 ~ sendForm ~ err:", err)
            })
    }

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLButtonElement>) {
        let { name, value } = event.target;
        change(name, value);
    }

    function change(name: string, value: string,) {
        // Set Data
        setData({ ...data, [name]: value });
    }

/*     function onChangeCheckbox(event: any) {
        let { name } = event.target;

        let isChecked = event.target.checked;
        setData({ ...data, [name]: isChecked ? true : false })
    } */

    async function onChangeCep(event: any) {
        let { name, value } = event.target;
        let formattedValue = (value).replaceAll('_', '').replace('-', '') //Deixando apenas os números
        if (formattedValue.length === 8) {

            let addressComplete = await apiAddress.getViaCep(formattedValue) // Acessa a API do viaCep
            if (addressComplete.erro) alert("A busca do cep falhou, preencha manualmente!") //Em caso de cep inválido
            fillAddress(addressComplete) //Preenchimento do cep
        } else {
            change(name, value);
        }

    }

    function onAddressChange(addressData: {
        rua: string;
        bairro: string;
        cidade: string;
        uf: string;
        cep: string;
        pais: string;
    }) {
        setData({
            ...data,
            address: addressData.rua,
            neighborhood: addressData.bairro,
            city: addressData.cidade,
            uf: addressData.uf,
            cep: addressData.cep
        });
    }

    function fillAddress(addressComplete: any) {
        setData({
            ...data,
            cep: addressComplete.cep,
            address: addressComplete.logradouro,
            city: addressComplete.localidade,
            neighborhood: addressComplete.bairro,
            uf: addressComplete.uf,
        })
    }

    const handleDeficiencyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStudentDeficiency(event.target.value === 'yes');
    };

    const handleResourceAssessmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStudentResourceAssessment(event.target.value === 'yes');
    };

    useEffect(() => {

    }, []); // dependências vazias para rodar apenas uma vez

    // Breadcrumbs
    /*     useEffect(() => {
            if (!breadcrumbs || breadcrumbs.curr !== "Nova Requisição") {
                setBreadcrumbs({
                    curr: 'Novo Aluno',
                    links: [
                        { name: 'Home', url: '/' },
                        { name: 'secretaria-escolar', url: '/secretaria-escolar' },
                        { name: 'Alunos', url: '/secretaria-escolar/alunos' },
                        { name: 'Novo Aluno' }
                    ]
                });
            }
        }, [breadcrumbs, setBreadcrumbs]); */

    return (
        <Template page="Novo Aluno" pageTitle="Cadastro de novo aluno" className="secEducStudentForm">
            {ready ? (
                <>
                    <div className="headerBox">
                        <div className="buttons">
                            <Link to="/secretaria-escolar/alunos" className="btn back" title="Voltar para lista de Requisições">
                                <FaArrowLeft size={12} /><span> Voltar</span>
                            </Link>
                        </div>
                    </div>

                    <div className='contentForm'>
                        <NewStudent onChange={onChange} data={data} />
                        <hr />
                        <NewStudentAddress onChange={onChange} onChangeCep={onChangeCep} onAddressChange={onAddressChange} data={data} />
                        <hr />
                        <NewStudentDocs onChange={onChange} data={data} />

                        <div className="radioGroup">
                            <label>O aluno tem deficiência?</label>
                            <div>
                                <label>
                                <input type="radio" name="studentDeficiency" value="yes" checked={studentDeficiency === true} onChange={handleDeficiencyChange} />
                                    Sim
                                </label>
                                <label>
                                <input type="radio" name="studentDeficiency" value="no" checked={studentDeficiency === false} onChange={handleDeficiencyChange} />
                                    Não
                                </label>
                            </div>
                        </div>

                        {studentDeficiency && (
                            <>
                                <hr />
                                <NewStudentDeficiency />

                                <div className="radioGroup">
                                    <label>O aluno necessita de avaliação de recursos?</label>
                                    <div>
                                        <label>
                                        <input type="radio" name="studentResourceAssessment" value="yes" checked={studentResourceAssessment === true} onChange={handleResourceAssessmentChange} />
                                            Sim
                                        </label>
                                        <label>
                                        <input type="radio" name="studentResourceAssessment" value="no" checked={studentResourceAssessment === false} onChange={handleResourceAssessmentChange} />
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}

                        {studentResourceAssessment && (
                            <>
                                <hr />
                                <NewStudentResourceAssessment />
                            </>
                        )}


                        <button className='save' onClick={sendForm}>Salvar</button>
                    </div>
                </>
            ) : <Loading />}
        </Template>
    );
}

export default SchoolSecretaryStudentsForm;
