import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

import CreateNewClasse from './createNewClasse';

const initData = {
    school_id: 0,
    school_year: "",
    teaching_type_id: 0,
    class_type_id: 0,
    shift_id: 0,
    class_serie: "",
    class_acron: "",
    start_time: "",
    end_time: "",
    start_classes: "",
    end_classes: "",
    max_students: "",
}

const SchoolSecretaryClassesForm: React.FC = () => {

    const [ready, setReady] = useState(true);
    const [data, setData] = useState<any>(initData);
    const navigate = useNavigate();

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { user } = useContext(GeneralContext);

    async function sendForm() {
        await api.post('', data, { headers: { Authorization: user.token } })
            .then(resp => {
                alert('Cadastro realizado com sucesso')
                console.log(resp)
                navigate('/secretaria-escolar/alunos')
            }).catch(err => {
                console.log("🚀 ~ sendForm ~ err:", err)
            })
    }

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLButtonElement>) {
        let { name, value } = event.target;
        change(name, value);
    }

    function change(name: string, value: string,) {
        // Set Data
        setData({ ...data, [name]: value });
    }

    // Breadcrumbs
    useEffect(() => {
        if (!breadcrumbs || breadcrumbs.curr !== "Nova Requisição") {
            setBreadcrumbs({
                curr: 'Nova Turma',
                links: [
                    { name: 'Home', url: '/' },
                    { name: 'secretaria escolar', url: '/secretaria-escolar' },
                    { name: 'Turmas', url: '/secretaria-escolar/turmas' },
                    { name: 'Nova Turma' }
                ]
            });
        }
    }, [breadcrumbs, setBreadcrumbs]);

    return (
        <Template page="Nova Requisição" pageTitle="Cadastro de Nova Requisição" className="secEducEmployeesForm">
            {ready ? (
                <>
                    <div className="headerBox">
                        <div className="buttons">
                            <Link to="/secretaria-escolar/turmas" className="btn back" title="Voltar para lista de Requisições">
                                <FaArrowLeft size={12} /><span> Voltar</span>
                            </Link>
                        </div>
                    </div>

                    <div className='contentForm'>
                        <CreateNewClasse/>
                        <button className='save' onClick={sendForm}>Salvar</button>
                    </div>
                </>
            ) : <Loading />}
        </Template>
    );
}

export default SchoolSecretaryClassesForm;
