import React, { useContext, useEffect, useState } from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';

const NewStudent: React.FC<any> = ({ onChange, errors, data }: any) => {
    const [classaAll, setClassAll] = useState<any>([]);
    const [school, setSchool] = useState<any>([]);
    const [ready, setReady] = useState(false);

    //Contexts
    const { user } = useContext(GeneralContext);

    async function loadInfos() {
        try {
            const [classaAll, school] = await Promise.all([
                api.get('/school_classes', { headers: { Authorization: user.token } }),
                api.get('/school', { headers: { Authorization: user.token } })
            ]);

            setClassAll(classaAll.data);
            setSchool(school.data);

            // Quando ambos os estados forem setados, setReady(true) é chamado.
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
            // Trate o erro apropriadamente, talvez com um setReady(false) ou exibição de mensagem de erro.
        }
    }

    useEffect(() => {
        if (!ready) {
            loadInfos()
        }
    }, [ready]);


    return (
        <>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_id">Escola</label>
                    <select onChange={onChange} id="school_id" name="school_id" value={data?.school_id} required>
                        <option value="">Selecione uma escola</option>
                        {school.map((schoolItem: any) => (
                            <option key={schoolItem.id} value={schoolItem.id}>
                                {schoolItem.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="formItem w40">
                    <label htmlFor="class_id">Turma</label>
                    <select onChange={onChange} id="class_id" name="class_id" value={data?.class_id} defaultValue={data?.class_id} required>
                    <option value="">Selecione a turma</option>
                        {classaAll.map((classItem: any) => (
                            <option key={classItem.id} value={classItem.id}>
                                { 
                                classItem.class_serie + classItem.class_acron + (` - ${classItem.class_id}`)
                                }
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="name"
                        type='text'
                        labelName='Nome Completo'
                        onChange={onChange}
                        value={data?.name}
                        error={errors && errors['name']}
                    />
                </div>
                <div className="formItem w1-2">
                    <InputLabel
                        name="social_name"
                        type='text'
                        labelName='Nome social'
                        onChange={onChange}
                        value={data?.social_name}
                        error={errors && errors['social_name']}
                    />
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="birth"
                        type='date'
                        labelName='Data de nascimento'
                        onChange={onChange}
                        value={data?.birth}
                        error={errors && errors['birth']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <InputLabel
                        name="mail"
                        type='text'
                        labelName='Email'
                        onChange={onChange}
                        value={data?.mail}
                        error={errors && errors['mail']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="mother_name"
                        type='text'
                        labelName='Nome da Mãe'
                        onChange={onChange}
                        value={data?.mother_name}
                        error={errors && errors['mother_name']}
                    />
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="father_name"
                        type='text'
                        labelName='Nome do Pai'
                        onChange={onChange}
                        value={data?.father_name}
                        error={errors && errors['father_name']}
                    />
                </div>
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="nationality">Nacionalidade</label>
                    <select onChange={onChange} id="nationality" name="nationality" value={data?.nationality} defaultValue={data?.nationality} required>
                        <option value="">Selecione</option>
                        <option value="Brasileiro">Brasileiro</option>
                        <option value="Estrangeiro">Estrangeiro</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="birth_city">Naturalidade</label>
                    <select onChange={onChange} id="birth_city" name="birth_city" value={data?.birth_city} defaultValue={data?.birth_city} required>
                    </select>
                </div>
                <div className="formItem w1-8">
                    <label htmlFor="birth_uf">UF</label>
                    <select onChange={onChange} id="birth_uf" name="birth_uf" value={data?.birth_uf} defaultValue={data?.birth_uf} required>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="country_origin">País de Origem</label>
                    <select onChange={onChange} id="country_origin" name="country_origin" value={data?.country_origin} defaultValue={data?.country_origin} required>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="sex">Gênero</label>
                    <select onChange={onChange} id="sex" name="sex" value={data?.sex} defaultValue={data?.sex} required >
                        <option value="">Selecione</option>
                        <option value="MASCULINO">Masculino</option>
                        <option value="FEMININO">Feminino</option>
                        <option value="OUTRO">Outro</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="color_race">Raça</label>
                    <select onChange={onChange} id="color_race" name="color_race" value={data?.color_race} defaultValue={data?.color_race} required >
                        <option value="">Selecione</option>
                        <option value="Branco">Branco</option>
                        <option value="Preto">Preto</option>
                        <option value="Pardo">Pardo</option>
                        <option value="Amarelo">Amarelo</option>
                        <option value="Indígena">Indígena</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="blood_type">Tipo Sanguíneo</label>
                    <select onChange={onChange} id="blood_type" name="blood_type" value={data?.blood_type} defaultValue={data?.blood_type} required>
                        <option value="">Selecione</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="organ_donor">Doador de Órgãos</label>
                    <select onChange={onChange} id="organ_donor" name="organ_donor" value={data?.organ_donor} defaultValue={data?.organ_donor} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>


                </div>

                {/* <div className="formItem wauto line">
                    <label htmlFor="organ_donor">Doador de Órgãos</label>
                    <input onChange={onChangeCheckbox} type="checkbox" name="organ_donor" />

                    <label htmlFor="quilombola">Indígena</label>
                    <input onChange={onChangeCheckbox} type="checkbox" name="organ_donor" />
                </div> */}

                <div className="formItem w1-4">
                    <label htmlFor="quilombola">Indígena</label>
                    <select onChange={onChange} id="quilombola" name="quilombola" value={data?.quilombola} defaultValue={data?.quilombola} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>


            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="deceased">Falecimento</label>
                    <select onChange={onChange} id="deceased" name="deceased" value={data?.deceased} defaultValue={data?.deceased} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="deceased_date"
                        type='date'
                        labelName='Data de falecimento'
                        onChange={onChange}
                        value={data?.deceased_date}
                        error={errors && errors['deceased_date']}
                    />
                </div>
            </div>

            <hr />

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="internet">Acesso a Internet</label>
                    <select onChange={onChange} id="internet" name="internet" value={data?.internet} defaultValue={data?.internet} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="pc_smartphone_tablet">Acesso Perifiricos</label>
                    <select onChange={onChange} id="pc_smartphone_tablet" name="pc_smartphone_tablet" value={data?.pc_smartphone_tablet} defaultValue={data?.pc_smartphone_tablet} required>
                        <option value="">Selecione</option>
                        <option value="true">sim - Table, celular ou PC</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="family_bag">Acesso a Bolsa familia</label>
                    <select onChange={onChange} id="family_bag" name="family_bag" value={data?.family_bag} defaultValue={data?.family_bag} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="family_bag">Acesso a Bolsa familia</label>
                    <select onChange={onChange} id="family_bag" name="family_bag" value={data?.family_bag} defaultValue={data?.family_bag} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="family_bag_cod"
                        type='text'
                        labelName='Codigo da bolsa familia'
                        onChange={onChange}
                        value={data?.family_bag_cod}
                        error={errors && errors['family_bag_cod']}
                    />
                </div>
            </div>
        </>
    );
};

export default NewStudent;