import React, { useContext } from "react";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalView: React.FC<any> = (props: any) => {
    console.log("🚀 ~ props:", props)
    // Contexts
    const { user } = useContext(GeneralContext);

    return <>
        <div className="modal-view">
            <h2>Detalhes do Veículo</h2>
            <p><strong>Nome:</strong> {props.data.name}</p>
            <p><strong>Descrição:</strong> {props.data.description}</p>
            <p><strong>Motorista:</strong> {props.data.driver.nome}</p>
            <p><strong>Veículo:</strong> {props.data.vehicle.name}</p>
            <p><strong>Monitor:</strong> {props.data.monitor.name}</p>

            {/* Exibindo a lista de alunos */}
            <p><strong>Alunos:</strong></p>
            <ul>
                {props.data.alunos && props.data.alunos.length > 0 ? (
                    props.data.alunos.map((aluno: any, index: number) => (
                        <li key={index}>{aluno.name}</li>
                    ))
                ) : (
                    <p>Sem alunos cadastrados</p>
                )}
            </ul>
        </div>
    </>;
};

export default ModalView;
