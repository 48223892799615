import React, { useContext, useEffect } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';

const SupervisionDirectionActivities: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatório de Atividades") && setBreadcrumbs({
            curr: 'Relatório de Atividades',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Atividades' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Relatório de Atividades" pageTitle="Supervisão e Direção - Relatório de Atividades" className="supDirectionActivities">
        Supervisão e Direção - Relatório de Alunos<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
    </Template>;
}

export default SupervisionDirectionActivities;