import React from "react";
import './styles.css';

interface LegendComponentProps {
    eventColors: { [key: string]: string };
    submit: () => void;
}

const LegendComponent: React.FC<LegendComponentProps> = ({ eventColors, submit }) => {
    return (
        <div className="legend">
            <h3>Legenda</h3>
            {
                Object.entries(eventColors).map(([eventType, color]) => (
                    <div key={eventType} className="legend-item">
                        <span
                            className={`legend-color ${color}`}
                            aria-label={`Cor do evento ${eventType}`}
                        />

                        <span className="legend-text">{eventType}</span>
                    </div>
                ))
            }

            <button onClick={submit} aria-label="Salvar mudanças">Salvar</button>
        </div>
    );
};

export default LegendComponent;
