import React, { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import api from '~/services/api';
import "./styles.css"

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import RequestBox from './Box';
import NewRequest from './Form';
import Products from './Modal/products';
import View from './Modal/view';
import Cancel from './Modal/cancel';

export interface Request {
    id           : number;
    id_user      : number;
    resource_data: string;
    state        : string;
    justification: string;
    inactive     : boolean;
    delivery_date: Date;
}

const SchoolSecretaryMaterials: React.FC = () => {
    const [requests, setRequests] = useState<Request[]>([]);
    const [requestsList, setRequestsList] = useState<Request[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [ready, setReady] = useState(false);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data: any) {
        if (type==="View") {
            setModalClass('viewRequest')
            setModalTitle('Visualizar Requisição')
            setModalBody(<>Visualizar</>)
            setModalBody(<View {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "NewRequest") {
            setModalClass('newRequest')
            setModalTitle('Nova Requisição')
            setModalBody(<NewRequest setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "Cancel") {
            setModalClass('cancelRequest')
            setModalTitle('Cancelando Requisição')
            setModalBody(<Cancel {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "AllProducts") {
            setModalClass('prodsRequest')
            setModalTitle('Visualizando Produtos')
            setModalBody(<Products products={products} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons= {
                    [
                        {
                            nameButton: "Nova Solicitação",
                            className: "add",
                            onClick: () => openModal("NewRequest", products),
                            icons: <FaPlus size={14} />
                        }, {
                            nameButton: "Lista de Produtos em estoque",
                            className: "info",
                            onClick: () => openModal("AllProducts", products)
                        }
                    ]
                }
                search={{
                    dataList: requestsList,
                    filterKey: ["name"],
                    setFilteredList: setRequests
                }}
                isViewButton={true}
                isViewSearch={false}
            />

            {
                requests && requests.length > 0 ? (
                    <div className='materials'>
                        {
                            requests.map((requestData: any) => (
                                <RequestBox
                                    key={`request_box_${requestData.id}`}
                                    {...requestData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma requisição</div>
            }
        </>
    }

    useEffect(() => {
        if (!ready) {
            api.get('warehouse_requests', {
                headers: { Authorization: user.token }
            }).then(resp => {
                setRequests(resp.data);
                setRequestsList(resp.data);
                setReady(true);
            }).catch(err => {
                setError('Erro ao carregar os dados dos estudantes')
                    setReady(true)
            });

            api.get('warehouse_resources', {
                headers: { Authorization: user.token }
            }).then(resp => {
                setProducts(resp.data);
                setReady(true);
            }).catch(err => {
                setError('Erro ao carregar os dados dos estudantes')
                    setReady(true)
            });
        }
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Requisições") && setBreadcrumbs({
            curr: 'Requisições',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Requisições' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Solicitações de Materiais e Serviços" pageTitle="Requisições de Materiais e Serviços" className="requests">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default SchoolSecretaryMaterials;