import React from 'react';
import './styles.css';

const UnitInfos: React.FC<any> = ({ data }) => {

    return <>
        <h3>{data.name}</h3>
        <p><b>Endereço:</b> {data.address}, {data.address_number ? data.address_number : 'S/N'}, {data.address_neighborhood}</p>
        <p><b>Cidade/CEP:</b> {data.address_city} / {data.address_cep}</p>
        <p><b>Tipo:</b> {data.teaching.name}</p>
    </>
}

export default UnitInfos;