import React from 'react';
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

const BasicInfoTab: React.FC<any> = ({data, errors, onChange}: any) => {
    return (
        <>
            <div className="formGroup">
                <div className="formItem wauto">
                    <InputLabel
                        name="name"
                        type='text'
                        labelName='Nome Completo'
                        value={data?.name}
                        onChange={onChange}
                        error={errors && errors['name']}
                    />
                </div>
                <div className="formItem w30">

                    <InputLabel
                        name="phone"
                        type='text'
                        labelName='Telefone'
                        value={data?.phone}
                        onChange={onChange}
                        error={errors && errors['phone']}
                    />
                </div>
            </div>
        </>
    );
};

export default BasicInfoTab;
