import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import loading from './images/loading.json';

const Loading: React.FC = () => {
    return <div className='loading'>
        <Lottie
            animationData={loading}
            speed={1.3}
            loop play
            className='icon'
        />
    </div>;
}

export default Loading;