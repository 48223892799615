import React, { useEffect, useState } from 'react';
import './styles.css';

const PermissionInfos: React.FC<any> = (props: any) => {
    const [ready, setReady] = useState(false);
    const [rules, setRules] = useState<any[]>([]);

    useEffect(() => {
        if (!ready) {
            let rules: any = [];
            let rule       = '';
            let item: any  = {};

            props.rules.map((el: any, idx: number) => {
                if (idx === 0 || rule !== el.page) {
                    if (idx !== 0) rules.push(item);
                    rule = el.page;

                    // Create new item
                    item = {
                        id: el.id,
                        name: el.page,
                        rules: []
                    }
                }

                item.rules.push({ name: el.subpage, ready: el.ready ? true : false, write: el.write ? true : false })

                if ((props.rules.length - 1) === idx) rules.push(item);
            })

            setRules(rules)
            setReady(true)
        }
    }, [ready])

    return <>
        <h3>{props.name}</h3>

        <hr />

        {
            ready ? rules.map((el: any) => <div key={`page_${el.id}`} className='pageBox'>
                <div className="header">
                    <h4>{el.name}</h4>
                    <div className="status">
                        <div className="ready">Leitura</div>
                        <div className="write">Edição</div>
                    </div>
                </div>

                {
                    el.rules.map((el2: any) => <div key={`rule_${el2.name}_${el.id}`} className='ruleLine'>
                        <div className='title'>{el2.name}</div>

                        <div className="status">
                            <div className={el2.ready ? 'yes' : 'no'}>{el2.ready ? 'Sim' : 'Não'}</div>
                            <div className={el2.write ? 'yes' : 'no'}>{el2.write ? 'Sim' : 'Não'}</div>
                        </div>
                    </div>)
                }
            </div>) : <></>
        }
    </>;
}

export default PermissionInfos;