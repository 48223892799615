import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import PermissionsBox from './Box';

const SecretaryEducationPermissions: React.FC = () => {
    const [ready, setReady]                     = useState(false);
    const [permissions, setPermissions]         = useState<any[]>([]);
    const [permissionsList, setPermissionsList] = useState<any[]>([]);
    const [error, setError]                     = useState<string | null>(null);

     // Contexts
     const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ url: "novo", nameButton: "Adicionar Permissões", className: "add" }]}
                search={{
                    dataList: permissionsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "position.name"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: (filteredData: any[]) => { },   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                placeholder="Filtrar Permissões..."
                isViewButton={true}
                isViewSearch={true}
            />

            {
                permissions && permissions.length > 0 ? (
                    <div className='permissions'>
                        {
                            permissions.map((permissionsData: any) => (
                                <PermissionsBox key={`permissions_box_${permissionsData.id}`} {...permissionsData} setReady={setReady} />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum cargo</div>
            }
        </>;
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('permissions', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setPermissions(resp.data);
            setPermissionsList(resp.data);
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados dos cargos')
            setReady(true)
        });
    }, [ready]);

     // Breadcrumbs
     useEffect(() => {
         (!breadcrumbs || breadcrumbs.curr!=="Permissões") && setBreadcrumbs({
             curr: 'Permissões',
             links: [
                 { name: 'Home', url: '/'},
                 { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                 { name: 'Permissões' }
             ]
         });
     }, [breadcrumbs]);

     return <Template page="Matrículas - Permissões" pageTitle="Secretaria da Educação - Permissões" className="secEducPermissions">
        { ready ? getContent() : <Loading /> }
     </Template>;
}

export default SecretaryEducationPermissions;