import React, { useContext, useEffect, useState } from 'react';
import './styles.css';
// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import TransportStudentsBox from './Box';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import { ModalContext } from '~/contexts/modal';
import Create from './Modal/create';
import View from './Modal/view';
import Edit from './Modal/edit';
import Remove from './Modal/remove';

const TransportStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);
    const [error, setError]               = useState<string | null>(null);
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const {setModalOpen,setModalClass, setModalTitle, setModalBody} = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "add"){
            setModalClass('')
            setModalTitle('Criar Novo Veiculo')
            setModalBody(<Create  setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<View data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }
/*         else if (type === "edit"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<Edit data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } */
        else if (type === "remove"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<Remove data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "Adicionar aluno em rota", onClick: () => {openModal("add", students)}, className: "add" }
                ]}
                search={{
                    dataList: studentsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setStudents   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                students && students.length > 0 ? (
                    <div className='students'>
                        {
                            students.map((studentData: any) => (
                                <TransportStudentsBox
                                    key={'student_' + studentData.id}
                                    {...studentData}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
            }
        </>
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Alunos' }
            ]
        });
        setReady(true)
        setStudents([
            {
                name: "João Ribeiro",
                rota: "Linha 5",
                attendance: [
                    { month: "Outubro", day: 14, state: true },
                    { month: "Outubro", day: 13, state: true },
                    { month: "Outubro", day: 12, state: false }
                ]
            },
            {
                name: "Maria Silva",
                rota: "Linha 2",
                attendance: [
                    { month: "Outubro", day: 14, state: false },
                    { month: "Outubro", day: 13, state: true },
                    { month: "Outubro", day: 12, state: true }
                ]
            },
            {
                name: "Carlos Souza",
                rota: "Linha 1",
                attendance: [
                    { month: "Outubro", day: 14, state: true },
                    { month: "Outubro", day: 13, state: false },
                    { month: "Outubro", day: 12, state: true }
                ]
            },
            {
                name: "Ana Pereira",
                rota: "Linha 3",
                attendance: [
                    { month: "Outubro", day: 14, state: true },
                    { month: "Outubro", day: 13, state: true },
                    { month: "Outubro", day: 12, state: true }
                ]
            },
            {
                name: "Lucas Fernandes",
                rota: "Linha 4",
                attendance: [
                    { month: "Outubro", day: 14, state: false },
                    { month: "Outubro", day: 13, state: true },
                    { month: "Outubro", day: 12, state: false }
                ]
            }
        ]);
        
    }, [breadcrumbs]);

    return <Template page="Alunos" pageTitle="Transporte - Alunos" className="secEducStudents">
       { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportStudents;