import React from "react";
import dates from '~/utils/dates';
import './styles.css';

const ModalView: React.FC<any> = (props: any) => {
    const data = JSON.parse(props.resource_data);

    return <>
        <div className="header">
            <h2>{props.state}</h2>
            <div className="reqDate">
                <b>Solicitado em</b>
                <span>{dates.getDateFormatted(props.created_at, 'dd/mm/yyyy')}</span>
            </div>
        </div>

        <hr />

        <div className="items">
            <div className="title">
                <div className="prod">Produto</div>
                <div className="qtty">Quantidade</div>
            </div>

            {
                data.map((el: any) => <div key={`item_${el.productId}`} className="line">
                    <div className="prod">{el.name}</div>
                    <div className="qtty">{el.quantity} {el.measure}</div>
                </div>)
            }
        </div>
    </>
}

export default ModalView;
