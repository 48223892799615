const Menu = [
    {
        id: 1,
        name: 'Secretaria da Educação',
        subpages: [
            'Calendário Escolar',
            'Matrículas',
            'Boletins',
            'Gerenciamento de Aulas'
        ]
    }, {
        id: 2,
        name: 'Professores',
        subpages: [
            'Cadastro de Professores',
            'Listagem de Professores',
            'Turmas',
            'Horários de Aula'
        ]
    }, {
        id: 3,
        name: 'Alunos',
        subpages: [
            'Cadastro de Alunos',
            'Listagem de Alunos',
            'Boletins',
            'Frequência',
            'Ocorrências'
        ]
    }, {
        id: 4,
        name: 'Pais e Responsáveis',
        subpages: [
            'Cadastro de Responsáveis',
            'Listagem de Responsáveis',
            'Comunicação'
        ]
    }, {
        id: 5,
        name: 'Nutrição',
        subpages: [
            'Cardápios',
            'Controle de Estoque',
            'Receitas'
        ]
    }, {
        id: 6,
        name: 'Transporte Escolar',
        subpages: [
            'Rotas',
            'Veículos',
            'Motoristas'
        ]
    }, {
        id: 7,
        name: 'Supervisão e Direção',
        subpages: [
            'Relatórios',
            'Avaliações',
            'Metas Educacionais'
        ]
    }, {
        id: 8,
        name: 'Biblioteca',
        subpages: [
            'Cadastro de Livros',
            'Empréstimos',
            'Devoluções'
        ]
    }, {
        id: 9,
        name: 'Almoxarifado',
        subpages: [
            'Entrada de Materiais',
            'Saída de Materiais',
            'Controle de Estoque'
        ]
    }, {
        id: 10,
        name: 'Administração',
        subpages: [
            'Configurações do Sistema',
            'Gerenciamento de Usuários',
            'Relatórios Administrativos'
        ]
    }, {
        id: 11,
        name: 'Financeiro',
        subpages: [
            'Contas a Pagar',
            'Contas a Receber',
            'Orçamentos',
            'Relatórios Financeiros'
        ]
    }, {
        id: 12,
        name: 'Recursos Humanos',
        subpages: [
            'Cadastro de Funcionários',
            'Férias',
            'Folha de Pagamento'
        ]
    }
];

export default Menu;