import React, { useState, useRef, useCallback } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import env from '~/utils/env';

const libraries: any[] = ['places'];

const AddressAutocomplete: React.FC<any> = ({onChange, onAddressChange, data} : any) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: env.googleMapsApiKey,
        libraries,
    });

    const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

    const handlePlaceChanged = useCallback(() => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();

            if (place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setCoordinates({ lat, lng });
            }

            if (place.formatted_address) {
                // setAddress(place.formatted_address);

                const text = place.formatted_address

                // Expressão Regular
                const regex = /^R\.?\s*(.*?)\s*-\s*(.*?)\s*,\s*(.*?)\s*-\s*(\w{2})(?:\s*,\s*(\d{5}-\d{3}))?\s*,\s*(.*)$/;

                // Executar a expressão regular
                const match = text.match(regex);

                if (match) {
                    const [, rua, bairro, cidade, uf, cep, pais] = match;

                    onAddressChange({
                        rua,
                        bairro,
                        cidade,
                        uf,
                        cep: cep ?? "",
                        pais
                    });
                } else {
                    console.log("Texto não corresponde ao formato esperado.");
                }
            }
        }
    }, []);

    const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
        autocompleteRef.current = autocomplete;
    }, []);

    if (!isLoaded) {
        return <div>Carregando...</div>;
    }

    return (
        <Autocomplete
            onLoad={onLoad}
            className='box-autocomplete'
            onPlaceChanged={handlePlaceChanged}
        >
            <input
                id="autocomplete"
                className='inputLabel'
                name="address"
                type="text"
                placeholder="Digite o nome da rua"
                value={data.address}
                onChange={onChange}
            />
        </Autocomplete>
    );
};

export default AddressAutocomplete;
