import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalCreate: React.FC<any> = (props: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return <>
       {/* select de alunos sem rotas */}
       {/* select de rotas existentes*/}
    </>;
};

export default ModalCreate;
