import React, { useContext, useEffect, useState } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

const Dashboard: React.FC = () => {
    const [ready, setReady] = useState(false);

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && setTimeout(() => setReady(true), 2000);
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Dashboard") && setBreadcrumbs({
            curr: 'Dashboard',
            links: [
                { name: 'Home' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Dashboard" pageTitle="Dashboard" className="dash">
        {
            // !ready ?
            <Loading />
        }
    </Template>;
}

export default Dashboard;