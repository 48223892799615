import React from 'react';
import { FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

interface Props {
    id: number;
    name: string;
    quantity: string;
    description: string;
    inactive: boolean;
    resourceMeasure: {
        id: number;
        measure: string;
        description: string;
    }
    resourceType: {
        id: number;
        type: string;
        name: string;
        description: string;
    }
    openModal: Function;
}

const ProductBox: React.FC<any> = (props: Props) => {
    function getButtons() {
        return <>
            <button type="button" className="edit" onClick={() => props.openModal("Edit", props)} title="Deletar Produto">
                <FaPencil size={14} /> <span>Editar</span>
            </button>

            <button type="button" className="inactivate" onClick={() => props.openModal('Delete', props)} title="Editar Produto">
                <FaTrash size={14} /> <span> Deletar</span>
            </button>
        </>
    }

    return <div className="productBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="description">
                { props.description }
            </div>

            <hr />

            <div className="quantity">
                <b>Quantidade:</b> {props.quantity} {props.resourceMeasure.measure.toLocaleLowerCase()}
            </div>

            <div className="category">
                <b>Categoria:</b> {props.resourceType.name}
            </div>
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default ProductBox;
