import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {FaTimes} from 'react-icons/fa';
import cryptos from '~/utils/cryptos';
import api from '~/services/api';
import '../auth.css';
import './styles.css';
import apiIP from '~/services/getIp'


// Contexts
import { GeneralContext } from '~/contexts';

// Components
import {InputLabel, Checkbox} from '~/components/Forms';

// Images
import logo from '~/assets/images/4educ_logo3.png';
import LoginGoogle from '~/components/Auth/LoginGoogle';

// Constants
const initialState = {mail: '', password: '', ip: apiIP.get()};

const Login: React.FC = () => {
	let params = useParams();
	let text   = params.text;

    const navigate              = useNavigate();
    const {user, login}         = useContext(GeneralContext);
    const [values, setValues]   = useState(initialState);
    const [message, setMessage] = useState<string>();
    const [errors, setErrors]   = useState<String[]>([]);

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setValues({...values, [name]: value})
    }

	async function onSubmit (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (values.mail==='' || values.password==='') {
			var errArr = [];

			if (values.mail === '') errArr.push('O campo de email está vazio');
			if (values.password === '') errArr.push('O campo de senha está vazio');

			setErrors(errArr);
			return;
		}

		let dataSubmit = {...values, ip: await apiIP.get()}

		api.post('login', dataSubmit).then(async (resp: any) => {
			const user = cryptos.decryptServer(resp.data);
			login(JSON.parse(user));
			document.querySelector('#root')?.classList.remove('login');
		}).catch((err: any) => {
			console.log(err)
			const { status } = err.response;
			if (status===400) {
				const error = err.response.data.errors;
				setErrors(error);
			}

			if (status===403) {
				const id = err.response.data.id;
				const error = err.response.data.errors;
				setErrors(error);
			}
		});

		setValues({ ...values, password: '' });
	}

	function treatErrors() {
        if (message!=='') {
            setMessage('');
        }

        return <div className="boxErrors">
			{ errors.map(el => <span key={`error_${el}`}>{ el }</span>) }
		</div>
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
                <div className="message">
                    { message }
                </div>

                <button type="button" onClick={() => { setMessage(''); navigate('/login', {replace: true}) }} className="close">
                    <FaTimes size="16" />
                </button>
            </div>
        )
    }

	// Set Message
	useEffect(() => {
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [user, text, errors])

	// Test Navigate
	useEffect(() => {
		if (user.token) navigate('/', {replace: true})
	}, [user])

	return (
		<div id="login" className="auth">
			<div className='header'>
				<img src={logo} alt="Logo Energizou" />

				<h2>Faça login para continuar</h2>
			</div>

			{ Array.isArray(errors) && errors.length>0 && treatErrors() }
			{ !errors.length && message && treatMessage() }

			<form onSubmit={onSubmit} autoComplete='off' method="POST">
				<InputLabel name='mail' type='mail' labelName='E-mail' value={values.mail} onChange={onChange}/>
				<InputLabel name='password' type='password' labelName='Senha' value={values.password} onChange={onChange} style={{marginTop: '20px'}} />

				<div className="boxPass">
					<Checkbox id='remember' name='remember' labelName='Lembrar-me'/>

					<Link to='/lembrar-senha' className="forgot dec">
						Esqueceu sua senha?
					</Link>
				</div>

				<button className="send" type="submit">
					Entrar
				</button>

				<div className="login-social">
					<div className="line-container">
						<div className="line"></div>
						<span className="text">ou</span>
						<div className="line"></div>
					</div>

				</div>

				<LoginGoogle/>
			</form>


		</div>
	)
}
export default Login
