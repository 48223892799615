import React, { useContext, useEffect, useState, useCallback } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import MapComponent from './map';
import api from '~/services/api';

const SecretaryEducationMapping: React.FC = () => {
    const [ready, setReady] = useState<boolean>(false);
    const [schools, setSchools] = useState<any[]>([]);
    const [students, setStudents] = useState<any[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Fetch data
    const fetchData = useCallback(async () => {
        try {
            const [resp] = await Promise.all([
                api.get('secEduc/map', { headers: { Authorization: user.token } }),
            ]);

            setSchools(resp.data.schools);
            setStudents(resp.data.students);
            setReady(true);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }, [user.token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Mapeamento de Alunos e Escolas") && setBreadcrumbs({
            curr: 'Mapeamento de Alunos e Escolas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                { name: 'Mapeamento' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Mapeamento de Alunos e Escolas" pageTitle="Secretaria da Educação - Mapeamento de Alunos e Escolas" className="secEducMap">
            <MapComponent ready={ready} schools={schools} students={students} />
        </Template>
    );
}

export default SecretaryEducationMapping;
