import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaAddressCard, FaBook, FaCalendarPlus, FaChalkboardUser, FaChartSimple, FaUsersLine } from 'react-icons/fa6';
import { SiGoogleclassroom } from "react-icons/si";
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const Teacher: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'professor', 'turmas') && (
                        <Link to="/professor/turmas" className={location.pathname === '/professor/turmas' ? ' active' : ''}>
                            <FaUsersLine />
                            <span>Turmas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'professor', 'alunos') && (
                        <Link to="/professor/alunos" className={location.pathname === '/professor/alunos' ? ' active' : ''}>
                            <FaAddressCard />
                            <span>Alunos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'professor', 'atividades') && (
                        <Link to="/professor/atividades" className={location.pathname === '/professor/atividades' ? ' active' : ''}>
                            <FaBook />
                            <span>Provas e Atividades</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'professor', 'notas') && (
                        <Link to="/professor/notas" className={location.pathname === '/professor/notas' ? ' active' : ''}>
                            <FaChartSimple />
                            <span>Notas e Outros</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'professor', 'planejamento') && (
                        <Link to="/professor/planejamento" className={location.pathname === '/professor/planejamento' ? ' active' : ''}>
                            <FaCalendarPlus />
                            <span>Planejamento de Aulas</span>
                        </Link>
                    )
                }

            </div>
        );
    }

    return (
        <>
            <Link to="/professor" className={location.pathname.indexOf('/professor') === 0 ? ' active' : ''}>
                <FaChalkboardUser />
                <span>Professor</span>
            </Link>

            {location.pathname.indexOf('/professor') === 0 && submenu()}
        </>
    );
}

export default Teacher;
