import React, { useContext, useEffect, useState } from 'react';
import { FaListCheck } from 'react-icons/fa6';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import StudentBox from './Box'

const TeacherStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [selClass, setSelClasss]        = useState<any>(null);
    const [classes, setClasses]           = useState<any>([]);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getSelectClasses() {
        if (classes.length === 0) return undefined;

        return <select
            className='classSel'
            defaultValue={selClass ? selClass : ''}
            onChange={(evt) => {
                setSelClasss(evt.currentTarget.value !== '' ? evt.currentTarget.value : null);
                setReady(false);
            }}
        >
            <option value="">-- Classe --</option>
            <optgroup label='Classes'>
                {
                    classes.map((el: any) => <option value={el.id}>{el.class_serie}ª Série{ el.class_acron ? ' ' + el.class_acron.toUpperCase() : '' }</option>)
                }
            </optgroup>
        </select>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get(`teacher/students${selClass ? '/' + selClass : ''}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data.students);
            setStudentsList(resp.data.students);
            setClasses(resp.data.classes);
            if (resp.data.classes.length===1) setSelClasss(resp.data.classes[0].id);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Professor', url: '/professor'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Alunos" pageTitle="Professor - Alunos" className="teacherStudents">
        {
            ready ? <>
                <HeaderSearchAndForm
                    buttons={[
                        {
                            nameButton: "Lista de Presença",
                            icons: <FaListCheck size={14} />,
                            className: "presence",
                            onClick: () => alert("Lista de Presença")
                        }
                    ]}
                    module={getSelectClasses()}
                    search={{
                        dataList: studentsList, // Substitua 'yourDataList' pela sua lista de dados
                        filterKey: ["id", "school.name", "teaching.name"], // Substitua 'nome' pela chave que você deseja filtrar
                        setFilteredList: setStudents // Substitua 'setYourList' pela função que define a lista filtrada
                    }}
                    isViewButton={true}
                    isViewSearch={true}
                />

                {
                    students && students.length > 0 ? (
                        <div className="students">
                            { students.map((data: any) => <StudentBox key={`std_${data.id}`} data={data} />) }
                        </div>
                    ) : <div className='noData'>Não foi possível localizar nenhum aluno</div>
                }
            </> : <Loading />
        }
    </Template>;
}

export default TeacherStudents;