import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSchool, FaMap, FaUsers, FaChartGantt, FaCalendarDays, FaFilePen } from 'react-icons/fa6';
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaGraduationCap } from 'react-icons/fa';
import { FaPersonWalking } from "react-icons/fa6";
import { GeneralContext } from '~/contexts/general';
import { TbLockX } from "react-icons/tb";
import { hasPermission } from '~/utils/permissions';

const SecretaryEducation: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'mapeamento') && (
                        <Link to="/secretaria-educacao/mapeamento" className={location.pathname === '/secretaria-educacao/mapeamento' ? ' active' : ''}>
                            <FaMap />
                            <span>Mapeamento</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'funcionarios') && (
                        <Link to="/secretaria-educacao/funcionarios" className={location.pathname.indexOf('/secretaria-educacao/funcionarios')>=0 ? ' active' : ''}>
                            <FaUsers />
                            <span>Funcionários da Educação</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'escolas') && (
                        <Link to="/secretaria-educacao/escolas" className={location.pathname === '/secretaria-educacao/escolas' ? ' active' : ''}>
                            <FaGraduationCap />
                            <span>Unidades Escolares</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'demais-unidades') && (
                        <Link to="/secretaria-educacao/demais-unidades" className={location.pathname === '/secretaria-educacao/demais-unidades' ? ' active' : ''}>
                            <HiBuildingOffice2 />
                            <span>Demais unidades</span>
                        </Link>
                    )
                }

                {
                    // hasPermission(user.permissions, user.role, 'secretaria-educacao', 'desempenho') && (
                    //     <Link to="/secretaria-educacao/desempenho" className={location.pathname === '/secretaria-educacao/desempenho' ? ' active' : ''}>
                    //         <FaChartGantt />
                    //         <span>Desempenho</span>
                    //     </Link>
                    // )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'calendario') && (
                        <Link to="/secretaria-educacao/calendario" className={location.pathname === '/secretaria-educacao/calendario' ? ' active' : ''}>
                            <FaCalendarDays />
                            <span>Calendário Escolar</span>
                        </Link>
                    )
                }

                {
                    // hasPermission(user.permissions, user.role, 'secretaria-educacao', 'matriculas') && (
                    //     <Link to="/secretaria-educacao/matriculas" className={location.pathname === '/secretaria-educacao/matriculas' ? ' active' : ''}>
                    //         <FaFilePen />
                    //         <span>Matrículas</span>
                    //     </Link>
                    // )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'permissoes') && (
                        <Link to="/secretaria-educacao/permissoes" className={location.pathname.indexOf('/secretaria-educacao/permissoes')>=0 ? ' active' : ''}>
                            <TbLockX />
                            <span>Permissões de usuários</span>
                        </Link>
                    )
                }

{
                    hasPermission(user.permissions, user.role, 'secretaria-educacao', 'cargos') && (
                        <Link to="/secretaria-educacao/cargos" className={location.pathname === '/secretaria-educacao/cargos' ? ' active' : ''}>
                            <FaPersonWalking />
                            <span>Cargos</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/secretaria-educacao" className={location.pathname.indexOf('/secretaria-educacao') === 0 ? ' active' : ''}>
                <FaSchool />
                <span>Secretaria da Educação</span>
            </Link>

            {location.pathname.indexOf('/secretaria-educacao') === 0 && submenu()}
        </>
    );
}

export default SecretaryEducation;
