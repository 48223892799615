import { ChangeEvent, useContext, useState } from "react";
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";
import api from "~/services/api";
import './stylesModal.css';

const FormCreateTypeAndMeasure = ({ option }: { option: string }) => {
    const [infoData, setInfoData] = useState({ name: "", describe: "" });
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        setInfoData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const salvarDadosDoForm = async () => {
        // Trim spaces and check if the fields are not empty
        if (infoData.name.trim() === "" || infoData.describe.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        setLoadData(true);
        setError(null);

        try {
            if (option === "measure") {
                const dataFormat = { measure: infoData.name, description: infoData.describe };
                const resp = await api.post('warehouse_measure/', dataFormat, {
                    headers: { Authorization: user.token }
                });
                console.log("🚀 ~ salvarDadosDoForm ~ resp:", resp);
                Modal.setModalOpen(false);
            } else if (option === "type") {
                const dataFormat = { type: infoData.name, description: infoData.describe };
                const resp = await api.post('warehouse_resource_type/', dataFormat, {
                    headers: { Authorization: user.token }
                });
                console.log("🚀 ~ salvarDadosDoForm ~ resp:", resp);
                Modal.setModalOpen(false);
            } else {
                setError("Opção inválida.");
            }
        } catch (err) {
            console.error("🚀 ~ salvarDadosDoForm ~ err:", err);
            setError("Erro ao salvar os dados. Tente novamente.");
        } finally {
            setLoadData(false);
        }
    };

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="form-group no-margin">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="exemplo"
                        name="name"
                        value={infoData.name}
                        onChange={onChange}
                        required
                    />

                    <label>Descrição</label>
                    <input
                        type="text"
                        placeholder="Exemplo"
                        name="describe"
                        value={infoData.describe}
                        onChange={onChange}
                        required
                    />
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={salvarDadosDoForm}>Adicionar</button>
                    <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default FormCreateTypeAndMeasure;
