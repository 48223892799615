import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaPencil, FaTrash } from 'react-icons/fa6';
import { FaEye } from 'react-icons/fa';
import './styles.css';

// Components
import Delete from './delete';
import Infos from './infos';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    id      : number;
    name    : string;
    editable: boolean;
    rules   : [];
    setReady: Function;
}

const PositionBox: React.FC<any> = (props: Props) => {
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        setModalOpen(true);

        if (type==="delete") {
            setModalClass('permissionsDelete')
            setModalTitle('Deletar Permissão')
            setModalBody(<>Deletar</>)
            setModalBody(<Delete {...props} setModalOpen={setModalOpen} />)
        } else {
            setModalClass('permissionsInfos')
            setModalTitle('Visualizando Permissões')
            setModalBody(<Infos {...props} />)
        }

        setModalOpen(true)
    }

    function getButtons() {
        return props.editable ? <>
            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Permissões">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <Link to={`/secretaria-educacao/permissoes/edit/${props.id}`} className="btn edit" title="Editar Permissão">
                <FaPencil size={12} /> <span> Editar</span>
            </Link>

            <button type="button" className="delete" onClick={() => openModal('delete')} title="Deletar Permissão">
                <FaTrash size={12} />
            </button>
        </> : <div className='admin'>Requer Nivel Admin</div>
    }

    return <div className="permissionBox">
            <div className="infos">
            <h3>{props.name}</h3>

            <div className="permissionUsing">
                <b>Permissions:</b> { props.name==="Admin" ? "Todas" : props.rules.length }
            </div>
        </div>

        <div className="buttons">
            { getButtons() }
        </div>
    </div>;
}

export default PositionBox;