import React, { useContext } from 'react';
import { FaEye, FaRectangleList } from 'react-icons/fa6';
import dates from '~/utils/dates';
import './styles.css';

// Components
import Infos from './infos';
import Presence from './presence';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    data: any;
}

const StudentBox: React.FC<any> = ({ data }: Props) => {
    const yearsOld = dates.getYearsOld(data.birth);
    const birth    = dates.getDateFormatted(data.birth);

    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        if (type === "stdPresence") {
            setModalClass('presenceList')
            setModalTitle('Presença do Aluno');
            setModalBody(<Presence />)
        } else {
            setModalClass('studentInfos')
            setModalTitle('Visualizando Aluno')
            setModalBody(<Infos data={data} />)
        }

        setModalOpen(true);
    }

    return <div className="studentBox">
        <div className="infos">
            <h3>{data.name}</h3>

            <div className="ra">
                <b>RA:</b> { data.ra_number }
            </div>

            <div className="birth">
                { birth.split('-').join('/') } <i>({yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')})</i>
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Aluno">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <button type="button" className="presence" onClick={() => openModal('stdPresence')} title="Visualizar Presença">
                <FaRectangleList size={16} />
            </button>
        </div>
    </div>;
}

export default StudentBox;