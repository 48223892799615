import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBriefcase, FaChartSimple, FaFileInvoiceDollar, FaFileLines, FaUser, FaUserGraduate } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const SupervisionDirection: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'supervisao-e-direcao', 'termo-de-visita') && (
                        <Link to="/supervisao-e-direcao/termo-de-visita" className={location.pathname === '/supervisao-e-direcao/termo-de-visita' ? ' active' : ''}>
                            <FaFileInvoiceDollar />
                            <span>Termo de Visita</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'supervisao-e-direcao', 'professores') && (
                        <Link to="/supervisao-e-direcao/professores" className={location.pathname === '/supervisao-e-direcao/professores' ? ' active' : ''}>
                            <FaUser />
                            <span>Relatório de Professores</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'supervisao-e-direcao', 'alunos') && (
                        <Link to="/supervisao-e-direcao/alunos" className={location.pathname === '/supervisao-e-direcao/alunos' ? ' active' : ''}>
                            <FaUserGraduate />
                            <span>Relatório de Alunos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'supervisao-e-direcao', 'notas') && (
                        <Link to="/supervisao-e-direcao/notas" className={location.pathname === '/supervisao-e-direcao/notas' ? ' active' : ''}>
                            <FaChartSimple />
                            <span>Relatório de Notas e Faltas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'supervisao-e-direcao', 'atividades') && (
                        <Link to="/supervisao-e-direcao/atividades" className={location.pathname === '/supervisao-e-direcao/atividades' ? ' active' : ''}>
                            <FaFileLines />
                            <span>Relatório de Atividades</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/supervisao-e-direcao" className={location.pathname.indexOf('/supervisao-e-direcao') === 0 ? ' active' : ''}>
                <FaBriefcase />
                <span>Supervisão e Direção</span>
            </Link>

            {location.pathname.indexOf('/supervisao-e-direcao') === 0 && submenu()}
        </>
    );
};

export default SupervisionDirection;
