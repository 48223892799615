import React, { useState, useEffect, useContext } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import dateFormat from 'dateformat';
import api from '~/services/api';
import './styles.css'; // Arquivo CSS para estilos

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import View from '../Modal/view';
import Create from '../Modal/create'; // Corrigido o import
import Loading from '~/components/Default/Loading';

const Calendar: React.FC = () => {
    const [menu, setMenu]   = useState<any[]>([]);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState<number[]>([]);
    const [month, setMonth]             = useState<number>(currentDate.getMonth()+1);
    const [monthName, setMonthName]     = useState('');
    const [year, setYear]               = useState<number>(currentDate.getFullYear());

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);

    const openModal = (type: string, data: any) => {
        if (type === 'View') {
            const dayData  = menu.find((el) => (el.year + '-' + el.month + '-' + el.day) === (dateFormat(currentDate, 'yyyy-mm') + '-' + (data < 10 ? '0' : '') + data));
            const viewData = dayData ? dayData : {day: (data < 10 ? '0' : '') + data, month: dateFormat(currentDate, 'mm'), year: dateFormat(currentDate, 'yyyy'), menu: []};
            setModalClass('viewMenu');
            setModalTitle('Cardápio do Dia');
            setModalBody(<View data={viewData} openModal={openModal} setModalOpen={setModalOpen} />);
        } else if (type === 'Edit') {
            setModalClass('editCreateMenu');
            setModalTitle('Edição do Cardápio do Dia');
            setModalBody(<Create data={data} setReady={setReady} setModalOpen={setModalOpen}/>);
        } else if (type === "Create") {
            setModalClass('editCreateMenu');
            setModalTitle('Criação do Cardápio do Dia');
            setModalBody(<Create data={data} setReady={setReady} setModalOpen={setModalOpen}/>);
        }

        setModalOpen(true);
    };

    // Generate Week Days
    const generateDaysInMonth = (date: Date): number[] => {
        const daysInMonth       = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();       // Total de dias no mês
        const firstDayIndex     = new Date(date.getFullYear(), date.getMonth(), 1).getDay();            // Dia da semana do primeiro dia do mês
        const lastDayIndex      = new Date(date.getFullYear(), date.getMonth(), daysInMonth).getDay();  // Dia da semana do último dia do mês

        const leadingEmptyDays  = Array(firstDayIndex).fill(0);     // Dias vazios no início
        const trailingEmptyDays = Array(6 - lastDayIndex).fill(0);  // Dias vazios no final

        return [
            ...leadingEmptyDays, // Dias vazios antes do mês começar
            ...Array.from({ length: daysInMonth }, (_, i) => i + 1), // Dias do mês
            ...trailingEmptyDays // Dias vazios após o mês acabar
        ];
    };

    // Button to Next Month
    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    // Button to Previous Month
    const prevMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    // Check Exist Info Day
    function checkInfosDay(day: number) {
        // Check if exist day
        if (day === 0) return ' empty';

        // Check if day has data
        const item = menu.find(el => dateFormat(el.year + '-' + el.month + '-' + el.day + ' 03:00:00', 'yyyy-mm-dd') === (dateFormat(currentDate, 'yyyy-mm-') + (day < 10 ? '0' : '') + day));
        return item ? ' active' : '';
    }

    // Format Array Menu
    function getMenu(dataMenu: any) {
        let dates: any = [];
        let dateDB     = '';
        let item: any  = {};
        dataMenu.map((el: any, idx: number) => {
            const loopDate = dateFormat(el.date, 'yyyy-mm-dd');
            if (idx === 0 || dateDB !== loopDate) {
                if (idx !== 0) dates.push(item);
                dateDB = loopDate;

                // Create new item
                item = {
                    day  : dateFormat(el.date, 'dd'),
                    month: dateFormat(el.date, 'mm'),
                    year : dateFormat(el.date, 'yyyy'),
                    menu : []
                }
            }

            item.menu.push({ id: el.id, type: el.type, name: el.name, description: el.description })
            if ((dataMenu.length - 1) === idx) dates.push(item);
        })

        setMenu(dates)
        setReady(true)
    }

    // Generate Content
    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
             <div className="calendar-container">
                <div className="calendar-header">
                    <button className='button-prev' onClick={prevMonth}>
                        <FaChevronLeft />
                    </button>

                    <h2>{monthName} de {year}</h2>

                    <button className='button-next' onClick={nextMonth}>
                        <FaChevronRight />
                    </button>
                </div>

                <div className="calendar-weekdays">
                    <div className="calendar-day">Dom</div>
                    <div className="calendar-day">Seg</div>
                    <div className="calendar-day">Ter</div>
                    <div className="calendar-day">Qua</div>
                    <div className="calendar-day">Qui</div>
                    <div className="calendar-day">Sex</div>
                    <div className="calendar-day">Sáb</div>
                </div>

                <div className="calendar-grid">
                    {
                        daysInMonth.map((day, idx) => (
                            <div
                                key={'day_' + idx}
                                className={`calendar-date${checkInfosDay(day)}`}
                                onClick={() => day === 0 ? null : openModal('View', day)}>
                                {day !== 0 ? day : ''}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    }

    // Get Current Days
    useEffect(() => {
        const days      = generateDaysInMonth(currentDate);
        const monthName = currentDate.toLocaleString('default', { month: 'long' });
        setDaysInMonth(days);
        setMonth(currentDate.getMonth()+1);
        setMonthName(monthName.charAt(0).toUpperCase() + monthName.slice(1));
        setYear(currentDate.getFullYear());
        setReady(false)
    }, [currentDate]);

    // Get Data
    useEffect(() => {
        !ready && api.get(`/nutrition/schedule/?month=${year}-${month}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            getMenu(resp.data)
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }, [ready]);

    return (
       <>
       { ready ? getContent() : <Loading /> }
       </>
    );
};

export default Calendar;
