import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalDelete: React.FC<any> = (props: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return <>
    <div className="DeleteTip">
        <div className="infos">
            <p>Deseja realmente Deletar essa Veiculo ?</p>
            <p className="alert">Esse processo é irreversível!</p>
    </div>

    <div className="btns">
            <button id="btnDelete" type="button" onClick={()=> {}}>Deletar Veiculo</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Voltar</button>
    </div>
</div>
</>;
};

export default ModalDelete;
