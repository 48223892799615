import React, { useContext } from "react";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalAttendance: React.FC<any> = (props: any) => {
    console.log("🚀 ~ props:", props)
    // Contexts
    const { user } = useContext(GeneralContext);

    return (
        <div className="modal-attendance">
            {props.data.attendance.map((att: any, index: number) => (
                <div key={index}>
                    <p>Data: {att.month}/{att.day}</p>
                    <p>Status: <strong style={{ color: att.state ? "green" : "red" }}>
                        {att.state ? "Presente" : "Ausente"}
                    </strong></p>
                </div>
            ))}
        </div>
    );
};

export default ModalAttendance;
