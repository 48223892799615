import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import SchoolBox from './Box';

const SecretaryEducationSchools: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [schools, setSchools]         = useState<any>(null);
    const [schoolsList, setSchoolsList] = useState<any>([]);
    const [error, setError]             = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ url: "", nameButton: "", className: "add" }]}
                search={{
                    dataList: schoolsList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["id", "name"],    // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setSchools   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={false}
                isViewSearch={true}
            />

            {
                schools && schools.length > 0 ? (
                    <div className="schools">
                        {
                            schools.map((schoolData: any) => (
                                <SchoolBox
                                    key={'schools_' + schoolData.id}
                                    {...schoolData}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma escola</div>
            }
        </>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('school', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setSchools(resp.data)
            setSchoolsList(resp.data)
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Unidades Escolares") && setBreadcrumbs({
            curr: 'Unidades Escolares',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                { name: 'Escolas' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Unidades Escolares" pageTitle="Secretaria da Educação - Unidades Escolares" className="secEducSchools">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default SecretaryEducationSchools;