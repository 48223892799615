import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalView: React.FC<any> = (props: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return <>

        <h2>{props.data.name}</h2>
        <p><strong>telefone:</strong> {props.data.telefone}</p>
        <p><strong>Email:</strong> {props.data.email}</p>
        <p><strong>CNH:</strong> {props.data.cnh}</p>
        <hr />
        <p><strong>Veiculo:</strong> {props.data.veiculo.type}</p>
        <p><strong>Placa:</strong> {props.data.veiculo.placa}</p>
        <p><strong>cor:</strong> {props.data.veiculo.cor}</p>
        <hr />
        <h3>Rotas</h3>
            {props.data.linhas.map((linha: any, index: number) => (
            <div key={index}>
                {linha}
            </div>))}
            

    </>;
};

export default ModalView;
