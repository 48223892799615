import React from "react";
import './styles.css';


const ModalView: React.FC<any> = (props: any) => {

    return (
        <div className="modal-view">
            <h2>Detalhes do Veículo</h2>
            <p><strong>Nome:</strong> {props.data.name}</p>
            <p><strong>Descrição:</strong> {props.data.description}</p>
            <p><strong>Placa:</strong> {props.data.pcence_plate}</p>
            <p><strong>Marca:</strong> {props.data.brand}</p>
            <p><strong>Modelo:</strong> {props.data.model}</p>
            <p><strong>Cor:</strong> {props.data.color}</p>
            <p><strong>Assentos:</strong> {props.data.seats}</p>
        </div>
    )
}

export default ModalView;
