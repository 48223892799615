import React, { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Map from '~/components/Default/Loading/map';
import env from '~/utils/env';

// Images
import PinSchool from './images/pin_school.png';
import PinStudent from './images/pin_student.png';
import dates from '~/utils/dates';

// A tipagem correta para `libraries`
const libraries = ['places'] as Array<'places'>;

const MapComponent: React.FC<any> = ({ ready, schools, students }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: env.googleMapsApiKey,
        libraries,
    });

    async function initMap() {
        const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
            center: { lat: -23.5384309, lng: -46.9039133 },
            zoom: 15,
            mapId: '4504f8b37365c3d0',
        });

        const markers: google.maps.Marker[] = [];

        // Students Marker
        students.forEach((elem: any) => {
            if (!elem.address || !elem.address.latitude || !elem.address.longitude) return;

            // Create custom element for marker
            const studentPin = {
                url: PinStudent,
                scaledSize: new google.maps.Size(20, 28), // Adjust size as needed
            };

            const marker = new google.maps.Marker({
                map,
                position: { lat: parseFloat(elem.address.latitude.replace(',', '.')), lng: parseFloat(elem.address.longitude.replace(',', '.')) },
                title: elem.name,
                icon: studentPin,
            });

            const infoWindow = new google.maps.InfoWindow();

            marker.addListener('click', () => {
                const contentString = `
                    <h4 class="student name">${elem.name}</h4>
                    <div class="content">
                        <p><b>Idade:</b> ${dates.getYearsOld(elem.birth).years > 0 ? dates.getYearsOld(elem.birth).years + ' ano' : dates.getYearsOld(elem.birth).months + ' mês'}</p>
                        <p><b>Email:</b> ${elem.mail || 'Não Informado'}</p>
                        <hr />
                        <p><b>Escola:</b> ${elem.school.name}</p>
                        <p><b>Ensino:</b> ${elem.school_class.teaching.name}</p>
                        <p><b>Série:</b> ${elem.school_class.class_serie} ${elem.school_class.class_acron?.toUpperCase() || ''}</p>
                        <p><b>Período:</b> ${elem.school_class.shift.name}</p>
                    </div>`;

                infoWindow.setContent(contentString);
                infoWindow.open(map, marker);
            });

            markers.push(marker);
        });

        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ map, markers });

        // School Marker
        schools.forEach((elem: any) => {
            const position = { lat: parseFloat(elem.latitude.replace(',', '.')), lng: parseFloat(elem.longitude.replace(',', '.')) };

            // Create custom element for marker
            const schoolPin = {
                url: PinSchool,
                scaledSize: new google.maps.Size(35, 50), // Adjust size as needed
            };

            const marker = new google.maps.Marker({
                map,
                position,
                title: elem.name,
                icon: schoolPin,
                zIndex: 9999999,
            });

            const infoWindow = new google.maps.InfoWindow();

            marker.addListener('click', () => {
                const contentString = `
                    <h4 class="school name">${elem.name.replace(/ESCOLA MUNICIPAL DE EDUCA(Ç|C)(Ã|A)O B(Á|A)SICA/gmi, "EMEB")}</h4>
                    <div class="content">
                        <p><b>Endereço:</b> ${elem.address.trim()}, ${elem.address_number || 'S/N'}</p>
                        <p><b>Bairro:</b> ${elem.address_neighborhood}</p>
                        <p><b>Diretoria:</b> ${elem.board.name}</p>
                        <p><b>Tipo:</b> ${elem.teaching.name}</p>
                    </div>`;

                infoWindow.setContent(contentString);
                infoWindow.open(map, marker);
            });

            markers.push(marker);
        });
    }

    useEffect(() => {
        if (isLoaded && !loadError && ready) initMap();
    }, [isLoaded, loadError, ready]);

    return <div id="map" className='mapContent'>
        <Map />
    </div>;
};

export default MapComponent;
