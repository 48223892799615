import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBoxesPacking } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';

const WarehouseSchool: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    return <Link to="/requisicoes" className={location.pathname === '/requisicoes' ? ' active' : ''}>
        <FaBoxesPacking />
        <span>Requisições</span>
    </Link>;
}

export default WarehouseSchool;
