import { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalForm: React.FC<any> = (props: any) => {
    const [newPosition, setNewPosition] = useState<string>("");
    const [loadData, setLoadData]       = useState<boolean>(false);
    const [error, setError]             = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { value } = event.target;
        setNewPosition(value);
    }

    const saveDataForm = async () => {
        if (newPosition.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = { id: null, measure: newPosition };
        if (props.id) dataFormat.id = props.id;

        // Send data to server
        await api.post('position', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    };

    useEffect(() => {
        if (props.id) setNewPosition(props.name)
    }, [])

    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="form-group no-margin">
                <div className="input-group">
                    <label>Nome</label>
                    <input
                        type="text"
                        placeholder="ex.: Diretor"
                        name="name"
                        value={newPosition}
                        onChange={onChange}
                        required
                    />
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalForm;
