import React, { useContext, useEffect, useState } from 'react';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import { ModalContext } from '~/contexts/modal';
import Create from './Modal/create';
import Edit from './Modal/edit';
import View from './Modal/view';
import Delete from './Modal/delete';
import LinesBox from './Box';
import api from '~/services/api';

const TransportLines: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [lines, setLines]               = useState<any[]>([]);
    const [linesList, setLinesList]               = useState<any[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "create"){
            setModalClass('')
            setModalTitle('Criar Novo Veiculo')
            setModalBody(<Create setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<Edit data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('')
            setModalTitle('Dados do Veiculo')
            setModalBody(<View data={data}setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete"){
            setModalClass('')
            setModalTitle('Deletar Veiculo')
            setModalBody(<Delete data={data.id} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Veiculo", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: linesList,
                    filterKey: [],
                    setFilteredList:setLinesList 
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                lines && lines.length > 0 ? (
                    <div className='linesEd'>
                        {
                            lines.map((linesData: any) => (
                                <LinesBox
                                    key={`Lines_box_${linesData.id}`}
                                    {...linesData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum veiculo</div>
            }
        </>
    }

    useEffect(() => {
/*         !ready && api.get('', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setLines(resp.data);
            setLinesList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados das requisições')
            setReady(true)
        }); */


    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Linhas") && setBreadcrumbs({
            curr: 'Linhas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Linhas' }
            ]
        });
        setReady(true)
        setLines([
            {
                id: 1,
                vehicle: {
                    name: "Caminhão Volvo",
                    description: "Caminhão para transporte de carga pesada",
                    licence_plate: "ABC-1234",
                    brand: "Volvo",
                    model: "FH 16",
                    color: "Azul",
                    seats: 2,
                },
                driver: {
                    nome: "João Pereira",
                    telefone: "(31) 97777-7777",
                    email: "joao.pereira@email.com",
                    cnh: "12345678900"
                },
                monitor: {
                    name: "Carlos Silva",
                    telefone: "(11) 99999-9999",
                    email: "carlos.silva@email.com"
                },
                name: "Linha A",
                description: "Transporte de carga pesada",
                alunos: [
                    { name: "Luis" },
                    { name: "Mariana" },
                    { name: "Pedro" },
                    { name: "Ana" }
                ]
            },
            {
                id: 2,
                vehicle: {
                    name: "Van Mercedes",
                    description: "Van para transporte de passageiros",
                    licence_plate: "XYZ-5678",
                    brand: "Mercedes-Benz",
                    model: "Sprinter",
                    color: "Branco",
                    seats: 12,
                },
                driver: {
                    nome: "Maria Fernandes",
                    telefone: "(41) 96666-6666",
                    email: "maria.fernandes@email.com",
                    cnh: "98765432100"
                },
                monitor: {
                    name: "Ana Souza",
                    telefone: "(21) 98888-8888",
                    email: "ana.souza@email.com"
                },
                name: "Linha B",
                description: "Transporte de passageiros",
                alunos: [
                    { name: "Luis" },
                    { name: "João" },
                    { name: "Clara" },
                    { name: "Felipe" }
                ]
            }
        ]);
        
        
    }, [breadcrumbs]);

    return <Template page="Linhas" pageTitle="Transporte - Linhas" className="secEducLines">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportLines;