import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBowlFood, FaLinesLeaning, FaUtensils } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const Nutrition: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'nutricao', 'cardapios') && (
                        <Link to="/nutricao/cardapios" className={location.pathname === '/nutricao/cardapios' ? ' active' : ''}>
                            <FaBowlFood />
                            <span>Cardápios</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'nutricao', 'dicas') && (
                        <Link to="/nutricao/dicas" className={location.pathname === '/nutricao/dicas' ? ' active' : ''}>
                            <FaLinesLeaning />
                            <span>Dicas</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/nutricao" className={location.pathname.indexOf('/nutricao') === 0 ? ' active' : ''}>
                <FaUtensils />
                <span>Nutrição</span>
            </Link>

            {location.pathname.indexOf('/nutricao') === 0 && submenu()}
        </>
    );
}

export default Nutrition;
