import React, { ReactNode, useEffect } from 'react';
import { createContext } from 'react';
import { Button } from '~/components/Forms';
import { FaTimes } from 'react-icons/fa';
import './styles.css';

interface ModalContextData {
    modalOpen: boolean;
    setModalOpen: Function;
    setModalClass : Function;
    setModalStyle : Function;
    setModalTitle: Function;
    setModalButton: Function;
    setModalBody: Function;
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData)

export function ModalProvider ({children}: ModalProviderProps) {
    const [modalOpen, setModalOpen]     = React.useState(false);
    const [modalClass, setModalClass]   = React.useState('' || null);
    const [modalStyle, setModalStyle]   = React.useState({});
    const [modalTitle, setModalTitle]   = React.useState('');
    const [modalBody, setModalBody]     = React.useState<any>(<></>);
    const [modalButton, setModalButton] = React.useState(true);

    useEffect(() => {
        // console.log(modalOpen)
        if (modalOpen===false) {
            setModalClass(null)
            setModalStyle({})
            setModalTitle('')
            setModalBody(<></>)
            setModalButton(true)
        }
    }, [modalOpen])

    return (
        <ModalContext.Provider value={{modalOpen, setModalOpen, setModalClass, setModalStyle, setModalTitle, setModalBody, setModalButton }}>
            {children}

            {
                modalOpen && <div id="modalBox" className={`${modalClass ? modalClass : ''}`}>
                    <div className="modalBody" style={modalStyle}>
                        <header>
                            {modalTitle ? <h3>{modalTitle}</h3> : <span></span>}
                            {
                                modalButton ? <Button
                                    type="button"
                                    className="close"
                                    func={() => setModalOpen(false)}
                                    icon={<FaTimes size={20} />}
                                /> : <></>
                            }
                        </header>

                        <div className="modalContent">
                            {modalBody}
                        </div>
                    </div>
                </div>
            }
        </ModalContext.Provider>
    );
}