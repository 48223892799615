import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaSignOutAlt } from 'react-icons/fa';
import { hasPermission } from '~/utils/permissions';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import SecretaryEducation from './secretaryEducation';
import SchoolSecretary from './schoolSecretary';
import Teacher from './teacher';
import Parents from './parents';
import SupervisionDirection from './direction';
import Transport from './transport';
import Nutrition from './nutrition';
import Warehouse from './warehouse';
import WarehouseSchool from './warehouseSchool';

// Images
import logo from '~/assets/images/4educ_logo3.png';

// Get Version
const version = require('~/../package.json').version;

const Menu: React.FC = () => {
    const location = useLocation();
    const { user } = useContext(GeneralContext);

    return (
        <aside>
            <div className="logo">
                <Link to='/'>
                    <img src={logo} alt="Energizou"/>
                </Link>
            </div>

            <nav>
                {hasPermission(user.permissions, user.role, 'dashboard') && (
                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                        <FaHome />
                        <span>Dashboard</span>
                    </Link>
                )}

                {hasPermission(user.permissions, user.role, 'secretaria-educacao') && (
                    <SecretaryEducation />
                )}

                {hasPermission(user.permissions, user.role, 'secretaria-escolar') && (
                    <SchoolSecretary />
                )}

                {hasPermission(user.permissions, user.role, 'professor') && (
                    <Teacher />
                )}

                {hasPermission(user.permissions, user.role, 'pais-e-alunos') && (
                    <Parents />
                )}

                {hasPermission(user.permissions, user.role, 'supervisao-e-direcao') && (
                    <SupervisionDirection />
                )}

                {hasPermission(user.permissions, user.role, 'transporte') && (
                    <Transport />
                )}

                {hasPermission(user.permissions, user.role, 'nutricao') && (
                    <Nutrition />
                )}

                {hasPermission(user.permissions, user.role, 'almoxarifado') && (
                    <Warehouse />
                )}

                {hasPermission(user.permissions, user.role, 'secretaria-escolar') && (
                    <WarehouseSchool />
                )}
            </nav>

            <footer>
                <div>
                    <Link to="/logout" className='logout'>
                        <FaSignOutAlt />
                    </Link>
                </div>

                <span>Version {version}</span>
            </footer>
        </aside>
    );
}

export default Menu;
