import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import api from '~/services/api';
import './styles.css';

// Get Menu Items
import MenuItems from './menus';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';

// Interface
interface RulesInt {
    page: string;
    subpage: string;
    canRead: boolean;
    canWrite: boolean;
}

const CreateRolePermissions: React.FC = () => {
    let   params   = useParams();
    let   id       = params.id;
    const navigate = useNavigate();

    // States
    const [roleName, setRoleName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState<RulesInt[]>([]);
    const [expandedMenus, setExpandedMenus] = useState<{ [key: string]: boolean }>({});
    const [error, setError] = useState<string | null>(null);
    const [edit, setEdit] = useState<boolean>(false);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);


    const handlePermissionChange = (page: string, subpage: string, type: 'read' | 'write', checked: boolean) => {
        setSelectedPermissions((prev: any) => {
            const existing:any = prev.find((p: any) => p.page === page && p.subpage === subpage);
            if (existing) {
                return prev.map((p: any) => p.page === page && p.subpage === subpage
                    ? { ...p, [type === 'read' ? 'canRead' : 'canWrite']: checked }
                    : p
                );
            } else {
                return [...prev, { page, subpage, canRead: type === 'read' ? checked : existing?.canRead || false, canWrite: type === 'write' ? checked : existing?.canWrite || false }];
            }
        });
    };

    const handleSelectAllSubpages = (page: string, type: 'read' | 'write', checked: boolean) => {
        setSelectedPermissions((prev: any) => {
            const updatedPermissions = MenuItems
                .find(menu => menu.name === page)?.subpages.map(subpage => {
                    const existing = prev.find((p: any) => p.page === page && p.subpage === subpage);
                    return {
                        page,
                        subpage,
                        canRead: type === 'read' ? checked : existing?.canRead || false,
                        canWrite: type === 'write' ? checked : existing?.canWrite || false,
                    };
                }) || [];

            const filteredPrev = prev.filter((p: any) => p.page !== page || (p.canRead && p.canWrite));
            return [...filteredPrev, ...updatedPermissions];
        });
    };

    const handleToggleMenu = (menu: string) => {
        setExpandedMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
    };

    const handleSelectAllReadPermissions = (page: string, checked: boolean) => {
        handleSelectAllSubpages(page, 'read', checked);
    };

    const handleSelectAllWritePermissions = (page: string, checked: boolean) => {
        handleSelectAllSubpages(page, 'write', checked);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newRole = {
            id,
            name: roleName,
            permissions: selectedPermissions,
        };

        api.post('permissions', newRole, {
            headers: { Authorization: user.token }
        }).then(resp => {
            alert('Cadastro realizado com sucesso')
            console.log(resp)
            navigate('/secretaria-educacao/permissoes')
        }).catch(errr => {
            console.error('Erro ao conectar com o servidor:', errr);
            alert('Erro ao conectar com o servidor.');
        });
    };

    useEffect(() => {
        if (id) {
            setEdit(true);

            // Get permissions data
            api.get(`permissions/${id}`, {
                headers: { Authorization: user.token }
            }).then(resp => {
                console.log(resp.data)
                setRoleName(resp.data.name)

                let rules: any = [];
                resp.data.rules.map((el: any) => {
                    let item = {
                        page: el.page,
                        subpage: el.subpage,
                        canRead: el.ready,
                        canWrite: el.write
                    }

                    rules.push(item)
                })

                setSelectedPermissions(rules);
            }).catch(err => {
                setError('Erro ao carregar os dados das permissões')
            });
        }
    },[])

    // Breadcrumbs
     useEffect(() => {
         (!breadcrumbs || breadcrumbs.curr!=="Criar Nova Função e Definir Permissões") && setBreadcrumbs({
             curr: 'Criar Nova Função e Definir Permissões',
             links: [
                 { name: 'Home', url: '/'},
                 { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                 { name: 'Permissões', url: '/secretaria-educacao/permissoes'},
                 { name: 'Adicionar Permissões' }
             ]
         });
     }, [breadcrumbs]);

    return <Template page="Criar Função e Permissões" pageTitle="Secretaria da Educação - Criar Permissões" className="secEducCreatePermissions">
        <div className="headerBox">
            <div className="buttons">
                <Link to="/secretaria-educacao/permissoes" className="btn back" title="Voltar para lista de permissões">
                    <FaArrowLeft size={12} /><span> Voltar</span>
                </Link>
            </div>
        </div>

        <form onSubmit={handleSubmit}>
            <div className="header">
                <div className="ruleBox">
                    <label>Nome da Função:</label>
                    <input type="text" className='ruleName' value={roleName} onChange={(e) => setRoleName(e.target.value)} required />
                </div>

                <button className='saveBtn' type="submit">Salvar Função</button>
            </div>

            <hr />

            <div className='permissions'>
                <h3>Selecione as Permissões</h3>

                {
                    MenuItems.map((menu: any) => (
                        <div key={menu.id} className='cpItem'>
                            <div className='title' onClick={() => handleToggleMenu(menu.name)}>
                                {menu.name}
                                <span>{expandedMenus[menu.name] ? '-' : '+'}</span>
                            </div>

                            <div className={`cpContent${expandedMenus[menu.name] ? '' : ' hidden'}`}>
                                {
                                    expandedMenus[menu.name] && <>
                                        <div className='lines all'>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleSelectAllReadPermissions(menu.name, e.target.checked)}
                                                /> Selecionar todos para leitura
                                            </label>

                                            <label>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleSelectAllWritePermissions(menu.name, e.target.checked)}
                                                /> Selecionar todos para edição
                                            </label>
                                        </div>

                                        {
                                            menu.subpages.map((subpage: any) => (
                                                <div key={subpage} className='lines sub'>
                                                    <div className='page'>{subpage}</div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => handlePermissionChange(menu.name, subpage, 'read', e.target.checked)}
                                                            checked={selectedPermissions.some(p => p.page === menu.name && p.subpage === subpage && p.canRead)}
                                                        /> Leitura
                                                    </label>

                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => handlePermissionChange(menu.name, subpage, 'write', e.target.checked)}
                                                            checked={selectedPermissions.some(p => p.page === menu.name && p.subpage === subpage && p.canWrite)}
                                                        /> Edição
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </form>
    </Template>;
};

export default CreateRolePermissions;
