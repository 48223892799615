import React from 'react';
import { Selectbox } from '~/components/Forms';
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

const BasicInfoTab: React.FC<any> = ({data, errors, states, onChange}: any) => {
    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-1">
                    <InputLabel
                        name="name"
                        type='text'
                        labelName='Nome Completo'
                        value={data?.name}
                        onChange={onChange}
                        error={errors && errors['name']}
                    />
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="gender">Gênero</label>
                    <select onChange={onChange} id="gender" name="gender" value={data?.gender} defaultValue={data?.gender} required>
                        <option value="male">Masculino</option>
                        <option value="female">Feminino</option>
                        <option value="other">Outro</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="marital_status">Estado Civil</label>
                    <select onChange={onChange} id="marital_status" name="marital_status" value={data?.marital_status} defaultValue={data?.marital_status} required>
                        <option value="single">Solteiro(a)</option>
                        <option value="married">Casado(a)</option>
                        <option value="divorced">Divorciado(a)</option>
                        <option value="widowed">Viúvo(a)</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="nationality"
                        type='text'
                        onChange={onChange}
                        labelName='Nacionalidade'
                        value={data?.nationality}
                        error={errors && errors['nationality']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="mail"
                        type='mail'
                        labelName='E-mail'
                        value={data?.mail}
                        onChange={onChange}
                        error={errors && errors['mail']}
                    />
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="phone"
                        type='text'
                        labelName='Telefone'
                        onChange={onChange}
                        value={data?.phone}
                        error={errors && errors['phone']}
                    />
                </div>

                <div className="formItem w1-3">
                    <InputLabelMask
                        name='cpf'
                        value={data?.cpf}
                        type='text'
                        mask="999.999.999-99"
                        placeholder='123.456.789-10'
                        labelName='CPF'
                        onChange={onChange}
                        error={errors && errors['cpf']}
                    />
                </div>

                <div className="formItem w1-3">
                    <InputLabelMask
                        name="rg"
                        type='text'
                        mask='99.999.999-9'
                        labelName='RG'
                        onChange={onChange}
                        value={data?.rg}
                        error={errors && errors['rg']}
                    />
                </div>

                <div className="formItem w1-5">
                    <InputLabel
                        name="issuing_organization"
                        type='text'
                        labelName='Orgão emissor'
                        value={data?.issuing_organization}
                        onChange={onChange}
                        error={errors && errors['issuing_organization']}
                    />
                </div>
            </div>
            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="birth_date"
                        type='date'
                        labelName='Data de nascimento'
                        onChange={onChange}
                        value={data?.birth_date}
                        error={errors && errors['birth_date']}
                    />
                </div>
            </div>
        </>
    );
};

export default BasicInfoTab;
