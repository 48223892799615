import CryptoJS from 'crypto-js';
import config from '~/utils/env';

const cryptos = {
    generateMD5: function (text: string) {
        return CryptoJS.MD5(text).toString(CryptoJS.enc.Hex);
    },

    encryptWeb: function (text: string | object, obj?: boolean) {
        const textEnc = (!obj ? JSON.stringify(text) : text);
        const encrypt = this._encrypt(textEnc, config.crypto.web);
        return encrypt;
    },

    decryptWeb: function (text: string | null, obj?: boolean) {
        const decrypt = this._decrypt(text, config.crypto.web);
        if (text==='' || (decrypt.charAt(0)!=='{' && decrypt.charAt(0)!=='[')) return '';
        return (!obj ? JSON.parse(decrypt) : decrypt);
    },

    encryptServer: function (text: string | object, obj?: boolean) {
        const textEnc = (!obj ? JSON.stringify(text) : text);
        const encrypt = this._encrypt(textEnc, config.crypto.server);
        return encrypt;
    },

    decryptServer: function (text: string | null, obj?: boolean) {
        const decrypt = this._decrypt(text, config.crypto.server);
        if (text==='') return '';
        return (!obj ? JSON.parse(decrypt) : decrypt);
    },

    _encrypt: function (text: any, password: string) {
        const iv      = CryptoJS.SHA256((Math.random()*40).toString()).toString(CryptoJS.enc.Hex).substring(0, 16);
        const keyPass = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex).substring(0, 32);
        const crypted = CryptoJS.AES.encrypt(text, keyPass);
        return iv + ':' + crypted;
    },

    _decrypt: function (text: any, password: string) {
        const parts = text.split(':');
        const keyPass = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex).substring(0, 32);
        return CryptoJS.AES.decrypt(parts[1], keyPass).toString(CryptoJS.enc.Utf8);
    }
}

export default cryptos;