import React, { useContext, useEffect } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';

const SchoolSecretaryTransfers: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Breadcrumbs
    useEffect(() => {
        !breadcrumbs && setBreadcrumbs({
            curr: 'Transferências',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Transferências' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Transferências" pageTitle="Secretaria Escolar - Transferências" className="secEscTransfers">
        Secretaria Escolar - Transferências<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
    </Template>;
}

export default SchoolSecretaryTransfers;