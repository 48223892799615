import React, { useContext, useEffect, useState } from 'react';
import './styles.css';
// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import TransportAttendanceBox from './Box';
import Loading from '~/components/Default/Loading';
import { ModalContext } from '~/contexts/modal';
import View from './Modal/view';
import Attendance from './Modal/attendance';

const TransportAttendance: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [attendance, setAttendance]         = useState<any>(null);
    const [attendanceList, setAttendanceList] = useState<any>([]);
    const [error, setError]               = useState<string | null>(null);
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const {setModalOpen,setModalClass, setModalTitle, setModalBody} = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        console.log("🚀 ~ openModal ~ type:", type)
        
        if (type === "attendance"){
            setModalClass('whAttendance')
            setModalTitle('Lista de presença')
            setModalBody(<Attendance data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('whView')
            setModalTitle('Rota')
            setModalBody(<View data={data} openModal={openModal} setModalOpen={setModalOpen} setReady={setReady} />)
        }
/*         else if (type === "edit"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<Edit data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } */
       /*  else if (type === "remove"){
            setModalClass('whView')
            setModalTitle('Editar Veiculo')
            setModalBody(<Remove data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } */
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "Adicionar presença a alunos", onClick: () => {openModal("add", attendance)}, className: "add" }
                ]}
                search={{
                    dataList: attendanceList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setAttendance   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                attendance && attendance.length > 0 ? (
                    <div className='attendance'>
                        {
                            attendance.map((studentData: any) => (
                                <TransportAttendanceBox
                                    key={'student_' + studentData.id}
                                    {...studentData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
            }
        </>
    }
    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Presenças") && setBreadcrumbs({
            curr: 'Presenças',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Presenças' }
            ]
        });
        setReady(true)
       setAttendance([
    {
        id: 1, 
        name: "Linha A",
        description: "Transporte de carga pesada",
        alunos: [
            { 
                name: "Luis",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: true },
                    { month: "02", day: "03", state: false }
                ] 
            },
            { 
                name: "Mariana",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: false },
                    { month: "02", day: "03", state: true }
                ]
            },
            { 
                name: "Pedro",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: true },
                    { month: "02", day: "03", state: true }
                ]
            },
            { 
                name: "Ana",
                attendance: [
                    { month: "02", day: "01", state: false },
                    { month: "02", day: "02", state: false },
                    { month: "02", day: "03", state: true }
                ]
            }
        ]
    },
    {
        id: 2,
        name: "Linha B",
        description: "Transporte de passageiros",
        alunos: [
            { 
                name: "Luis",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: false },
                    { month: "02", day: "03", state: true }
                ]
            },
            { 
                name: "João",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: true },
                    { month: "02", day: "03", state: false }
                ]
            },
            { 
                name: "Clara",
                attendance: [
                    { month: "02", day: "01", state: true },
                    { month: "02", day: "02", state: true },
                    { month: "02", day: "03", state: true }
                ]
            },
            { 
                name: "Felipe",
                attendance: [
                    { month: "02", day: "01", state: false },
                    { month: "02", day: "02", state: false },
                    { month: "02", day: "03", state: true }
                ]
            }
        ]
    }
]);

    }, [breadcrumbs]);

    return <Template page="Presenças" pageTitle="Transporte - Presenças" className="secEducAttendance">
       { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportAttendance;