import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import BasicInfoTab from './BasicInfoTab';
import AddressTab from './AddressTab';
import WorkInfoTab from './WorkInfoTab';
import apiAddress from '~/services/cep';

let initData = {
    name                : "",
    gender              : "male",
    marital_status      : "single",
    nationality         : "Brasileiro",
    mail                : "",
    phone               : "",
    cpf                 : "",
    rg                  : "",
    issuing_organization: "SSP-SP",
    birth_date          : "",

    // address
    cep         : "",
    address     : "",
    number      : "",
    complement  : "",
    city        : "",
    neighborhood: "",
    uf          : "",

    public_area : "URBAN",
    latitude    : "-23.550520",
    longitude   : "-46.633308",

    // workInfo
    position_id      : "",
    rules_category_id: "",
    department       : "",
    work_location    : "",
    admission_date   : "",
    contract_end_date: "",
    contract_type    : "PJ",
    work_start_time  : "",
    work_end_time    : "",
    contract_number  : "",
    work_hours       : "Full-time",

    is_temporary     : false,
    login_flag       : false,
}

const EmployeeCreate: React.FC = () => {
    const {user}              = useContext(GeneralContext);
    const [ready, setReady]   = useState(false);
    const [states, setStates] = useState<any>([]);
    const [positions, setPositions] = useState<any>([]);
    const [permissions, setPermissions] = useState<any>([]);
    const [schoolAndOtherUnits, setSchoolAndOtherUnits] = useState<any>([]);
    const [data, setData]     = useState<any>(initData);
    const navigate            = useNavigate();

    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    async function sendForm() {
        await api.post('employees', data, {headers: { Authorization: user.token }})
        .then(resp => {
            alert('Cadastro realizado com sucesso')
            console.log(resp)
            navigate('/secretaria-educacao/funcionarios')
        }).catch(err => {
            console.log("🚀 ~ sendForm ~ err:", err)
        })
    }

    function onChange(event: ChangeEvent<HTMLInputElement|HTMLButtonElement>) {
        let {name, value} = event.target;
        change(name, value);
    }

    function change(name: string, value: string,) {
        // Set Data
        setData({...data, [name]:value});
    }

    function onChangeCheckbox(event: any) {
        let {name} = event.target;

        let isChecked = event.target.checked;
        setData({...data, [name]:isChecked ? true : false})
    }

    async function onChangeCep(event: any) {
        let {name, value} = event.target;
        let formattedValue = (value).replaceAll('_','').replace('-','') //Deixando apenas os números
        if (formattedValue.length === 8){

            let addressComplete = await apiAddress.getViaCep(formattedValue) // Acessa a API do viaCep
            if(addressComplete.erro) alert("A busca do cep falhou, preencha manualmente!") //Em caso de cep inválido
            fillAddress(addressComplete) //Preenchimento do cep
        } else{
            change(name, value);
        }

    }

    function onAddressChange(addressData: {
        rua: string;
        bairro: string;
        cidade: string;
        uf: string;
        cep: string;
        pais: string;
    }) {
        setData({
            ...data,
            address: addressData.rua,
            neighborhood: addressData.bairro,
            city: addressData.cidade,
            uf: addressData.uf,
            cep: addressData.cep
        });
    }

    function fillAddress(addressComplete: any) {
        setData({
            ...data,
            cep         : addressComplete.cep,
            address     : addressComplete.logradouro,
            city        : addressComplete.localidade,
            neighborhood: addressComplete.bairro,
            uf          : addressComplete.uf,
        })
    }

    async function loadInfos() {
        try {
            const [statesResponse, positionsResponse, permissionsResponse, allUnits] = await Promise.all([
                api.get('infos/states', { headers: { Authorization: user.token }}),
                api.get('position', { headers: { Authorization: user.token }}),
                api.get('permissions', { headers: { Authorization: user.token }}),
                api.get('school/all/list', { headers: { Authorization: user.token }})
            ]);

            setStates(statesResponse.data);
            setPositions(positionsResponse.data);
            setPermissions(permissionsResponse.data);
            setSchoolAndOtherUnits(allUnits.data);

            // Quando ambos os estados forem setados, setReady(true) é chamado.
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
            // Trate o erro apropriadamente, talvez com um setReady(false) ou exibição de mensagem de erro.
        }
    }

    // Get states data
    useEffect(() => {
        if(!ready){
            loadInfos()
        }
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Novo Funcionário") && setBreadcrumbs({
            curr: 'Novo Funcionário',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Secretaria da Educação', url: '/secretaria-educacao' },
                { name: 'Funcionários', url: '/secretaria-educacao/funcionarios' },
                { name: 'Novo Funcionário' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Novo Funcionário" pageTitle="Cadastro de Novo Funcionário" className="secEducEmployeesForm">
            {
                ready ? <>
                    <div className="headerBox">
                        <div className="buttons">
                            <Link to="/secretaria-educacao/funcionarios" className="btn back" title="Voltar para lista de funcionários">
                                <FaArrowLeft size={12} /><span> Voltar</span>
                            </Link>
                        </div>
                    </div>

                    <div className='contentForm'>
                        <BasicInfoTab states={states} onChange={onChange} data={data}/>
                        <hr />
                        <AddressTab states={states} onChange={onChange} onChangeCep={onChangeCep} onAddressChange={onAddressChange} data={data}/>
                        <hr />
                        <WorkInfoTab onChange={onChange} data={data} onChangeCheckbox={onChangeCheckbox} positions={positions} permissions={permissions} units={schoolAndOtherUnits}/>
                        <hr />
                        <button className='save' onClick={() => sendForm()}>Salvar</button>
                    </div>
                </> : <Loading />
            }
        </Template>
    );
}

export default EmployeeCreate;
