import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus, FaTimes } from 'react-icons/fa';
import './styles.css'

interface IButtonHeader {
    url?: string;
    nameButton: string;
    onClick?: () => void;
    className?: string;
    icons?: any;
}

interface ISearchFilterProps {
    dataList: any[];
    filterKey: string[];
    setFilteredList: (filteredData: any[]) => void;
}

interface IComponentHeader {
    buttons: IButtonHeader[];
    module?: ReactElement;
    search: ISearchFilterProps;
    placeholder?: any;
    isViewButton: Boolean;
    isViewSearch: Boolean;
}

const HeaderSearchAndForm: React.FC<IComponentHeader> = (props: IComponentHeader) => {
    const [search, setSearch] = useState('');

    const getNestedProperty = (obj: any, path: string): string => {
        // Verifica se 'path' é válido e se contém um ponto
        if (path && path.includes('.')) {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj) || ''; // Retorna string vazia se não encontrar
        } else {
            return obj && obj[path] ? String(obj[path]) : ''; // Verifica se a propriedade simples existe
        }
    };

    const normalizeString = (str: string) => str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    function onChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
        const searchValue = event.target.value;
        const filteredData = props.search.dataList.filter((item) => {
            return normalizeString(String(getNestedProperty(item, props.search.filterKey[0])))
                .includes(normalizeString(searchValue)) || normalizeString(String(getNestedProperty(item, props.search.filterKey[1])))
                .includes(normalizeString(searchValue)) || normalizeString(String(getNestedProperty(item, props.search.filterKey[2])))
                .includes(normalizeString(searchValue))
        });

        setSearch(searchValue);
        props.search.setFilteredList(filteredData);
    }

    return (
        <div className="headerBoxSearchAndForm">
            {
                props.isViewButton ? <div className="buttons">
                    {
                        props.buttons.map((button, index) => (
                            button.url ? <Link to={button.url} key={`${button.nameButton}.${index}`}>
                                <button
                                    type="button"
                                    className={button.className}
                                    title={button.nameButton}
                                >
                                    <FaPlus size={14} />
                                    <span>{button.nameButton}</span>
                                </button>
                            </Link> : <button
                                key={`${button.nameButton}.${index}`}
                                type="button"
                                className={button.className}
                                title={button.nameButton}
                                onClick={button?.onClick}
                            >

                                {button.icons ? button.icons : <></>}
                                <span>{button.nameButton}</span>
                            </button>
                        ))
                    }
                </div> : <></>
            }

            {
                props.module ? props.module : <></>
            }

            {
                props.isViewSearch ?
                    <div className="searchBox">
                        <input
                            type="text"
                            name="search"
                            id="search"
                            placeholder={props.placeholder ? props.placeholder : "Filtrar produto..."}
                            value={search}
                            onChange={onChangeSearch}
                        />
                        {search !== '' && (
                            <button onClick={() => { setSearch(''); props.search.setFilteredList(props.search.dataList); }}>
                                <FaTimes />
                            </button>
                        )}
                    </div> : <></>
            }
        </div>
    );
};

export default HeaderSearchAndForm;
