import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import './styles.css';
import api from "~/services/api";

// Contexts
import { GeneralContext } from "~/contexts";

const dataBase = {
    id: 0,
    name: '',
    description: ''
}

const ModalCreate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    const [breakfast, setBreakfast] = useState(dataBase);
    const [lunch, setLunch]         = useState(dataBase);
    const [snack, setSnack]         = useState(dataBase);
    const [dinner, setDinner]       = useState(dataBase);
    const [supper, setSupper]       = useState(dataBase);
    const [loadData, setLoadData]   = useState<boolean>(false);
    const [error, setError]         = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { name, value } = event.target;
        const nameArr = name.split('.');

        switch (nameArr[0]) {
            case 'breakfast':
                let bfData = JSON.parse(JSON.stringify(breakfast));
                bfData[nameArr[1]] = value;
                setBreakfast(bfData);
                return false;

            case 'lunch':
                let lunchData = JSON.parse(JSON.stringify(lunch));
                lunchData[nameArr[1]] = value;
                setLunch(lunchData);
                return false;

            case 'snack':
                let snackData = JSON.parse(JSON.stringify(snack));
                snackData[nameArr[1]] = value;
                setSnack(snackData);
                return false;

            case 'dinner':
                let dinnerData = JSON.parse(JSON.stringify(dinner));
                dinnerData[nameArr[1]] = value;
                setDinner(dinnerData);
                return false;

            case 'supper':
                let supperData = JSON.parse(JSON.stringify(supper));
                supperData[nameArr[1]] = value;
                setSupper(supperData);
                return false;
        }
    }

    const saveDataForm = async () => {
        if (
            breakfast.name.trim() === "" || breakfast.description.trim() === "" ||
            lunch.name.trim() === "" || lunch.description.trim() === "" ||
            snack.name.trim() === "" || snack.description.trim() === "" ||
            dinner.name.trim() === "" || dinner.description.trim() === "" ||
            supper.name.trim() === "" || supper.description.trim() === ""
        ) {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = {
            day: data.day,
            month: data.month,
            year: data.year,
            breakfast,
            lunch,
            snack,
            dinner,
            supper
        };

        // Send data to server
        await api.post('/nutrition/schedule', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    };

    useEffect(() => {
        if (data.menu.length>0) {
            let breakfastData = data.menu.find((el: any) => el.type === 'Breakfast');
            setBreakfast(breakfastData)

            let lunchData = data.menu.find((el: any) => el.type === 'Lunch');
            setLunch(lunchData)

            let snackData = data.menu.find((el: any) => el.type === 'Snack');
            setSnack(snackData)

            let dinnerData = data.menu.find((el: any) => el.type === 'Dinner');
            setDinner(dinnerData)

            let supperData = data.menu.find((el: any) => el.type === 'Supper');
            setSupper(supperData)
        }
    }, [])


    return !loadData ? (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editCreateMenu">
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="form-group no-margin">
                <h3>Café da Manhã</h3>

                <div className="input-group">
                    <label htmlFor="name">Título</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="ex.: Pão e Leite"
                        name="breakfast.name"
                        value={breakfast.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="description">Descrição</label>
                    <textarea
                        id="description"
                        rows={3}
                        placeholder="ex.: Pão com manteiga com leite e achocolatado"
                        name="breakfast.description"
                        value={breakfast.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <hr />

            <div className="form-group no-margin">
                <h3>Almoço</h3>

                <div className="input-group">
                    <label htmlFor="name">Título</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="ex.: Pão e Leite"
                        name="lunch.name"
                        value={lunch.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="description">Descrição</label>
                    <textarea
                        id="description"
                        rows={3}
                        placeholder="ex.: Pão com manteiga com leite e achocolatado"
                        name="lunch.description"
                        value={lunch.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <hr />

            <div className="form-group no-margin">
                <h3>Lanche</h3>

                <div className="input-group">
                    <label htmlFor="name">Título</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="ex.: Pão e Leite"
                        name="snack.name"
                        value={snack.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="description">Descrição</label>
                    <textarea
                        id="description"
                        rows={3}
                        placeholder="ex.: Pão com manteiga com leite e achocolatado"
                        name="snack.description"
                        value={snack.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <hr />

            <div className="form-group no-margin">
                <h3>Janta</h3>

                <div className="input-group">
                    <label htmlFor="name">Título</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="ex.: Pão e Leite"
                        name="dinner.name"
                        value={dinner.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="description">Descrição</label>
                    <textarea
                        id="description"
                        rows={3}
                        placeholder="ex.: Pão com manteiga com leite e achocolatado"
                        name="dinner.description"
                        value={dinner.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <hr />

            <div className="form-group no-margin">
                <h3>Ceia</h3>

                <div className="input-group">
                    <label htmlFor="name">Título</label>
                    <input
                        id="name"
                        type="text"
                        placeholder="ex.: Pão e Leite"
                        name="supper.name"
                        value={supper.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="description">Descrição</label>
                    <textarea
                        id="description"
                        rows={3}
                        placeholder="ex.: Pão com manteiga com leite e achocolatado"
                        name="supper.description"
                        value={supper.description}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>

            <div className="btns">
                <button id="btnSave" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </form>
    ) : (
        <>Salvando dados...</>
    );
};

export default ModalCreate;
