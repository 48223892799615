import { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalCancel: React.FC<any> = (props: any) => {

    // Contexts
    const { user } = useContext(GeneralContext);

    const inactiveRequest = async () => {
        await api.delete(`warehouse_requests/${props.id}`, {
            headers: { Authorization: user.token }
        }).then(data => {
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    };

    return <>
        <div className="infos">
            <p>Deseja realmente Cancelar essa Solicitação ?</p>
            <p>A solicitação possui <b>{JSON.parse(props.resource_data).length}</b> itens cadastrados</p>
            <p className="alert">Esse processo é irreversível!</p>
        </div>

        <div className="btns">
            <button id="btnDelete" type="button" onClick={inactiveRequest}>Cancelar Solicitação</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Voltar</button>
        </div>
    </>;
};

export default ModalCancel;

