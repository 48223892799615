import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import '../auth.css';
import './styles.css';

// Components
import {InputLabel} from '~/components/Forms';

// Images
// import logo from '~/assets/images/logo.svg';
import logo from '~/assets/images/4educ_logo3.png';

// Constants
const initialState = {mail: ''};

const Forgot: React.FC = () => {
	let params = useParams();
	let text   = params.text;

    const navigate              = useNavigate();
    const {logged}              = useContext(GeneralContext);
    const [values, setValues]   = useState(initialState);
    const [message, setMessage] = useState<string>('');
    const [errors, setErrors]   = useState<String[]>([]);

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setValues({...values, [name]: value})
    }

	async function onSubmit (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (values.mail==='') {
			var errArr = [];

			if (values.mail==='') errArr.push('O campo de email está vazio');

			setErrors(errArr);
			return;
		}

		await api.post('forgot-password', values).then(async (resp: any) => {
			setErrors([])
			setValues(initialState)
			setMessage(resp.data.message)
		}).catch((err: any) => {
			if(err.response?.status){

					setMessage('')
					const { status } = err.response
					if (status===400) {
						const error = err.response.data.errors
						setErrors(error)
					}
			}else{
				console.log(err)
			}
		});
	}

	function treatErrors() {
        if (message!=='') {
            navigate('/lembrar-senha', {replace: true})
            setMessage('');
        }

        return (
            <div className="boxErrors">
				{ errors.map(el => <span key={`error_${el}`}>{ el }</span>) }
            </div>
        )
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
				{ message }
            </div>
        )
    }

	// Set Message
	useEffect(() => {
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [text, message, errors])

	// Test Navigate
	useEffect(() => {
		if (logged) navigate('/');
	}, [logged, navigate])

	return (
		<div id="forgot" className="auth">
			<div className='header'>
				<img src={logo} alt="Logo Energizou" />

				<h2>Lembrar minha senha</h2>
			</div>

			{ Array.isArray(errors) && errors.length>0 && treatErrors() }
			{ !errors.length && message && treatMessage() }

			<form onSubmit={onSubmit} autoComplete='off' method="POST">
				<InputLabel name='mail' type='mail' labelName='E-mail' value={values.mail} onChange={onChange}/>

				<button className="send" type="submit">
					Lembrar a Senha
				</button>

				<div className="back">
					<span>Conseguiu lembrar sua senha?</span>

					<Link to='/login' className="dec">
						volte ao login
					</Link>
				</div>
			</form>
		</div>
	)
}
export default Forgot
