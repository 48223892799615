import React, { useContext, useEffect, useState } from 'react';
import './styles.css';
// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { ModalContext } from '~/contexts/modal';
import Loading from '~/components/Default/Loading';
import Create from './Modal/create';
import Edit from './Modal/edit';
import View from './Modal/view';
import Delete from './Modal/delete';
import DriverBox from './Box';


const TransportDrivers: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [drivers, setDrivers]               = useState<any[]>([]);
    const [driversList, setDriversList]               = useState<any[]>([]);
    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {

        setModalClass('whView')
        setModalTitle('Dados do Motorista')
        setModalBody(<View data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        setModalOpen(true)
/*         if (type === "create"){
            setModalClass('')
            setModalTitle('Adicionar novo Motorista')
            setModalBody(<Create setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit"){
            setModalClass('')
            setModalTitle('Editar Motorista')
            setModalBody(<Edit setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('')
            setModalTitle('Dados do Motorista')
            setModalBody(<View setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete"){
            setModalClass('')
            setModalTitle('Deletar Motorista')
            setModalBody(<Delete setModalOpen={setModalOpen} setReady={setReady} />)
        } */
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Motorista", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: driversList,
                    filterKey: [],
                    setFilteredList:setDriversList 
                }}
                isViewButton={false}
                isViewSearch={true}
            />

            {
                drivers && drivers.length > 0 ? (
                    <div className='drivers'>
                        {
                            drivers.map((driversData: any) => (
                                <DriverBox
                                    key={`drivers_box_${driversData.name}`}
                                    {...driversData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum veiculo</div>
                
            }
        </>
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Motoristas") && setBreadcrumbs({
            curr: 'Motoristas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Motoristas' }
            ]
        });

        setReady(true)

        setDrivers([
            {
                name: "João Silva",
                telefone: "(11) 99999-9999",
                email: "joao.silva@email.com",
                cnh: "12345678900",
                veiculo: {
                    type:"Van", 
                    placa:"DEF-5678", 
                    cor:"Azul"
                },
                linhas: ["Linha 1", "Linha 2"]
            },
            {
                name: "Maria Souza",
                telefone: "(21) 98888-8888",
                email: "maria.souza@email.com",
                cnh: "98765432100",
                veiculo: {
                    type:"Van", 
                    placa:"ABC-1234", 
                    cor:"Azul"},
                linhas: ["Linha 3", "Linha 4"]
            }
        ]);
    }, [breadcrumbs]);

    return <Template page="Motoristas" pageTitle="Transporte - Motoristas" className="secEducDrivers">
         { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportDrivers;