import dateFormat from 'dateformat';

const dates = {
    getDateFormatted: function (date: string | Date, format?: string) {
        return dateFormat(date, format ? format : 'dd-mm-yyyy');
    },

    getTimeFormatted: function (date: string, format?: string) {
        const dateCur  = new Date();
        const dateBase = `${dateCur.getFullYear()}-${dateCur.getMonth()}-${dateCur.getDate()} ${date}`;

        return dateFormat(dateBase, format ? format : 'HH:MM:ss');
    },

    getDateDBFormatted: function (date: string, init?: boolean) {
        let nDate = new Date(date);

        let year  = dateFormat(nDate, 'yyyy'),
            month = dateFormat(nDate, 'mm'),
            day   = dateFormat(nDate, 'dd');

        return year + '-' + month + '-' + (init ? '01' : day) + 'T03:00:00.000Z';
    },

    getDateString: function (date: Date | string) {
        let year  = dateFormat(date, 'yyyy'),
            month = dateFormat(date, 'mm'),
            day   = dateFormat(date, 'dd');

        return {year, month, day};
    },

    getYearsOld: function (date: Date | string) {
        let currDate: any = new Date();
        let fromDate: any = new Date(date);

        const diffInMs   = currDate - fromDate;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let   years      = Math.floor(diffInDays / 365);
        let   restMonths = diffInDays - (years * 365);
        let   months     = Math.floor(restMonths / 30);
        let   days       = Math.floor(restMonths - (months * 30));

        return { years, months, days };
    },

    getNameMonth: function (month: number) {
        let monthArr = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];


        return monthArr[month-1];
    },

    getShortNameMonth: function (month: number) {
        let monthArr = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
        ];

        return monthArr[month-1];
    }
}

export default dates;