import { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

interface Request {
    id           : number;
    state        : string;
    justification: string;
}

// Default Product Value
const requestDefault = {
    id           : 0,
    state        : '',
    justification: '',
}

// Array States
const states = ['Pendente', 'Aprovado', 'Negado', 'Em falta', 'Envio Parcial', 'Enviado', 'Recebido', 'Outro'];

const ModalResponse: React.FC<any> = (props: any) => {
    const [ready, setReady]             = useState<boolean>(false);
    const [error, setError]             = useState<string | null>(null);
    const [loadData, setLoadData]       = useState<boolean>(false);
    const [requestData, setRequestData] = useState<Request>(requestDefault);

    // Contexts
    const { user } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        let newProduct = JSON.parse(JSON.stringify(requestData));
        setRequestData({...newProduct, [name]: value});
    }

    const saveDataForm = async () => {
        if (requestData.state.trim() === "" || requestData.justification.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = requestData;

        // Send data to server
        await api.patch(`warehouse_requests/state/${requestData.id}`, dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    };

    function getContent() {
        if (loadData) return <div className="saving">Salvando dados...</div>

        return <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="form-group">
                <div className="input-group">
                    <select id="state" name="state" onChange={onChange} defaultValue={requestData.state} required>
                        <option value="">Selecione uma Opção</option>

                        {states.map((el: any, idx: number) => <option key={`opt_${idx}`} value={el}>{el}</option>) }
                    </select>

                    <textarea
                        id="justification"
                        name="justification"
                        onChange={onChange}
                        cols={50}
                        rows={8}
                        placeholder='Digite sua mensagem'
                        required
                    >{ requestData.justification }</textarea>
                </div>

                <div className="input-group btns">
                    <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                    <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
                </div>
            </div>
        </form>
    }

    useEffect(() => {
        if (!ready) {
            console.log(props)
            setRequestData(props)
            setReady(true);
        }
    }, [ready])

    return ready ? getContent() : <></>;
};

export default ModalResponse;
