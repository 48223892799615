import React from "react";
import './styles.css';
import dates from './../../../../utils/dates';

const ModalView: React.FC<any> = ({ data, openModal, setModalOpen }: any) => {
    function getContent() {
        // Check Exist Menu
        if (!data.menu || data.menu.length===0) return <>
            <div className="noInfos">Não há cardápio registrado para esse dia.</div>

            <div className="btns">
                <button id="btnCreate" onClick={() => openModal('Create', data)}>Criar Cardápio</button>
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>

        // Set Infos Menu
        const breakfast = data && data.menu.find((el: any) => el.type === 'Breakfast');
        const lunch     = data && data.menu.find((el: any) => el.type === 'Lunch');
        const snack     = data && data.menu.find((el: any) => el.type === 'Snack');
        const dinner    = data && data.menu.find((el: any) => el.type === 'Dinner');
        const supper    = data && data.menu.find((el: any) => el.type === 'Supper');

        // Generate Template
        return (
            <>
                <h3>Cardápio de {data.day} de {dates.getNameMonth(data.month)} de {data.year}</h3>

                <hr />

                <div className="line">
                    <h4>Café da Manhã {breakfast ? <span>({breakfast.name})</span> : ''}</h4>
                    <div className="desc">{breakfast ? breakfast.description : 'Não Informado'}</div>
                </div>

                <div className="line">
                    <h4>Almoço {lunch ? <span>({lunch.name})</span> : ''}</h4>
                    <div className="desc">{lunch.name ? lunch.description : 'Não Informado'}</div>
                </div>

                <div className="line">
                    <h4>Lanche {snack ? <span>({snack.name})</span> : ''}</h4>
                    <div className="desc">{snack.name ? snack.description : 'Não Informado'}</div>
                </div>

                <div className="line">
                    <h4>Janta {dinner ? <span>({dinner.name})</span> : ''}</h4>
                    <div className="desc">{dinner.name ? dinner.description : 'Não Informado'}</div>
                </div>

                <div className="line">
                    <h4>Ceia {supper ? <span>({supper.name})</span> : ''}</h4>
                    <div className="desc">{supper.name ? supper.description : 'Não Informado'}</div>
                </div>



                <div className="btns">
                    <button id="btnEdit" onClick={() => openModal('Edit', data)}>Editar Cardápio</button>
                    <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
                </div>
            </>
        );
    }

    return getContent();
};

export default ModalView;
