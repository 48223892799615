import React, { useContext } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalInactivate: React.FC<any> = (props: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    async function inactivateProduct() {
        // Send data to server
        await api.patch(`warehouse_resources/inactive`, { id: props.id }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    }

    return <>
        <div className="infos">
            <p>Deseja realmente desativar o produto <i>"{ props.name }"</i>?</p>
        </div>

        <div className="btns">
            <button id="btnDelete" type="button" onClick={inactivateProduct}>Desativar</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Voltar</button>
        </div>
    </>;
};

export default ModalInactivate;
