import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { GeneralProvider } from '~/contexts';
import { ModalProvider } from './contexts/modal';
import Routes from '~/routes';

const App: React.FC = () => {
	return (
		<HelmetProvider>
			<GeneralProvider>
				<ModalProvider>
					<Routes />
				</ModalProvider>
			</GeneralProvider>
		</HelmetProvider>
	)
}

export default App;
