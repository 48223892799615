import React from "react";
import './styles.css';

const ModalView: React.FC<any> = ({ products }: any) => {
    return <>
        <div className="title">
            <div className="name">Produto</div>
            <div className="quantity">Quantidade</div>
            <div className="type">Setor</div>
        </div>

        {
            products.map((el: any) => <div className="prodBox">
                <div className="name" title={el.description}>{el.name}</div>
                <div className="quantity">{el.quantity} {el.resourceMeasure.measure}</div>
                <div className="type">{el.resourceType.name}</div>
            </div>)
        }
    </>
};

export default ModalView;
