import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalEdit: React.FC<any> = (props: any) => {
    const [vehicle, setVehicle] = useState<any[]>([]);  // Array de veículos
    const [driver, setDriver] = useState<any[]>([]);    // Array de motoristas
    const [monitor, setMonitor] = useState<any[]>([]);  // Array de monitores
    const [line, setLine] = useState<any>({
        name: props.data.name || "",
        description: props.data.description || "",
        vehicle_id: props.data.vehicle_id || "",
        driver_id: props.data.driver_id || "",
        monitor_id: props.data.monitor_id || ""
    });

    // Contexts
    const { user } = useContext(GeneralContext);

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setLine((prevLine: any) => ({
            ...prevLine,
            [name]: value
        }));
    };

    async function saveDataForm() {
        try {
            console.log("Dados da rota atualizados:", line);
            await api.put(`/transportation_routes/${props.data.id}`, line, {
                headers: { Authorization: user.token }
            });
            alert('Dados atualizados com sucesso!');
            props.setModalOpen(false);
        } catch (error) {
            alert('Erro ao atualizar dados');
            console.error('Erro ao salvar dados:', error);
        }
    }

    async function loadInfos() {
        try {
            const [employeesResponse, vehicleResponse] = await Promise.all([
                api.get('/employees', { headers: { Authorization: user.token } }),
                api.get('/transportation_vehicles', { headers: { Authorization: user.token } }),
            ]);
    
            const drivers = employeesResponse.data.filter((employee: any) => employee.position?.name.toLowerCase() === 'motorista');
            const monitors = employeesResponse.data.filter((employee: any) => employee.position?.name.toLowerCase() === 'monitor');
    
            setVehicle(vehicleResponse.data);
            setDriver(drivers);
            setMonitor(monitors);

            props.setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações');
            console.error('Erro ao carregar informações:', error);
        }
    }

    useEffect(() => {
        loadInfos();
    }, []);

    return (
        <>
            <form 
                method="post" 
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} 
                className="EditLine"
            >
                <div className="form-group">
                    <div className="input-group">
                        <label>Nome</label>
                        <input
                            type="text"
                            placeholder="ex.: Veículo A"
                            name="name"
                            value={line.name}
                            onChange={onChange}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label>Descrição</label>
                        <input
                            type="text"
                            placeholder="ex.: Descrição do veículo"
                            name="description"
                            value={line.description}
                            onChange={onChange}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <label>Veículo</label>
                        <select
                            name="vehicle_id"
                            value={line.vehicle_id}
                            onChange={onChange}
                            required
                        >
                            <option value="">Selecione um veículo</option>
                            {vehicle.map((veh: any) => (
                                <option key={veh.id} value={veh.id}>
                                    {veh.name} - {veh.licence_plate}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="input-group">
                        <label>Monitor</label>
                        <select
                            name="monitor_id"
                            value={line.monitor_id}
                            onChange={onChange}
                            required
                        >
                            <option value="">Selecione um monitor</option>
                            {monitor.map((mon: any) => (
                                <option key={mon.id} value={mon.id}>
                                    {mon.name} - {mon.telefone}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="input-group">
                        <label>Motorista</label>
                        <select
                            name="driver_id"
                            value={line.driver_id}
                            onChange={onChange}
                            required
                        >
                            <option value="">Selecione um motorista</option>
                            {driver.map((drv: any) => (
                                <option key={drv.id} value={drv.id}>
                                    {drv.nome} - {drv.cnh}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="input-group btns">
                        <button id="btnSave" type="button" onClick={saveDataForm}>Salvar</button>
                        <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ModalEdit;
