import React from 'react';
import { FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

interface Props {
    position            : {
        id      : number;
        name    : string;
        editable: boolean;
    };
    PeopleUsing         : number;
    openModal           : Function;
}

const PositionBox: React.FC<any> = (props: Props) => {
    function getButtons() {
        return props.position.editable ? <>
            <button type="button" className="edit" onClick={() => props.openModal('edit', props.position)} title="Editar Cargo">
                <FaPencil size={14} /> <span> Editar</span>
            </button>

            <button type="button" className="delete" onClick={() => props.openModal('delete', props.position)} title="Deletar Cargo">
                <FaTrash size={14} /><span> Deletar</span>
            </button>
        </> : <div className='admin'>Requer Nivel Admin</div>
    }

    return <div className="positionBox">
        <div className="infos">
            <h3>{props.position.name}</h3>

            <div className="positionUsing">
                <b>Cargos Atribuídos:</b> { props.PeopleUsing }
            </div>
        </div>

        <div className="buttons">
            { getButtons() }
        </div>
    </div>;
}

export default PositionBox;