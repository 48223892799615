import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';


const TransportAttendanceBox: React.FC<any> = (props: any) => {

    function getButtons() {
        return <>
            <button type="button" className="edit" onClick={() => props.openModal("view", props)} title="Deletar Produto">
                <FaEye size={16} /> <span>Visualizar</span>
            </button>
        </>
    }

    return <div className="transportAttendanceBox">
        <div className="infos">
            <h3>{props.name}</h3>
            
            <div className="description">
                {props.description}
            </div>
           
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default TransportAttendanceBox;
