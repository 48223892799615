import React, { useContext, useEffect, useState } from 'react';
import dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

const ClassStudents: React.FC<any> = ({ id }: any) => {
    const [ready, setReady]               = useState(false);
    const [classStudents, setClassStudents] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function getContent() {
        return classStudents && classStudents.length > 0 ? <>
                <div className="header">
                    <div className="name">Nome</div>
                    <div className="years">Idade</div>
                </div>

                {
                    classStudents.map((el: any) => {
                        let yearsOld = dates.getYearsOld(el.birth);

                        return <div key={`teacher_${el.id}`} className='line'>
                            <div className="name">{el.name}</div>
                            <div className="years">{yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</div>
                        </div>
                    })
                }
            </> : <div className='alert'>Nenhum aluno cadastrado para essa classe</div>
    }

    useEffect(() => {
        !ready && api.get(`teacher/class/${id}/students`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setClassStudents(resp.data);
            setReady(true);
        });
    }, [ready]);

    return ready ? getContent() : <></>;
}

export default ClassStudents;