import React, { ChangeEvent, useContext, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalEdit: React.FC<any> = (props: any) => {

    const [vehicle, setVehicle]             = useState<any>({
        name:props.data.name,
        description:props.data.description,
        licence_plate:props.data.licence_plate,
        brand:props.data.brand,
        model:props.data.model,
        color:props.data.color,
        seats:props.data.seats
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target; 
        setVehicle((prevVehicle:any) => ({
            ...prevVehicle,
            [name]: value
        }));
    };

    async function saveDataForm() {

        console.log("Dados da nova vehicle:" + vehicle)
    }

    return <>
      <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="CreateVehicle">
    <div className="form-group">
        <div className="input-group">
            <label>Nome</label>
            <input
                type="text"
                placeholder="ex.: Veículo A"
                name="name"
                value={vehicle.name}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Descrição</label>
            <input
                type="text"
                placeholder="ex.: Descrição do veículo"
                name="description"
                value={vehicle.description}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Placa</label>
            <input
                type="text"
                placeholder="ex.: ABC-1234"
                name="licence_plate"
                value={vehicle.licence_plate}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Marca</label>
            <input
                type="text"
                placeholder="ex.: Toyota"
                name="brand"
                value={vehicle.brand}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Modelo</label>
            <input
                type="text"
                placeholder="ex.: Corolla"
                name="model"
                value={vehicle.model}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Cor</label>
            <input
                type="text"
                placeholder="ex.: Preto"
                name="color"
                value={vehicle.color}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group">
            <label>Assentos</label>
            <input
                type="number"
                placeholder="ex.: 5"
                name="seats"
                value={vehicle.seats}
                onChange={onChange}
                required
            />
        </div>

        <div className="input-group btns">
            <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
        </div>
    </div>
</form>
    </>;
};

export default ModalEdit;
