import React, { useContext, useEffect, useState } from 'react';
import dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

const ClassInfos: React.FC<any> = ({ id }: any) => {
    const [ready, setReady]           = useState(false);
    const [classInfos, setClassInfos] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    useEffect(() => {
        !ready && api.get('school_classes/' + id, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setClassInfos(resp.data);
            console.log(resp.data)
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        <h3>{classInfos.class_id}</h3>
        <p><b>Escola:</b> {classInfos.school.name}</p>
        <p><b>Tipo:</b> {classInfos.teaching.name}</p>
        <p><b>Série:</b> {classInfos.class_serie} {classInfos.class_acron}</p>
        <p><b>Ano Letivo:</b> {classInfos.school_year}</p>
        <p><b>Alunos / Máxmo:</b> {classInfos.students ? classInfos.students.length : 0} / {classInfos.max_students}</p>
        <p><b>Período:</b> {classInfos.shift.name}</p>
        <p><b>Horário:</b> {dates.getTimeFormatted(classInfos.start_time, 'HH:MM')} às {dates.getTimeFormatted(classInfos.end_time, 'HH:MM')}</p>
    </> : <></>;
}

export default ClassInfos;