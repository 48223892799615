import React, { useContext } from 'react';
import { FaEye, FaLocationDot } from 'react-icons/fa6';
import './styles.css';

// Components
import Map from '~/components/Maps';
import Infos from './infos';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    id                  : number;
    name                : string;
    latitude            : string;
    longitude           : string;
    address_neighborhood: string;
    teaching            : any;
}

const SchoolBox: React.FC<any> = (props: Props) => {
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        if (type === "map") {
            setModalClass('map')
            setModalTitle('Visualizando Endereço');
            setModalBody(<Map infos={{name: props.name, latitude: props.latitude.replace(',', '.'), longitude: props.longitude.replace(',', '.')}} type="unit" />)
        } else {
            setModalClass('unitInfos')
            setModalTitle('Visualizando Unidade');
            setModalBody(<Infos data={props} />)
        }

        setModalOpen(true);
    }

    return <div className="unitBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="neighborhood">
                <b>Bairro:</b> { props.address_neighborhood }
            </div>

            <div className="schoolID">
                <b>Tipo:</b> { props.teaching.name }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="maps" onClick={() => openModal('map')} title="Visualizar Endereço">
                <FaLocationDot size={14} />
            </button>

            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Escola">
                <FaEye size={16} /><span> Visualizar</span>
            </button>
        </div>
    </div>;
}

export default SchoolBox;