import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import PositionBox from './Box';
import Form from './Modal';
import Delete from './Modal/delete';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Interfaces
interface Position {
    id: number;
    name: string;
    numbeOfposition: string;
}

const SecretaryEducationPosition: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [position, setposition]         = useState<Position[]>([]);
    const [positionList, setpositionList] = useState<Position[]>([]);
    const [error, setError]               = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data: any) {
        if (type==="delete") {
            setModalClass('posDelete')
            setModalTitle('Deletar Cargo')
            setModalBody(<Delete {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit") {
            setModalClass('posAddEdit')
            setModalTitle('Editando Cargo')
            setModalBody(<Form {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else {
            setModalClass('posAddEdit')
            setModalTitle('Adicionar Nova Cargo')
            setModalBody(<Form setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{nameButton: "Adicionar Novo Cargo", className: "add", onClick: () => {openModal("add", "type")}}]}
                search={{
                    dataList: positionList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["position.name"],             // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setposition   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                placeholder="Filtrar Cargos..."
                isViewButton={true}
                isViewSearch={true}
            />

            {
                position && position.length > 0 ? (
                    <div className='positions'>
                        {
                            position.map((positionData: any) => (
                                <PositionBox key={`position_box_${positionData.position.id}`} {...positionData} openModal={openModal} />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum cargo</div>
            }
        </>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('position', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setposition(resp.data);
            setpositionList(resp.data);
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados dos cargos')
            setReady(true)
        });
    }, [ready]);

     // Breadcrumbs
     useEffect(() => {
         (!breadcrumbs || breadcrumbs.curr!=="Cargos") && setBreadcrumbs({
             curr: 'Cargos',
             links: [
                 { name: 'Home', url: '/'},
                 { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                 { name: 'Cargos' }
             ]
         });
     }, [breadcrumbs]);

    return <Template page="Matrículas - Cargos" pageTitle="Secretaria da Educação - Cargos" className="secEducPosition">
        { ready ? getContent() : <Loading /> }
     </Template>;
}

export default SecretaryEducationPosition;