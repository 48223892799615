import React, { useContext, useEffect, useState } from "react";
import dates from "~/utils/dates";
import { eachDayOfInterval, startOfYear, endOfYear, getDay } from 'date-fns';
import './styles.css';

// Components
import Template from "~/components/Default/Template";
import CalendarComponent from "./Components/Calendar";
import BimesterInfoComponent from "./Components/Bimester";
import LegendComponent from "./Components/Legend";

// Contexts
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "~/services/api";

type BimesterDates = {
    bimester1Start: string;
    bimester1End  : string;
    bimester2Start: string;
    bimester2End  : string;
    bimester3Start: string;
    bimester3End  : string;
    bimester4Start: string;
    bimester4End  : string;
};

type SelectedDay = {
    day: number;
    month: number;
} | null;

type EventDays = Record<string, string>;

const eventColors: Record<string, string> = {
    "Recesso": "red",
    "Feriado": "orange",
    "Férias do docente": "green",
    "Planejamento/Replanejamento": "blue",
    "Reunião de pais/Responsáveis (Letivo)": "purple",
    "Formação Continuada": "pink",
    "Ponto Facultativo": "gray",
    "Conselho de classe (Letivo)": "brown",
    "Dias Letivos": "yellow",
    "Final de semana": "olive",
    "Semana nacional da Pessoa com deficiência": "cyan",
    "Início/Encerramento do ano letivo": "magenta",
};

const SecretaryEducationCalendar: React.FC = () => {
	const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);
	const { breadcrumbs, setBreadcrumbs, user }                        = useContext(GeneralContext);
	const [searchParams]                                               = useSearchParams();
	const year:number                                                  = Number(searchParams.get("year")) || Number(new Date().getFullYear());
    // const navigate                                                     = useNavigate();


    const [eventDays, setEventDays] = useState<EventDays>({});
    const [bimesterDates, setBimesterDates] = useState<BimesterDates>({
            bimester1Start: "",
            bimester1End: "",
            bimester2Start: "",
            bimester2End: "",
            bimester3Start: "",
            bimester3End: "",
            bimester4Start: "",
            bimester4End: "",
    });

    const handleDayClick = (day: number, month: number) => {
        let monthName   = dates.getNameMonth(month+1)
        let daySelected:string = String(day).length > 1 ? String(day) : "0" + String(day)

        setModalClass('eventDay')
        setModalTitle(`Defina um evento para o dia ${ daySelected } de ${ monthName }`)
        setModalBody(Object.entries(eventColors).map(([eventType, color]) => (
            <button key={eventType} className="legend-item" onClick={() => handleEventSelect(day, month, eventType)}>
                <span className={color} />
                {eventType}
            </button>
        )))
        setModalOpen(true);
    };

    const handleEventSelect = (day: number, month: number, eventType: string) => {
        setModalOpen(false)
        const key = `${day}-${month}`;
        setEventDays((prev) => ({
            ...prev,
            [key]: eventType,
        }));
    };

    const fillLetiveDaysForYear = (year: number) => {
        const startDate = startOfYear(new Date(year, 0, 1)); // Primeiro dia do ano
        const endDate = endOfYear(new Date(year, 11, 31));   // Último dia do ano

        const daysInYear = eachDayOfInterval({ start: startDate, end: endDate });

        const updatedEventDays = { ...eventDays };

        daysInYear.forEach((date) => {
            const dayOfWeek = getDay(date); // 0 = Domingo, 6 = Sábado
            const day = date.getDate();
            const month = date.getMonth();  // Janeiro é 0, Dezembro é 11
            const key = `${day}-${month}`;

            // Preenche apenas os dias de segunda a sexta-feira e não sobrescreve eventos já existentes
            if (dayOfWeek >= 1 && dayOfWeek <= 5 && !updatedEventDays[key]) {
                updatedEventDays[key] = "Dias Letivos";
            } else if(dayOfWeek === 0 || dayOfWeek === 6){
                updatedEventDays[key] = "Final de semana";
            }
        });

        setEventDays(updatedEventDays)

        return updatedEventDays;
    };


    const calculateLetiveDays = (): number => {
        let letiveDays = 0;
        Object.values(eventDays).forEach((event) => {
            if (event === "Dias Letivos") letiveDays += 1;
        });
        return letiveDays;
    };

    const handleBimesterDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBimesterDates({
            ...bimesterDates,
            [e.target.name]: e.target.value,
        });
    };

    async function sendForm() {
        await api.post('calendar',
            {
                year,
                bimesterDates,
                eventDays,
                letiveDays: calculateLetiveDays()
            },
            {headers: { Authorization: user.token }})
        .then(resp => {
            alert('Cadastro realizado com sucesso')
            console.log(resp)
            // navigate('/secretaria-educacao/funcionarios')
        }).catch(err => {
            console.log("🚀 ~ sendForm ~ err:", err)
        })
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Calendário") && setBreadcrumbs({
            curr: 'Calendário',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Secretaria da Educação', url: '/secretaria-educacao' },
                { name: 'Calendário' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Calendário escolar" pageTitle="Secretaria da Educação - Calendário escolar" className="secEducCalendar">
            <div className="headerBox">
                <div className="buttons">
                    <button onClick={()=>fillLetiveDaysForYear(year)}>Preencher dias úteis e finais de semana</button>
                </div>
            </div>
            <div className="calendar-container">
                {/* Calendário à esquerda */}
                <CalendarComponent
                    eventColors={eventColors}
                    eventDays={eventDays}
                    onDayClick={handleDayClick}
                    year={Number(year)}
                />

                <div className="rigth">
                    <BimesterInfoComponent
                        onBimesterDateChange={handleBimesterDateChange}
                        letiveDays={calculateLetiveDays()}
                        bimesterDates={bimesterDates}
                    />

                    <hr />

                    <LegendComponent eventColors={eventColors} submit={sendForm} />
                </div>
            </div>

        </Template>
    );
};

export default SecretaryEducationCalendar;
