import React from 'react';
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';

import './styles.css';

const NewStudentAddress: React.FC<any> = ({ onChange, onChangeCep,onChangeStreet, data, errors} : any) => {

    return (
        <>
        <div className="formGroup nomg">
            <div className="formItem w25">
                <InputLabelMask
                    name='cep'
                    value={data?.cep}
                    type='text'
                    mask="99999-999"
                    placeholder='00000-000'
                    labelName='CEP'
                    onChange={onChangeCep}
                    error={errors && errors['cep']}
                />
            </div>

            <div className="formItem wauto">
                <InputLabel
                    name="address"
                    type='text'
                    labelName='Rua'
                    value={data?.address}
                    onChange={onChangeStreet}
                    error={errors && errors['address']}
                />
               </div>

            <div className="formItem w10">
                <InputLabel
                    name="number"
                    type='text'
                    labelName='Número'
                    onChange={onChange}
                    value={data?.number}
                    error={errors && errors['number']}
                />
            </div>
        </div>

        <div className="formGroup">
            <div className="formItem w30">
                <InputLabel
                    name="complement"
                    type='text'
                    labelName='Complemento'
                    value={data?.complement}
                    onChange={onChange}
                    error={errors && errors['complement']}
                />
            </div>
            <div className="formItem w30">
                <InputLabel
                    name="neighborhood"
                    type='text'
                    labelName='Bairro'
                    value={data?.neighborhood}
                    onChange={onChange}
                    error={errors && errors['neighborhood']}
                />
            </div>

            <div className="formItem wauto">
                <InputLabel
                    name="city"
                    type='text'
                    labelName='Cidade'
                    value={data?.city}
                    onChange={onChange}
                    error={errors && errors['city']}
                />
            </div>

            <div className="formItem w30">
                <label htmlFor="uf">Estado</label>
                <select onChange={onChange} id="gender" name="gender" value={data?.gender} defaultValue={data?.gender} required>
                    </select>
                {/* <select onChange={onChange} id="uf" name="uf" value={data.uf} defaultValue={data.uf} required>
                    <option></option>
                    {
                        states.map((el: any) => <option key={'state_' + el.id} value={el.acron}>{el.name}</option>)
                    }
                </select> */}
            </div>
        </div>

        <div className="formGroup nomg">
            <div className="formItem w100">
                <InputLabel
                    name="longitude"
                    type='text'
                    labelName='Longitude'
                    value={data?.address}
                    onChange={onChangeStreet}
                    error={errors && errors['address']}
                />
            </div>

            <div className="formItem w100">
                <InputLabel
                    name="latitude"
                    type='text'
                    labelName='Latitude'
                    value={data?.address}
                    onChange={onChangeStreet}
                    error={errors && errors['address']}
                />
               </div>

            <div className="formItem wauto">
                <InputLabel
                    name="Area"
                    type='text'
                    labelName='Area'
                    onChange={onChange}
                    value={data?.number}
                    error={errors && errors['number']}
                />
            </div>
        </div>
        </>
    );
};

export default NewStudentAddress;