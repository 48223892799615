import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';
import './styles.css';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';

const CreateNewClasse: React.FC<any> = ({ data, errors, onChange }: any) => {
    const [classType, setClassType] = useState<any>([]);
    const [school, setSchool] = useState<any>([]);
    const [teaching, setTeaching] = useState<any>([]);
    const [shift, setShift] = useState<any>([]);
    const [ready, setReady] = useState(false);

    //Contexts
    const { user } = useContext(GeneralContext);

    async function loadInfos() {
        try {
            const [classTypeResponse, schoolResponse, teachingResponse, shiftResponse] = await Promise.all([
                api.get('/class_type', { headers: { Authorization: user.token } }),
                api.get('/school', { headers: { Authorization: user.token } }),
                api.get('/teaching_type', { headers: { Authorization: user.token } }),
                api.get('/school_shift', { headers: { Authorization: user.token } })
            ]);

            setClassType(classTypeResponse.data);
            setSchool(schoolResponse.data);
            setTeaching(teachingResponse.data);
            setShift(shiftResponse.data);
            console.log("🚀 ~ loadInfos ~ setShift:", setShift)
            console.log("🚀 ~ loadInfos ~ setTeaching:", setTeaching)
            console.log("🚀 ~ loadInfos ~ setSchool:", setSchool)
            console.log("🚀 ~ loadInfos ~ setClassType:", setClassType)

            // Quando ambos os estados forem setados, setReady(true) é chamado.
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
            // Trate o erro apropriadamente, talvez com um setReady(false) ou exibição de mensagem de erro.
        }
    }

    useEffect(() => {
        if (!ready) {
            loadInfos()
        }
    }, [ready]);

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_id">Escola</label>
                    <select onChange={onChange} id="school_id" name="school_id" value={data?.school_id} required>
                        <option value="">Selecione uma escola</option>
                        {school.map((schoolItem: any) => (
                            <option key={schoolItem.id} value={schoolItem.id}>
                                {schoolItem.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="teaching_type_id">Nivel Escolar</label>
                    <select onChange={onChange} id="teaching_type_id" name="teaching_type_id" value={data?.teaching_type_id} required>
                        <option value="">Selecione o nível escolar</option>
                        {teaching.map((teachingItem: any) => (
                            <option key={teachingItem.id} value={teachingItem.id}>
                                {teachingItem.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="class_type_id">Tipo de Ensino</label>
                    <select onChange={onChange} id="class_type_id" name="class_type_id" value={data?.class_type_id} required>
                        <option value="">Selecione o tipo de ensino</option>
                        {classType.map((classTypeItem: any) => (
                            <option key={classTypeItem.id} value={classTypeItem.id}>
                                {classTypeItem.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="shift_id">Periodo</label>
                    <select onChange={onChange} id="shift_id" name="shift_id" value={data?.shift_id} required>
                        <option value="">Selecione o período</option>
                        {shift.map((shiftItem: any) => (
                            <option key={shiftItem.id} value={shiftItem.id}>
                                {shiftItem.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <hr />
            <br />

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="class_serie"
                        type='number'
                        labelName='Serie'
                        onChange={onChange}
                        value={data?.class_serie}
                        error={errors && errors['class_serie']}
                    />
                </div>

                <div className="formItem w1-4">
                    <label htmlFor="class_acron">Sala</label>
                    <select onChange={onChange} id="class_acron" name="class_acron" value={data?.class_acron} defaultValue={data?.class_acron} required>
                    <option value="">Selecione</option>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                        <option value="e">E</option>
                        <option value="f">F</option>
                    </select>
                </div>

                <div className="formItem w1-4">
                    <InputLabel
                        name="max_students"
                        type='number'
                        onChange={onChange}
                        labelName='Quantaidade Maxima de alunos'
                        value={data?.max_students}
                        error={errors && errors['max_students']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="start_time"
                        type='date'
                        labelName='Data Inicio da Turma'
                        onChange={onChange}
                        value={data?.start_time}
                        error={errors && errors['start_time']}
                    />
                </div>
                <div className="formItem w1-2">
                    <InputLabel
                        name="end_time"
                        type='date'
                        labelName='Data Encerramento da Turma'
                        onChange={onChange}
                        value={data?.end_time}
                        error={errors && errors['end_time']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="start_classes"
                        type='time'
                        labelName='Hora de inicio da aula'
                        onChange={onChange}
                        value={data?.start_classes}
                        error={errors && errors['start_classes']}
                    />
                </div>
                <div className="formItem w1-2">
                    <InputLabel
                        name="end_classes"
                        type='time'
                        labelName='Hora deEncerramento da aula'
                        onChange={onChange}
                        value={data?.end_classes}
                        error={errors && errors['end_classes']}
                    />
                </div>
            </div>
        </>
    );
};

export default CreateNewClasse;




