import React, { useContext } from 'react';
import { FaEye, FaLocationDot } from 'react-icons/fa6';
import dates from '~/utils/dates';
import './styles.css';

// Components
import Map from '~/components/Maps';
import Infos from './infos';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    ra_number: number;
    name     : string;
    birth    : Date;
    address  : any;
}

const StudentBox: React.FC<any> = (props: Props) => {
    const yearsOld = dates.getYearsOld(props.birth);
    const birth = dates.getDateFormatted(props.birth);

    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        if (type === "map") {
            setModalClass('map')
            setModalTitle('Visualizando Endereço');
            setModalBody(<Map infos={{name: props.name, latitude: props.address.latitude, longitude: props.address.longitude}} />)
        } else {
            setModalClass('studentInfos')
            setModalTitle('Visualizando Aluno')
            setModalBody(<Infos data={props} />)
        }

        setModalOpen(true);
    }

    return <div className="studentBox">
        <div className="infos">
            <h3>{props.name}</h3>

            <div className="ra">
                <b>RA:</b> { props.ra_number }
            </div>

            <div className="birth">
                { birth.split('-').join('/') } <i>({yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')})</i>
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="maps" onClick={() => openModal('map')} title="Visualizar Mapa">
                <FaLocationDot size={14} />
            </button>

            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Aluno">
                <FaEye size={16} /><span> Visualizar</span>
            </button>
        </div>
    </div>;
}

export default StudentBox;