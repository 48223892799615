import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import { FaTrash } from 'react-icons/fa6';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Loading from '~/components/Default/Loading';
import InputLabel from '~/components/Forms/InputLabel';

let measureUnit = "";

const NewRequestForm: React.FC<any> = (props: any) => {
    const [ready, setReady]                 = useState(false);
    const [products, setProducts]           = useState<any[]>([]);
    const [requests, setRequests]           = useState<any[]>([{ quantity: "", productId: "", name: "", type: "", measure: "" }]);
    const [error, setError] = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function addProd() {
        setRequests([...requests, { id: requests.length+1, quantity: "", productId: "" }]);
    }

    function removeProd(id: number) {
        if (requests.length === 1) return;
        let reqBase = JSON.parse(JSON.stringify(requests));
        let idx     = requests.findIndex((el: any) => id === el.id);

        // Remove Item
        reqBase.splice(idx, 1);

        // Recreate Requests
        setRequests(reqBase);
    }

    function onChange(index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        const newRequests     = [...requests];

        newRequests[index]    = {
            ...newRequests[index],
            [name]: value,
            measure: '',
        };

        // Se o campo alterado for o select de produto
        if (name === 'productId') {
            // Encontre o produto selecionado
            const selectedProduct = products.find((prod: any) => `${prod.id}`===value);

            // Atualize a unidade de medida e outros dados baseados no produto selecionado
            if (selectedProduct) {
                // Adiciona informações do produto selecionado ao objeto newRequests
                newRequests[index] = {
                    ...newRequests[index],
                    name: selectedProduct.name,
                    type: selectedProduct.resourceType, // Supondo que você tenha esse campo
                    measure: selectedProduct.resourceMeasure.measure,
                };
            }
        }

        setRequests(newRequests);
    }

    async function sendForm() {
        let requestData = {
            user_id: user.id, // Número do ID do usuário
            resource_data: requests, // Texto descrevendo o recurso
            delivery_date: new Date(), // Data da entrega (formato Date)
        };

        api.post('warehouse_requests', requestData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            props.setModalOpen(false);
            props.setReady(false);
        }).catch(err => {
            setError('Erro ao carregar os dados dos produtos')
            setReady(true)
        });
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            {
                requests.map((data: any, idx: number) => (
                    <div key={`prod_${idx}`} className="formGroup">
                        <div className="inputLabel product">
                            <label htmlFor="product">Produto</label>
                            <select
                                name="productId"
                                value={data.productId}
                                onChange={(e) => onChange(idx, e)}
                            >
                                <option value="">Selecione um Produto</option>

                                {
                                    products.map((prod: any) => (
                                        <option key={`prod_${prod.id}`} value={prod.id}>
                                            {`${prod.name} - Unidade de Medida: ${prod.resourceMeasure.measure}`}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        <InputLabel
                            name="quantity"
                            className='quantity'
                            type='text'
                            value={data.quantity}
                            onChange={(e) => onChange(idx, e)}
                            labelName={`Quantidade${data.measure ? ' em ' + data.measure : ''}`}
                        />

                        <button
                            type='button'
                            onClick={() => removeProd(data.id)}
                        >
                            <FaTrash size={14} />
                        </button>
                    </div>
                ))
            }

            <div className="buttons">
                <button className='add' onClick={addProd}>Adicionar Produto na lista de requisição</button>
                <button className='save' onClick={sendForm}>Salvar</button>
            </div>
        </>
    }

    useEffect(() => {
        !ready && api.get('warehouse_resources', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setProducts(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados dos produtos')
            setReady(true)
        });
    }, [ready, requests])

    return ready ? getContent() : <Loading />
}

export default NewRequestForm;