import React from 'react';
import { FaEye, FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const MonitorsBox: React.FC<any> = (props: any) => {
    console.log("🚀 ~ props:", props)
    function getButtons() {
        return <>
            <button type="button" className="inactivate" onClick={() => props.openModal('Delete', props)} title="Editar Produto">
            <FaEye size={16} /> <span> Visualizar</span>
            </button>
        </>
    }

    return <div className="monitorsBox">
        <div className="infos">
            <h3>{props.name}</h3>

            {props.line.map((linha: any, index: number) => (
            <div key={index}>
               <strong>Linha:</strong> {linha}
            </div>))}
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default MonitorsBox;
