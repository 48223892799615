import React from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';

const NewStudentDocs: React.FC<any> = ({onChange, data, errors}) => {

    return (
        <>
             <div className="formGroup">
                <div className="formItem w25">
                    <InputLabel
                        name="cpf"
                        type='text'
                        labelName='CPF'
                        onChange={onChange}
                        value={data?.cpf}
                        error={errors && errors['cpf']}
                    />
                </div>
                <div className="formItem wauto">
                    <InputLabel
                        name="birth_certificate"
                        type='text'
                        labelName='Numero da Certidão de nascimento'
                        onChange={onChange}
                        value={data?.birth_certificate}
                        error={errors && errors['birth_certificate']}
                    />
                </div>
                <div className="formItem w40">
                    <InputLabel
                        name="date_birth_certificate"
                        type='date'
                        labelName='Expedição da certidão de nasciemnto'
                        onChange={onChange}
                        value={data?.date_birth_certificate}
                        error={errors && errors['date_birth_certificate']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-2">
                    <InputLabel
                        name="inep_code"
                        type='text'
                        labelName='Codigo do Inep'
                        onChange={onChange}
                        value={data?.inep_code}
                        error={errors && errors['inep_code']}
                    />
                </div>
                <div className="formItem w1-2">
                    <InputLabel
                        name="cns_number"
                        type='text'
                        labelName='Codigo do CNS'
                        onChange={onChange}
                        value={data?.cns_number}
                        error={errors && errors['cns_number']}
                    />
                </div>
                <div className="formItem w80">
                    <InputLabel
                        name="date_civil_doc"
                        type='date'
                        labelName='Expedição do documento civil'
                        onChange={onChange}
                        value={data?.date_civil_doc}
                        error={errors && errors['date_civil_doc']}
                    />
                </div>
            </div>
        </>
    );
};

export default NewStudentDocs;