import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

const SchoolInfos: React.FC<any> = ({ school_id }) => {
    const [ready, setReady]     = useState(false);
    const [schoolInfos, setSchoolInfos] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    useEffect(() => {
        !ready && api.get('school/' + school_id, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setSchoolInfos(resp.data);
            setReady(true);
        });
    }, [ready]);

    return ready ? <>
        <h3>{schoolInfos.name}</h3>
        <p><b>Endereço:</b> {schoolInfos.address}, {schoolInfos.address_number ? schoolInfos.address_number : 'S/N'}, {schoolInfos.address_neighborhood}</p>
        <p><b>Cidade/CEP:</b> {schoolInfos.address_city} / {schoolInfos.address_cep}</p>
        <p><b>Diretoria:</b> {schoolInfos.board.name}</p>
        <p><b>Tipo:</b> {schoolInfos.teaching.name}</p>
        <hr />
        <p><b>Quantidade de Classes:</b> {schoolInfos.classes}</p>
        <p><b>Número Total de Alunos:</b> {schoolInfos.students}</p>
    </> : <></>;
}

export default SchoolInfos;