import React, { useCallback, useEffect, useState } from 'react';
import { LoginSocialGoogle } from 'reactjs-social-login';
import './styles.css'
import env from '~/utils/env';
import { FcGoogle } from "react-icons/fc";


const LoginGoogle: React.FC = () => {
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState<any>();

    const onLogout = useCallback(() => {}, []);

    return <div className="login-social-google">
        <LoginSocialGoogle
          client_id={env.googleClientID || ''}
          onLoginStart={() => console.log('Iniciando Login')}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          onResolve={({ provider, data }: any) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <button className="google-btn">
            <div className="google-icon-wrapper">
              <FcGoogle className='google-icon'/>
              </div>
            <span className="btn-text">Login via Google</span>
          </button>


        </LoginSocialGoogle>

        {/* {
            provider && profile && (
                <User provider={provider} profile={profile} onLogout={onLogout} />
            )
        } */}
    </div>
}

export default LoginGoogle;