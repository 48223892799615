import React from 'react';
import { FaPencil, FaTrash } from 'react-icons/fa6';
import './styles.css';

const TransportStudentsBox: React.FC<any> = (props: any) => {
    function getButtons() {
        return <>
            <button type="button" className="edit" onClick={() => props.openModal("Edit", props)} title="Deletar Produto">
                <FaPencil size={14} /> <span>Visulizar</span>
            </button>

            <button type="button" className="inactivate" onClick={() => props.openModal('Delete', props)} title="Editar Produto">
                <FaTrash size={14} /> <span> Deletar</span>
            </button>
        </>
    }

    return <div className="productBox">
        <div className="infos">
           {/* a */}
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default TransportStudentsBox;
