import React from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';

const NewStudentDeficiency: React.FC<any> = ({ data, onChange, errors }) => {

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="reduced_mobility">Mobilidade Reduzida</label>
                    <select onChange={onChange} id="reduced_mobility" name="reduced_mobility" value={data?.reduced_mobility} defaultValue={data?.reduced_mobility} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="reduced_mobility_type"
                        type='text'
                        labelName='Tipo da Mobilidade Reduzida'
                        onChange={onChange}
                        value={data?.reduced_mobility_type}
                        error={errors && errors['reduced_mobility_type']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_support">Suporte Escola</label>
                    <select onChange={onChange} id="school_support" name="school_support" value={data?.school_support} defaultValue={data?.school_support} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="school_support_daily_activities">Suporte Escola Diariamente com Atividades</label>
                    <select onChange={onChange} id="school_support_daily_activities" name="school_support_daily_activities" value={data?.school_support_daily_activities} defaultValue={data?.school_support_daily_activities} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="school_support_activities">Suporte Escola com Atividades</label>
                    <select onChange={onChange} id="school_support_activities" name="" value={data?.school_support_activities} defaultValue={data?.school_support_activities} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="school_support_hygiene">Suporte Escola com higiene</label>
                    <select onChange={onChange} id="school_support_hygiene" name="school_support_hygiene" value={data?.school_support_hygiene} defaultValue={data?.school_support_hygiene} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="school_support_locomotion">Suporte Escola com locomoção</label>
                    <select onChange={onChange} id="school_support_locomotion" name="school_support_locomotion" value={data?.school_support_locomotion} defaultValue={data?.school_support_locomotion} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="school_support_food">Suporte Escola com alimentação</label>
                    <select onChange={onChange} id="school_support_food" name="school_support_food" value={data?.school_support_food} defaultValue={data?.school_support_food} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <InputLabel
                        name="start_term_activities_daily"
                        type='date'
                        labelName='iniciar atividades letivas diariamente'
                        onChange={onChange}
                        value={data?.start_term_activities_daily}
                        error={errors && errors['start_term_activities_daily']}
                    />
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="end_term_activities_daily"
                        type='date'
                        labelName='finalizar atividades letivas diariamente'
                        onChange={onChange}
                        value={data?.end_term_activities_daily}
                        error={errors && errors['end_term_activities_daily']}
                    />
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <InputLabel
                        name="start_term_school_activities"
                        type='date'
                        labelName='iniciar atividades escolares do período'
                        onChange={onChange}
                        value={data?.start_term_school_activities}
                        error={errors && errors['start_term_school_activities']}
                    />
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="end_term_school_activities"
                        type='date'
                        labelName='finalizar atividades escolares do período'
                        onChange={onChange}
                        value={data?.end_term_school_activities}
                        error={errors && errors['end_term_school_activities']}
                    />
                </div>
            </div>
        </>
    );
};

export default NewStudentDeficiency;