import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import '../auth.css';
import './styles.css';

// Components
import {InputLabel} from '~/components/Forms';

// Images
// import logo from '~/assets/images/logo.svg';
import logo from '~/assets/images/4educ_logo3.png';

// Constants
const initialState = {token: '', pass: '', confirm: ''};

const Forgot: React.FC = () => {
	let params = useParams();
	let token  = params.token;
	let text   = params.text;

    const navigate              = useNavigate();
    const {logged}              = useContext(GeneralContext);
    const [values, setValues]   = useState({...initialState, token});
    const [message, setMessage] = useState<string>('');
    const [errors, setErrors]   = useState<String[]>([]);

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setValues({...values, [name]: value})
    }

	function onSubmit (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		let errArr = [];
		if (values.pass==='' || values.confirm==='') {
			if (values.pass==='') errArr.push('O campo de senha está vazio');
			if (values.confirm==='') errArr.push('O campo de confirmação está vazio');

			setErrors(errArr);
			return;
		} else if (values.pass!==values.confirm) {
			errArr.push('A senha e a confirmação estão diferentes');
			setErrors(errArr);
			return;
		}

		api.post('remember-password', values).then(async (resp: any) => {
			setErrors([])
			setValues({...initialState, token})
			setMessage(resp.data.message)
		}).catch((err: any) => {
			setMessage('')
			const { status } = err.response
			if (status===400) {
				const error = err.response.data.errors
				setErrors(error)
			}
		});
	}

	function treatErrors() {
        return (
            <div className="boxErrors">
				{ errors.map(el => <span key={`error_${el}`}>{ el }</span>) }
            </div>
        )
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
				{ message }
            </div>
        )
    }

	// Set Message
	useEffect(() => {
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [text, errors])

	// Test Navigate
	useEffect(() => {
		if (logged) navigate('/');
	}, [logged, navigate])

	return (
		<div id="newPass">
			<div className='header'>
				<img src={logo} alt="Logo Energizou" />

				<h2>Informe sua nova senha</h2>
			</div>

			{ Array.isArray(errors) && errors.length>0 && treatErrors() }
			{ !errors.length && message && treatMessage() }

			<form onSubmit={onSubmit} autoComplete='off' method="POST">
				<InputLabel name='pass' type='password' labelName='Senha' value={values.pass} onChange={onChange}/>
				<InputLabel name='confirm' type='password' labelName='Confirmação de Senha' value={values.confirm} onChange={onChange} style={{marginTop: '20px'}}/>

				<button className="send" type="submit">
					Salvar Senha
				</button>

				<div className="back">
					<span>Você que solicitou uma nova senha?</span>

					<Link to='/login' className="dec">
						volte ao login
					</Link>
				</div>
			</form>
		</div>
	)
}
export default Forgot
