import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBusSimple, FaIdBadge, FaListCheck, FaRoute, FaUserTie } from 'react-icons/fa6';
import { GiHomeGarage } from "react-icons/gi";
import { FaUserFriends } from 'react-icons/fa';
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const Transport: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'transporte', 'veiculos') && (
                        <Link to="/transporte/veiculos" className={location.pathname === '/transporte/veiculos' ? ' active' : ''}>
                            <FaBusSimple />
                            <span>Veículos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'transporte', 'motoristas') && (
                        <Link to="/transporte/motoristas" className={location.pathname === '/transporte/motoristas' ? ' active' : ''}>
                            <FaUserTie />
                            <span>Motoristas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'transporte', 'monitoras') && (
                        <Link to="/transporte/monitoras" className={location.pathname === '/transporte/monitoras' ? ' active' : ''}>
                            <FaUserFriends />
                            <span>Monitoras</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'transporte', 'linhas') && (
                        <Link to="/transporte/linhas" className={location.pathname === '/transporte/linhas' ? ' active' : ''}>
                            <FaRoute />
                            <span>Linhas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'transporte', 'alunos') && (
                        <Link to="/transporte/alunos" className={location.pathname === '/transporte/alunos' ? ' active' : ''}>
                            <FaIdBadge />
                            <span>Alunos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'transporte', 'presenca') && (
                        <Link to="/transporte/presenca" className={location.pathname === '/transporte/presenca' ? ' active' : ''}>
                            <FaListCheck />
                            <span>Presença Mensal</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/transporte" className={location.pathname.indexOf('/transporte') === 0 ? ' active' : ''}>
                <GiHomeGarage />
                <span>Transporte</span>
            </Link>

            {location.pathname.indexOf('/transporte') === 0 && submenu()}
        </>
    );
}

export default Transport;
