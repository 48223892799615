import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Auth Pages
import { Login, Logout, Forgot, NewPassword } from '~/pages/auth';

// Pages
import { Error404, Dashboard, Profile } from '~/pages';
import { SecretaryEducationCalendar, SecretaryEducationDash, SecretaryEducationEmployees, SecretaryEducationEmployeesForm, SecretaryEducationEnrollment, SecretaryEducationMapping, SecretaryEducationOtherUnits, SecretaryEducationOtherUnitsForm, SecretaryEducationPerformance, SecretaryEducationPermissions, SecretaryEducationPermissionsForm, SecretaryEducationPosition, SecretaryEducationSchools } from '~/pages/SecretaryEducation';
import { SchoolSecretaryClasses, SchoolSecretaryControls, SchoolSecretaryDash, SchoolSecretaryDocuments, SchoolSecretaryEnrollment, SchoolSecretaryReports, SchoolSecretaryStudents, SchoolSecretaryTransfers } from '~/pages/SchoolSecretary';
import { TeacherActivities, TeacherClasses, TeacherDash, TeacherGrades, TeacherPlanning, TeacherStudents } from '~/pages/Teacher';
import { ParentsAlerts, ParentsDash, ParentsEnrollment, ParentsGrades, ParentsNutrition, ParentsStudents } from '~/pages/Parents';
import { DirectionActivities, DirectionDash, DirectionGrades, DirectionStudents, DirectionTeachers, DirectionTerm } from '~/pages/SupervisionDirection';
import { TransportAttendance, TransportDash, TransportDrivers, TransportLines, TransportMonitors, TransportStudents, TransportVehicles } from '~/pages/Transport';
import { NutriDash, NutriMenus, NutriTips } from '~/pages/Nutrition';
import { WarehouseRequests, WarehouseDash, WarehouseMaintenance, WarehouseNutrition, WarehouseProducts, WarehouseTransport, WarehouseSchoolRequests } from '~/pages/Warehouse';

import useAuthGuard from '~/services/useAuthGuard';
import WarehouseRequestsForm from '~/pages/Warehouse/School/Form';
import SchoolSecretaryClassesForm from '~/pages/SchoolSecretary/Classes/Form';
import SchoolSecretaryStudentsForm from '~/pages/SchoolSecretary/Students/Form';

const AuthGuardedRoute: React.FC<{ page: string, subpage: string, element: React.ReactElement }> = ({ page, subpage, element }) => {
    useAuthGuard(page, subpage);
    return element;
};

const SystemRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Auth */}
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/lembrar-senha" element={<Forgot />} />
                <Route path="/nova-senha/:token" element={<NewPassword />} />

                {/* Admin */}
                <Route path="/" element={<Dashboard />} />
                <Route path="/perfil" element={<AuthGuardedRoute page="admin" subpage="profile" element={<Profile />} />} />

                {/* Secretary of Education */}
                <Route path = "/secretaria-educacao" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="" element={<SecretaryEducationDash />} />} />
                <Route path = "/secretaria-educacao/mapeamento" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="mapeamento" element={<SecretaryEducationMapping />} />} />
                <Route path = "/secretaria-educacao/funcionarios" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="funcionarios" element={<SecretaryEducationEmployees />} />} />
                <Route path = "/secretaria-educacao/funcionarios/novo" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="funcionarios" element={<SecretaryEducationEmployeesForm />} />} />
                <Route path = "/secretaria-educacao/escolas" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="escolas" element={<SecretaryEducationSchools />} />} />
                <Route path = "/secretaria-educacao/demais-unidades" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="demais-unidades" element={<SecretaryEducationOtherUnits />} />} />
                <Route path = "/secretaria-educacao/demais-unidades/novo" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="demais-unidades" element={<SecretaryEducationOtherUnitsForm />} />} />
                <Route path = "/secretaria-educacao/desempenho" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="desempenho" element={<SecretaryEducationPerformance />} />} />
                <Route path = "/secretaria-educacao/calendario" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="calendario" element={<SecretaryEducationCalendar />} />} />
                <Route path = "/secretaria-educacao/matriculas" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="matriculas" element={<SecretaryEducationEnrollment />} />} />
                <Route path = "/secretaria-educacao/permissoes" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="permissoes" element={<SecretaryEducationPermissions />} />} />
                <Route path = "/secretaria-educacao/permissoes/novo" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="permissoes" element={<SecretaryEducationPermissionsForm />} />} />
                <Route path = "/secretaria-educacao/permissoes/edit/:id" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="permissoes" element={<SecretaryEducationPermissionsForm />} />} />
                <Route path = "/secretaria-educacao/cargos" element = {<AuthGuardedRoute page="secretaria-educacao" subpage="cargos" element={<SecretaryEducationPosition />} />} />

                {/* School Secretary */}
                <Route path="/secretaria-escolar" element={<AuthGuardedRoute page="secretaria-escolar" subpage="" element={<SchoolSecretaryDash />} />} />
                <Route path="/secretaria-escolar/matriculas" element={<AuthGuardedRoute page="secretaria-escolar" subpage="matriculas" element={<SchoolSecretaryEnrollment />} />} />
                <Route path="/secretaria-escolar/transferencias" element={<AuthGuardedRoute page="secretaria-escolar" subpage="transferencias" element={<SchoolSecretaryTransfers />} />} />
                <Route path="/secretaria-escolar/turmas" element={<AuthGuardedRoute page="secretaria-escolar" subpage="turmas" element={<SchoolSecretaryClasses />} />} />
                <Route path="/secretaria-escolar/turmas/nova" element={<AuthGuardedRoute page="secretaria-escolar" subpage="turmas" element={<SchoolSecretaryClassesForm />} />} />
                <Route path="/secretaria-escolar/alunos" element={<AuthGuardedRoute page="secretaria-escolar" subpage="alunos" element={<SchoolSecretaryStudents />} />} />
                <Route path="/secretaria-escolar/alunos/novo" element={<AuthGuardedRoute page="secretaria-escolar" subpage="alunos" element={<SchoolSecretaryStudentsForm />} />} />
                <Route path="/secretaria-escolar/documentos" element={<AuthGuardedRoute page="secretaria-escolar" subpage="documentos" element={<SchoolSecretaryDocuments />} />} />
                <Route path="/secretaria-escolar/controle" element={<AuthGuardedRoute page="secretaria-escolar" subpage="controle" element={<SchoolSecretaryControls />} />} />
                <Route path="/secretaria-escolar/relatorios" element={<AuthGuardedRoute page="secretaria-escolar" subpage="relatorios" element={<SchoolSecretaryReports />} />} />

                {/* Teacher */}
                <Route path="/professor" element={<AuthGuardedRoute page="professor" subpage="" element={<TeacherDash />} />} />
                <Route path="/professor/turmas" element={<AuthGuardedRoute page="professor" subpage="turmas" element={<TeacherClasses />} />} />
                <Route path="/professor/alunos" element={<AuthGuardedRoute page="professor" subpage="alunos" element={<TeacherStudents />} />} />
                <Route path="/professor/atividades" element={<AuthGuardedRoute page="professor" subpage="atividades" element={<TeacherActivities />} />} />
                <Route path="/professor/notas" element={<AuthGuardedRoute page="professor" subpage="notas" element={<TeacherGrades />} />} />
                <Route path="/professor/planejamento" element={<AuthGuardedRoute page="professor" subpage="planejamento" element={<TeacherPlanning />} />} />

                {/* Parents */}
                <Route path="/pais-e-alunos" element={<AuthGuardedRoute page="pais-e-alunos" subpage="" element={<ParentsDash />} />} />
                <Route path="/pais-e-alunos/avisos" element={<AuthGuardedRoute page="pais-e-alunos" subpage="avisos" element={<ParentsAlerts />} />} />
                <Route path="/pais-e-alunos/aluno" element={<AuthGuardedRoute page="pais-e-alunos" subpage="aluno" element={<ParentsStudents />} />} />
                <Route path="/pais-e-alunos/notas" element={<AuthGuardedRoute page="pais-e-alunos" subpage="notas" element={<ParentsGrades />} />} />
                <Route path="/pais-e-alunos/nutricao" element={<AuthGuardedRoute page="pais-e-alunos" subpage="nutricao" element={<ParentsNutrition />} />} />
                <Route path="/pais-e-alunos/matricula" element={<AuthGuardedRoute page="pais-e-alunos" subpage="matricula" element={<ParentsEnrollment />} />} />

                {/* Supervision and Direction */}
                <Route path="/supervisao-e-direcao" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="" element={<DirectionDash />} />} />
                <Route path="/supervisao-e-direcao/termo-de-visita" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="termo-de-visita" element={<DirectionTerm />} />} />
                <Route path="/supervisao-e-direcao/professores" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="professores" element={<DirectionTeachers />} />} />
                <Route path="/supervisao-e-direcao/alunos" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="alunos" element={<DirectionStudents />} />} />
                <Route path="/supervisao-e-direcao/notas" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="notas" element={<DirectionGrades />} />} />
                <Route path="/supervisao-e-direcao/atividades" element={<AuthGuardedRoute page="supervisao-e-direcao" subpage="atividades" element={<DirectionActivities />} />} />

                {/* Transport */}
                <Route path="/transporte" element={<AuthGuardedRoute page="transporte" subpage="" element={<TransportDash />} />} />
                <Route path="/transporte/veiculos" element={<AuthGuardedRoute page="transporte" subpage="veiculos" element={<TransportVehicles />} />} />
                <Route path="/transporte/motoristas" element={<AuthGuardedRoute page="transporte" subpage="motoristas" element={<TransportDrivers />} />} />
                <Route path="/transporte/monitoras" element={<AuthGuardedRoute page="transporte" subpage="monitoras" element={<TransportMonitors />} />} />
                <Route path="/transporte/linhas" element={<AuthGuardedRoute page="transporte" subpage="linhas" element={<TransportLines />} />} />
                <Route path="/transporte/alunos" element={<AuthGuardedRoute page="transporte" subpage="alunos" element={<TransportStudents />} />} />
                <Route path="/transporte/presenca" element={<AuthGuardedRoute page="transporte" subpage="presenca" element={<TransportAttendance />} />} />

                {/* Nutrition */}
                <Route path="/nutricao" element={<AuthGuardedRoute page="nutricao" subpage="" element={<NutriDash />} />} />
                <Route path="/nutricao/cardapios" element={<AuthGuardedRoute page="nutricao" subpage="cardapios" element={<NutriMenus />} />} />
                <Route path="/nutricao/dicas" element={<AuthGuardedRoute page="nutricao" subpage="dicas" element={<NutriTips />} />} />

                {/* Warehouse */}
                <Route path="/almoxarifado" element={<AuthGuardedRoute page="almoxarifado" subpage="" element={<WarehouseDash />} />} />
                <Route path="/almoxarifado/produtos" element={<AuthGuardedRoute page="almoxarifado" subpage="produtos" element={<WarehouseProducts />} />} />
                <Route path="/almoxarifado/nutricao" element={<AuthGuardedRoute page="almoxarifado" subpage="nutricao" element={<WarehouseNutrition />} />} />
                <Route path="/almoxarifado/requisicao" element={<AuthGuardedRoute page="almoxarifado" subpage="request" element={<WarehouseRequests />} />} />
                <Route path="/almoxarifado/manutencao" element={<AuthGuardedRoute page="almoxarifado" subpage="manutencao" element={<WarehouseMaintenance />} />} />
                <Route path="/almoxarifado/transporte" element={<AuthGuardedRoute page="almoxarifado" subpage="transporte" element={<WarehouseTransport />} />} />

                {/* Warehouse - Requisitions School */}
                <Route path="/requisicoes" element={<AuthGuardedRoute page="requisicoes" subpage="" element={<WarehouseSchoolRequests />} />} />
                <Route path="/requisicoes/novo" element={<AuthGuardedRoute page="requisicoes" subpage="nova" element={<WarehouseRequestsForm />} />} />

                {/* Error */}
                <Route path="/404" element={<Error404 />} />
                <Route path="/*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SystemRoutes;
