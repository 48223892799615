import React, { useContext, useEffect } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';

const SupervisionDirection: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Supervisão e Direção") && setBreadcrumbs({
            curr: 'Supervisão e Direção',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Supervisão e Direção" pageTitle="Supervisão e Direção" className="supDirection">
        Supervisão e Direção<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
    </Template>;
}

export default SupervisionDirection;