import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css'

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Response from './Modal/response';
import RequestBox from './Box';
import View from './Modal/view';

export interface Request {
    id           : number;
    resource_id  : number[];
    quantity     : number;
    delivery     : boolean;
    delivery_date: Date;
}

const WarehouseRequests: React.FC = () => {
    const [ready, setReady]             = useState(false);
    const [error, setError]             = useState<string | null>(null);
    const [request, setRequest]         = useState<Request[]>([]);
    const [requestList, setRequestList] = useState<Request[]>([]);

    //ContextS
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type==="response") {
            setModalClass('whResponse')
            setModalTitle('Responder Solicitação de Material')
            setModalBody(<>Resposta a solicitação</>)
            setModalBody(<Response {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view") {
            setModalClass('whView')
            setModalTitle('Lista Produtos Solicitados')
            setModalBody(<View {...data} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{ nameButton: "" }]}
                search={{
                    dataList: requestList,
                    filterKey: ["name"],
                    setFilteredList: setRequest
                }}
                isViewButton={false}
                isViewSearch={true}
            />

            {
                request && request.length > 0 ? (
                    <div className='request'>
                        {
                            request.map((requestData: any) => (
                                <RequestBox
                                    key={`request_box_${requestData.id}`}
                                    {...requestData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum cargo</div>
            }
        </>
    }

    useEffect(() => {
        !ready && api.get('warehouse_requests', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setRequest(resp.data);
            setRequestList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados das requisições')
            setReady(true)
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Requisições") && setBreadcrumbs({
            curr: 'Requisições',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Almoxarifado', url: '/almoxarifado' },
                { name: 'Requisições' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Requisições" pageTitle="Almoxarifado - Requisições" className="warehouseRequest">
           { ready ? getContent() : <Loading /> }
        </Template>
    )
}

export default WarehouseRequests;