import React, { useState, ChangeEvent, useContext } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

const FormRA: React.FC<any> = () => {
    const [studentData, setStudentData] = useState<any>({ errors: [], ra: '' });
    const [loadData, setLoadData] = useState<boolean>(false);

    // Contexts
    const { user } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const data = {[name]: value};
        setStudentData({...studentData, ...data});
    }

    async function saveStudent() {
        setLoadData(true)

        await api.put('secEduc/students/ra', { ra: studentData.ra }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            console.log(resp.data)
            Modal.setModalOpen(false);
        });
    }

    return !loadData ? <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
        <div className="form-group no-margin">
            <div className="input-group">
                <input id="name" className={studentData.errors && studentData.errors.name ? 'error' : ''} name="ra" type="text" placeholder="RA" value={studentData.ra} onChange={onChange} />
            </div>

            <div className="input-group btns">
                <button id="btnAdd" type="button" onClick={() => saveStudent()}>Adicionar</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </div>
        </div>
    </form> : <>Salvando dados do aluno...</>;
}

export default FormRA;