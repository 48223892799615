import React from 'react';
import './styles.css';

// Component
import InputLabel from '~/components/Forms/InputLabel';

const NewStudentResourceAssessment: React.FC<any> = ({ data, onChange, errors }) => {

    return (
        <>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="interpreter_guide">Intérprete</label>
                    <select onChange={onChange} id="interpreter_guide" name="interpreter_guide" value={data?.interpreter_guide} defaultValue={data?.interpreter_guide} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="interpret_libras">intérprete de Libras</label>
                    <select onChange={onChange} id="interpret_libras" name="interpret_libras" value={data?.interpret_libras} defaultValue={data?.interpret_libras} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="lip_reading">leitura labial</label>
                    <select onChange={onChange} id="lip_reading" name="lip_reading" value={data?.lip_reading} defaultValue={data?.lip_reading} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="none">Nenhum</label>
                    <select onChange={onChange} id="none" name="none" value={data?.none} defaultValue={data?.none} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="braille_test">Testes em Braille</label>
                    <select onChange={onChange} id="braille_test" name="braille_test" value={data?.braille_test} defaultValue={data?.braille_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="transcription_assistance">Transcrição assistência</label>
                    <select onChange={onChange} id="transcription_assistance" name="transcription_assistance" value={data?.transcription_assistance} defaultValue={data?.transcription_assistance} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="expanded_test_font_size">tamanho da fonte de teste expandido</label>
                    <select onChange={onChange} id="expanded_test_font_size" name="expanded_test_font_size" value={data?.expanded_test_font_size} defaultValue={data?.expanded_test_font_size} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <InputLabel
                        name="expanded_test_font_size"
                        type='text'
                        labelName='Tamnaho'
                        onChange={onChange}
                        value={data?.expanded_test_font_size}
                        error={errors && errors['expanded_test_font_size']}
                    />
                </div>
            </div>
            <div className="formGroup">
                <div className="formItem w1-4">
                    <label htmlFor="reader_aid">Leitor</label>
                    <select onChange={onChange} id="reader_aid" name="reader_aid" value={data?.reader_aid} defaultValue={data?.reader_aid} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="libras_video_test">Video em libras</label>
                    <select onChange={onChange} id="libras_video_test" name="libras_video_test" value={data?.libras_video_test} defaultValue={data?.libras_video_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="audio_visual_def_code">código de definição audiovisual</label>
                    <select onChange={onChange} id="audio_visual_def_code" name="audio_visual_def_code" value={data?.audio_visual_def_code} defaultValue={data?.audio_visual_def_code} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div className="formItem w1-4">
                    <label htmlFor="portuguese_language_test">Teste de idioma</label>
                    <select onChange={onChange} id="portuguese_language_test" name="portuguese_language_test" value={data?.portuguese_language_test} defaultValue={data?.portuguese_language_test} required>
                        <option value="">Selecione</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

        </>
    );
};

export default NewStudentResourceAssessment;