import React, { useContext, useEffect } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';

const ParentsStudents: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Ficha do Aluno") && setBreadcrumbs({
            curr: 'Ficha do Aluno',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Pais e Alunos', url: '/pais-e-alunos'},
                { name: 'Aluno' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Ficha do Aluno" pageTitle="Pais e Alunos - Ficha do Aluno" className="parentsStudents">
        Pais e Alunos - Ficha do Aluno<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
        .<br />
    </Template>;
}

export default ParentsStudents;