import React, { useContext } from "react";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalView: React.FC<any> = (props: any) => {
console.log("🚀 ~ props:", props)

    // Contexts
    const { user } = useContext(GeneralContext);

    return (
        <div className="modal-view">
            <h2>Alunos cadastrado nessa rota</h2>
            <p>{props.data.name}</p>
            <p>{props.data.description}</p>
            {props.data.alunos && props.data.alunos.length > 0 ? (
                props.data.alunos.map((aluno: any, index: number) => (
                    <div key={index}>
                        <p>Nome: {aluno.name}</p>
                        <button onClick={() => props.openModal("attendance", aluno)}>Presença mensal</button>
                    </div>
                ))
            ) : (
                <p>Nenhum aluno encontrado</p>
            )}
        </div>
    );
};

export default ModalView;
