import React, { useContext, useEffect } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { ModalContext } from '~/contexts/modal';
import FormCreateTypeAndMeasure from './Form/formCreateTypeAndMeasure';

const Warehouse: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function modal(option: string) {
        console.log("🚀 ~ modal ~ option:", option)
        if (option === "type") {
            setModalTitle('Adicionar Nova Categoria')
            setModalBody(<FormCreateTypeAndMeasure option={option} />)
            setModalOpen(true)
        } else if (option === "measure") {
            setModalTitle('Adicionar Nova Unidade de medida')
            setModalBody(<FormCreateTypeAndMeasure option={option} />)
            setModalOpen(true)
        } else {
            setModalTitle('erro')
            setModalBody(<></>)
            setModalOpen(true)
        }
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Almoxarifado") && setBreadcrumbs({
            curr: 'Almoxarifado',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Almoxarifado' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Almoxarifado" pageTitle="Almoxarifado" className="warehouse">
        <button onClick={()=> modal("type")}>Adicionar Nova Categoria</button>
        <button onClick={()=> modal("measure")}>Adicionar Nova Unidade de Medida</button>
      
    </Template>;
}

export default Warehouse;