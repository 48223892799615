interface ENV {
    stage: string;
    host: string;
    server: string;
    googleClientID: string;
    googleMapsApiKey: string;

    crypto: {
        web: string;
        server: string;
    }
}

const env: ENV = {
    stage: process.env.REACT_APP_STAGE || '',
    host: process.env.REACT_APP_HOST || '',
    server: process.env.REACT_APP_SERVER || '',
    googleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',

    crypto: {
        web: process.env.REACT_APP_CRYPTO_WEB || '',
        server: process.env.REACT_APP_CRYPTO_SERVER || ''
    }
}

export default env;