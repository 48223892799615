import React, { useContext, useEffect, useState } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';
import './styles.css';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { ModalContext } from '~/contexts/modal';
import Create from './Modal/create';
import Edit from './Modal/edit';
import View from './Modal/view';
import Delete from './Modal/delete';
import VehiclesBox from './Box';
import api from '~/services/api';

interface IVehiecles{
    id?:string;
    name:string;
    description:string;
    licence_plate:string;
    brand:string;
    model:string;
    color:string;
    seats:number;
}

const TransportVehicles: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [error, setError]               = useState<string | null>(null);
    const [vehicles, setVehicles]               = useState<IVehiecles[]>([]);
    const [vehiclesList, setVehiclesList]               = useState<IVehiecles[]>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type === "create"){
            setModalClass('')
            setModalTitle('Criar Novo Veiculo')
            setModalBody(<Create setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "edit"){
            setModalClass('')
            setModalTitle('Editar Veiculo')
            setModalBody(<Edit data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "view"){
            setModalClass('')
            setModalTitle('Dados do Veiculo')
            setModalBody(<View data={data}setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type === "delete"){
            setModalClass('')
            setModalTitle('Deletar Veiculo')
            setModalBody(<Delete data={data.id} setModalOpen={setModalOpen} setReady={setReady} />)
        }
        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[
                    { nameButton: "Cadastrar Novo Veiculo", onClick: ()=> openModal("create"), className: "add" }
                ]}
                search={{
                    dataList: vehiclesList,
                    filterKey: [],
                    setFilteredList:setVehiclesList
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                vehicles && vehicles.length > 0 ? (
                    <div className='vehicles'>
                        {
                            vehicles.map((vehiclesData: any) => (
                                <VehiclesBox
                                    key={`vehicles_box_${vehiclesData.id}`}
                                    {...vehiclesData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum veiculo</div>
            }
        </>
    }

    useEffect(() => {
/*         !ready && api.get('', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setVehicles(resp.data);
            setVehiclesList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados das requisições')
            setReady(true)
        }); */
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Veículos") && setBreadcrumbs({
            curr: 'Veículos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Transporte', url: '/transporte'},
                { name: 'Veículos' }
            ]
        });

        setReady(true)

        setVehicles([
            {
                id: "1",
                name: "Carro A",
                description: "Sedan confortável para viagens longas.",
                licence_plate: "ABC-1234",
                brand: "Toyota",
                model: "Corolla",
                color: "Preto",
                seats: 5
            },
            {
                id: "2",
                name: "Carro B",
                description: "SUV espaçosa, perfeita para a família.",
                licence_plate: "DEF-5678",
                brand: "Honda",
                model: "CR-V",
                color: "Branco",
                seats: 7
            },
            {
                id: "3",
                name: "Carro C",
                description: "Compacto ideal para a cidade.",
                licence_plate: "GHI-9101",
                brand: "Fiat",
                model: "Uno",
                color: "Vermelho",
                seats: 4
            },
            {
                id: "4",
                name: "Carro D",
                description: "Esportivo com design moderno.",
                licence_plate: "JKL-1123",
                brand: "Ford",
                model: "Mustang",
                color: "Azul",
                seats: 2
            },
            {
                id: "5",
                name: "Carro E",
                description: "Van ideal para transporte de mercadorias.",
                licence_plate: "MNO-4567",
                brand: "Mercedes-Benz",
                model: "Sprinter",
                color: "Prata",
                seats: 3
            }
        ]);
    }, [breadcrumbs]);

    return <Template page="Veículos" pageTitle="Transporte - Veículos" className="secEducVehicles">
        { ready ? getContent() : <Loading /> }
    </Template>;
}

export default TransportVehicles;